import React from "react";

const Logo: React.FC = () => (
  <a href="index.php" className="logo-box">
    {/* Light Brand Logo */}
    <div className="logo-light">
      <img src='/img/logo-light.png' className="logo-lg h-6" alt="Light logo" />
      <img src='/img/logo-sm.png' className="logo-sm" alt="Small logo" />
    </div>
    {/* Dark Brand Logo */}
    <div className="logo-dark">
      <img src='/img/logo-dark.png' className="logo-lg h-6" alt="Dark logo" />
      <img src='/img/logo-sm.png' className="logo-sm" alt="Small logo" />
    </div>
  </a>
);

export default Logo;
