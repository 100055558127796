import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_SKILLS } from "../../graphql/queries/skill";
import { GET_CATEGORIES } from "../../graphql/queries/category";

interface IJudges {
  id: string;
  name: string;
  host: string;
  phoneNumber: string;
}
interface Skill {
  id: string;
  name: string;
}

interface Program {
  id: string;
  name: string;
  skill: Skill;
  category: {
    id: string;
    name: string;
  };
  eliminationJudge: IJudges[];
  eliminationStartingTime: string;
  finalDate: string;
  eliminationDate: string;
  eliminationVenue: {
    id: string;
    name: string;
  };
  finalJudge: IJudges[];
  finalStartingTime: string;
  finalVenue: {
    id: string;
    name: string;
  };
}

interface JudgeNavProps {
  handleSearch: (search: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery: string;
  // setSearchQuery: (value: string) => void;
  setFilteredProgramData: any;
  programList: Program[];
  handleCreate: ()=>void;
  judgesData: any;
}

const JudgeNav: React.FC<JudgeNavProps> = ({
  handleSearch,
  searchQuery,
  setFilteredProgramData,
  programList,
  handleCreate,
  judgesData,
}) => {
  const [category, setCategory] = useState(''); // Category filter state
  const [skill, setSkill] = useState(''); // Skill filter state
  const [judge, setJudge] = useState(''); // Judge filter state

  const { data: skillsData, } = useQuery(GET_SKILLS);
  const { data: categoryData, } = useQuery(GET_CATEGORIES);

  useEffect(() => {
    const filteredData = programList?.filter(program => {
      const searchLower = searchQuery.toLowerCase();

      // Filter by search query (name, elimination judges, final judges)
      const nameMatch = program?.name?.toLowerCase().includes(searchLower);
      const eliminationJudgeMatch = program?.eliminationJudge?.some(judge =>
        judge?.name?.toLowerCase().includes(searchLower)
      );
      const finalJudgeMatch = program?.finalJudge?.some(judge =>
        judge?.name?.toLowerCase().includes(searchLower)
      );

      // Filter by category (if selected)
      const categoryMatch = category ? program?.category?.name === category : true;

      // Filter by skill (if selected)
      const skillMatch = skill ? program?.skill?.name === skill : true;

      // Filter by judge (if selected)
      const judgeMatch = judge
        ? program?.eliminationJudge?.some(elimJudge => elimJudge?.name === judge) ||
        program?.finalJudge?.some(finalJudge => finalJudge?.name === judge)
        : true;

      // Return true if all conditions are met
      return (
        (nameMatch || eliminationJudgeMatch || finalJudgeMatch) &&
        categoryMatch &&
        skillMatch &&
        judgeMatch
      );
    });

    // Set the filtered data after computing
    setFilteredProgramData(filteredData);
  }, [searchQuery, category, skill, judge, programList, setFilteredProgramData]);
  return (
    <div className="justify-evenly flex flex-wrap sm:flex-nowrap items-center gap-4 p-4 ">
      {/* Search Input */}
      <div className="w-full min-w-48 sm:w-1/4 flex items-center">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => handleSearch(e)}
          placeholder="Search.."
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      <div className="hidden sm:flex items-center sm:w-1/4 ">
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <option value="">Categories</option>
          {categoryData?.categories?.map((category: { id: string; name: string }) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
      </div>

      <div className="items-center hidden sm:flex sm:w-1/4">
        <select
          value={skill}
          onChange={(e) => setSkill(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <option value="">Skills</option>
          {skillsData?.skills?.map((skill: { id: string; name: string }) => (
            <option key={skill?.id} value={skill?.name}>
              {skill?.name}
            </option>
          ))}
        </select>
      </div>

      <div className="hidden sm:flex items-center sm:w-1/4">
        <select
          value={judge}
          onChange={(e) => setJudge(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <option value="">Judges</option>
          {judgesData?.judges?.map((judge: { id: string; name: string }) => (
            <option key={judge?.id} value={judge?.name}>
              {judge?.name}
            </option>
          ))}
        </select>
      </div>

      <button className="w-40 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
      onClick={handleCreate}>
        <i className="mgc_add_circle_line mr-1"></i>Add New Judge
      </button>
    </div>
  );
};

export default JudgeNav;