import React from "react";
import { useQuery } from "@apollo/client";
import InputText from "./InputText";
import { GET_CLASSES } from "../../graphql/queries/class";
import Select from "../ui-elements/Select";
import { useNavigate } from "react-router-dom";
import { GET_CATEGORIES } from "../../graphql/queries/category";

interface DetailsProps {
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleSubmit: (e: React.FormEvent) => void;
  loading: boolean;
  data: any;
  setData: (data: any) => void;
}

const Details: React.FC<DetailsProps> = ({
  setData,
  data,
  handleInputChange,
  handleSubmit,
  loading,
}) => {
  const {
    data: classData,
    loading: classLoading,
    error: classError,
  } = useQuery(GET_CLASSES);
  const {
    data: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery(GET_CATEGORIES);

  const navigate = useNavigate();

  if (classLoading || categoriesLoading) return <p>Loading...</p>;
  if (classError) return <p>Error loading classes.</p>;
  if (categoriesError) return <p>Error loading categories.</p>;

  return (
    <>
      <div className="col-span-1 md:col-span-3 bg-white p-6 shadow rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-base font-medium mb-4">Candidate Details</h3>
          <button className="bg-gray-200 px-2 py-1 rounded-md">
            <i className="mgc_profile_line"></i>
          </button>
        </div>

        <form className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <InputText
            id="chestNo"
            label="Chest No"
            value={data.chestNo}
            handleInputChange={handleInputChange}
            disabled
          />
          <InputText
            id="admissionNo"
            label="Admission No"
            value={data.admissionNo}
            handleInputChange={handleInputChange}
          />
          <InputText
            id="name"
            label="Name"
            className="md:col-span-2"
            value={data.name}
            handleInputChange={handleInputChange}
          />
          <div>
            <Select
              label="class"
              value={data.class}
              options={classData?.classes}
              flex="col"
              onChange={(e) => {
                const selectedClass = classData?.classes.find(
                  (classItem: any) => classItem.id === e.target.value
                );

                setData((prev: any) => ({
                  ...prev,
                  class: e.target.value,
                  category: selectedClass?.category?.id || "",
                }));
              }}
            />
          </div>
          <div>
            <Select
              label="category"
              value={data.category}
              options={categoriesData?.categories}
              flex="col"
              disabled
              onChange={(e) =>
                setData((prev: any) => ({ ...prev, category: e.target.value }))
              }
            />
          </div>

          <div>
            <label htmlFor="dob" className="block text-sm text-gray-700 mb-1">
              Date of Birth
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={data.dob}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>

          <div>
            <Select
              label="gender"
              value={data.gender}
              options={["Male", "Female"]}
              flex="col"
              onChange={(e) =>
                setData((prev: any) => ({ ...prev, gender: e.target.value }))
              }
            />
          </div>

          <div className="md:col-span-4">
            <label htmlFor="email" className="block text-sm text-gray-700 mb-1">
              Candidate Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={data.email}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        </form>

        {/* Buttons for submit and reset */}
      </div>
      <div className="flex justify-end space-x-4 mt-1 col-span-1 md:col-span-4">
        <button
          type="button"
          onClick={() => navigate("/candidates")}
          className="bg-red-500 text-white px-4 py-2 rounded-md"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className={`text-white px-4 py-2 rounded-md ${
            loading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-green-500 hover:bg-green-600"
          }`}
        >
          {loading ? "Loading.." : "Save"}
        </button>
      </div>
    </>
  );
};

export default Details;
