import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_PROGRAM_PARTICIPANTS } from "../../../graphql/queries/participation";
import TeamInput from "./TeamInput";
import Modal from "../../UIElements/Model";
import toast from "react-hot-toast";
import Select from "../../ui-elements/Select";

// Type for the candidate data structure
interface Candidate {
  id: string;
  chestNo: string;
  name: string;
  photo: string;
  institution: {
    id: string;
    name: string;
    shortName: string;
  };
}

interface AddCompetitionProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  programId: string;
  participationId?: string;
}

const AddCompetition: React.FC<AddCompetitionProps> = ({
  isOpen,
  setIsOpen,
  programId,
  // participationId,
}) => {
  const [teamOne, setTeamOne] = useState<(Candidate | null)[]>(
    Array(2).fill(null)
  );
  const [teamTwo, setTeamTwo] = useState<(Candidate | null)[]>(
    Array(2).fill(null)
  );
  const [teamOneSearch, setTeamOneSearch] = useState<string[]>([]);
  const [teamTwoSearch, setTeamTwoSearch] = useState<string[]>([]);
  const [filteredTeamOne, setFilteredTeamOne] = useState<Candidate[][]>([]);
  const [filteredTeamTwo, setFilteredTeamTwo] = useState<Candidate[][]>([]);
  const [date, setDate] = useState("");
  const [startingTime, setStartingTime] = useState("");
  const [endingTime, setEndingTime] = useState("");
  const [type, setType] = useState("");
  const [loading] = useState(false);

  const clearInput = () => {
    setTeamOne(Array(2).fill(null));
    setTeamTwo(Array(2).fill(null));
    setTeamOneSearch([]);
    setTeamTwoSearch([]);
    setDate("");
    setStartingTime("");
    setEndingTime("");
    setType("");
  };

  const {
    data: participantsData,
    loading: participantsLoading,
    error: participantsError,
  } = useQuery(GET_PROGRAM_PARTICIPANTS, {
    variables: { programId },
    skip: !programId,
  });

  useEffect(() => {
    if (participantsData && participantsData.programParticipants) {
      const filterParticipants = (searchTerms: string[]) => {
        return searchTerms.map((term) =>
          participantsData.programParticipants.filter(
            (participant: Candidate) =>
              participant.institution.name
                .toLowerCase()
                .includes(term.toLowerCase()) ||
              participant.institution.shortName
                .toLowerCase()
                .includes(term.toLowerCase())
          )
        );
      };

      setFilteredTeamOne(filterParticipants(teamOneSearch));
      setFilteredTeamTwo(filterParticipants(teamTwoSearch));
    }
  }, [participantsData, teamOneSearch, teamTwoSearch]);

  const handleParticipantSelect = (
    participant: Candidate | null,
    index: number,
    setTeam: React.Dispatch<React.SetStateAction<(Candidate | null)[]>>
  ) => {
    setTeam((prev) =>
      prev.map((item, idx) => (idx === index ? participant : item))
    );
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Ensure both teams have at least one participant before submitting
    if (
      teamOne.filter((participant) => participant !== null).length === 0 ||
      teamTwo.filter((participant) => participant !== null).length === 0
    ) {
      toast.error("Both teams must have at least one participant.");
      return;
    }

    // Proceed with the form submission logic
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Add New Competition"
      setIsOpen={setIsOpen}
      onClose={clearInput}
      handleSubmit={handleFormSubmit}
      loading={loading}
      buttonText={"Submit"}
    >
      <div className="flex items-center gap-3">
        <TeamInput
          team={teamOne}
          teamName="Team A"
          search={teamOneSearch}
          setSearch={setTeamOneSearch}
          filteredParticipants={filteredTeamOne}
          handleParticipantSelect={(participant, index) =>
            handleParticipantSelect(participant, index, setTeamOne)
          }
          double
          loading={participantsLoading}
          error={participantsError}
        />
        <div className="text-3xl font-semibold">v/s</div>
        <TeamInput
          team={teamTwo}
          teamName="Team B"
          search={teamTwoSearch}
          setSearch={setTeamTwoSearch}
          filteredParticipants={filteredTeamTwo}
          handleParticipantSelect={(participant, index) =>
            handleParticipantSelect(participant, index, setTeamTwo)
          }
          double
          loading={participantsLoading}
          error={participantsError}
        />
      </div>
      <div className="grid grid-cols-3 gap-2">
        <div>
          <label htmlFor="date" className="block text-sm text-gray-700 mb-1">
            Date
          </label>
          <input
            type="date"
            id="date"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label
            htmlFor="startingTime"
            className="block text-sm text-gray-700 mb-1"
          >
            Starting Time
          </label>
          <input
            type="time"
            id="startingTime"
            name="startingTime"
            value={startingTime}
            onChange={(e) => setStartingTime(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label
            htmlFor="endingTime"
            className="block text-sm text-gray-700 mb-1"
          >
            Ending Time
          </label>
          <input
            type="time"
            id="endingTime"
            name="endingTime"
            value={endingTime}
            onChange={(e) => setEndingTime(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
      </div>
      <Select
        label="Type"
        value={type}
        options={["Elimination", "Pre Quarter", "Quarter", "Semi Final"]}
        flex="col"
        onChange={(e) => setType(e.target.value)}
      />
    </Modal>
  );
};

export default AddCompetition;
