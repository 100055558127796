
import { gql } from "@apollo/client";

export const GET_JUDGEMENT = gql`
query Judges {
  judges {
    id
    name
    email
    phoneNumber
    host 
  }
}
`;
