import { gql } from "@apollo/client";

export const CREATE_PARTICIPATION = gql`
  mutation CreateParticipation($input: CreateParticipationInput!) {
    createParticipation(input: $input) {
      id
    }
  }
`;

export const UPDATE_PARTICIPATION = gql`
  mutation UpdateParticipation(
    $id: ID!
    $input: UpdateParticipationInput
  ) {
    updateParticipation(id: $id, input: $input) {
      id
    }
  }
`;

export const UPDATE_REGISTRATION_STATUS = gql`
  mutation UpdateRegistrationStatus(
    $id: ID!
    $input: UpdateRegistrationStatusInput
  ) {
    updateRegistrationStatus(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_PARTICIPATION = gql`
  mutation DeleteParticipation($id: ID!) {
    deleteParticipation(id: $id)
  }
`;
