import React, { useEffect, useState } from "react";
import InstitutionCard from "./InstitutionCard";
import Modal from "../UIElements/Model";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ROLES } from "../../graphql/queries/role";
import { GET_CATEGORIES } from "../../graphql/queries/category";
import toast from "react-hot-toast";
import {
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION,
} from "../../graphql/mutations/institution";
import { GET_GROUP_WISE_INSTITUTIONS } from "../../graphql/queries/institution";
import { CREATE_USER, UPDATE_USER } from "../../graphql/mutations/admin";
import {
  CREATE_CANDIDATE,
  UPDATE_CANDIDATE,
} from "../../graphql/mutations/candidates";
import { GET_PROGRAM_CATEGORIES } from "../../graphql/queries/programCategory";
import { GET_CLUSTERS } from "../../graphql/queries/cluster";

interface GroupCardProps {
  id: string;
  title: string;
  section: {
    id: string;
  };
}

interface Institution {
  id: string;
  name: string;
  shortName: string;
  category: {
    name: string;
    id: string;
  }[];
  programCategory: {
    name: string;
    id: string;
  }[];
  role: string;
  cluster: {
    id: string;
    name: string;
  };
  candidateId: {
    id: string;
    name: string;
    category: {
      id: string;
      name: string;
    };
  };
  userId: {
    id: string;
    username: string;
    role: {
      id: string;
      name: string;
    };
  };
}

interface FormData {
  shortName: string;
  fullName: string;
  role: string;
  category: string[];
  programCategory: string[];
  institutionCategory: string;
  password: string;
  candidateId: string;
  userId: string;
  cluster: string;
}

const GroupCard: React.FC<GroupCardProps> = ({ title, id, section }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editInstitution, setEditInstitution] = useState<Institution | null>(
    null
  );
  const [formData, setFormData] = useState<FormData>({
    shortName: "",
    fullName: "",
    role: "",
    cluster: "",
    category: [],
    programCategory: [],
    institutionCategory: "",
    password: "",
    candidateId: "",
    userId: "",
  });

  const { data: rolesData } = useQuery(GET_ROLES);
  const { data: clustersData } = useQuery(GET_CLUSTERS);
  const { data: categoriesData } = useQuery(GET_CATEGORIES);
  const { data: programCategoriesData } = useQuery(GET_PROGRAM_CATEGORIES);
  const { data: institutionData, refetch } = useQuery(
    GET_GROUP_WISE_INSTITUTIONS,
    {
      variables: { groupId: id },
    }
  );

  const clearInput = () => {
    setFormData({
      shortName: "",
      fullName: "",
      role: "",
      cluster: "",
      category: [],
      programCategory: [],
      institutionCategory: "",
      password: "",
      candidateId: "",
      userId: "",
    });
  };

  const [CreateInstitution] = useMutation(CREATE_INSTITUTION);
  const [UpdateInstitution] = useMutation(UPDATE_INSTITUTION);
  const [Register] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [createCandidate] = useMutation(CREATE_CANDIDATE);
  const [updateCandidate] = useMutation(UPDATE_CANDIDATE);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value, name } = e.target;

    if (e.target instanceof HTMLInputElement && e.target.type === "checkbox") {
      const { checked } = e.target;
      const categoryType = name; // This would be either "category" or "programCategory"

      setFormData((prevData) => {
        const prevCategoryArray = Array.isArray((prevData as any)[categoryType])
          ? (prevData as any)[categoryType]
          : []; // Ensure it's an array

        const updatedArray = checked
          ? [...prevCategoryArray, id] // Add if checked
          : prevCategoryArray.filter((item: any) => item !== id); // Remove if unchecked

        return {
          ...prevData,
          [categoryType]: updatedArray,
        };
      });
    } else {
      // Handle other input types
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const logoPath = `https://admin.sibaq.in/img/institution_default_logo.png`;

  useEffect(() => {
    if (editInstitution) {
      setFormData({
        shortName: editInstitution.shortName,
        fullName: editInstitution.name,
        role: editInstitution.userId.role.id,
        cluster: editInstitution.cluster?.id,
        category: editInstitution.category?.map(
          (cat: { id: string; name: string }) => cat.id
        ),
        programCategory: editInstitution.programCategory?.map(
          (prog: { id: string; name: string }) => prog.id
        ),
        institutionCategory: editInstitution.candidateId.category.id,
        password: "",
        candidateId: editInstitution.candidateId.id,
        userId: editInstitution.userId.id,
      });
    }
  }, [editInstitution]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (editInstitution) {
        await updateCandidate({
          variables: {
            id: formData.candidateId,
            input: {
              category: formData.institutionCategory,
              name: formData.shortName,
            },
          },
        });
        await UpdateInstitution({
          variables: {
            id: editInstitution.id,
            input: {
              name: formData.fullName,
              shortName: formData.shortName,
              category: formData.category,
              cluster: formData.cluster,
              programCategory: formData.programCategory,
              groupId: id,
            },
          },
        });

        await updateUser({
          variables: {
            id: formData.userId,
            username: formData.shortName,
            role: formData.role,
            group: id,
            institution: editInstitution.id,
            section: section.id,
          },
        });

        toast.success("Institution Updated!");
        clearInput();
        setIsOpen(false);
        refetch();
      } else {
        const { data: candidateData } = await createCandidate({
          variables: {
            input: {
              admissionNo: "001",
              category: formData.institutionCategory,
              gender: "Male",
              name: formData.shortName,
              photo: logoPath,
              section: section.id,
            },
          },
        });

        const candidateId = candidateData?.createCandidate?.id;

        if (candidateId) {
          const { data: institutionData } = await CreateInstitution({
            variables: {
              input: {
                name: formData.fullName,
                shortName: formData.shortName,
                category: formData.category,
                programCategory: formData.programCategory,
                cluster: formData.cluster,
                groupId: id,
                logo: logoPath,
                candidateId: candidateId,
              },
            },
          });

          const institutionId = institutionData?.createInstitution?.id;

          if (institutionId) {
            await updateCandidate({
              variables: {
                id: candidateId,
                input: { institution: institutionId },
              },
            });
            const { data: userData } = await Register({
              variables: {
                username: formData.shortName,
                password: formData.password,
                role: formData.role,
                group: id,
                institution: institutionId,
                section: section.id,
              },
            });

            const userId = userData?.Register?.user.id;

            if (userId) {
              await UpdateInstitution({
                variables: {
                  id: institutionId,
                  input: {
                    userId,
                  },
                },
              });
            } else {
              console.log(userData);
            }
          }
        }

        toast.success("Institution Created!");
      }
      clearInput();
      setIsOpen(false);
      refetch();
    } catch (error: any) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors[0]?.message
          ? error.graphQLErrors[0]?.message
          : "Failed to Create/Update Institution";
      toast.error(errorMessage);
    }
  };

  const handleEdit = (institution: any) => {
    setEditInstitution(institution);
    setIsOpen(true);
  };

  return (
    <>
      <div className="card">
        <div className="card-header flex justify-between items-center">
          <h4 className="card-title">{title}</h4>
          <div>
            <button
              onClick={() => setIsOpen(true)}
              className="btn bg-primary/20 text-sm font-medium text-primary hover:text-white hover:bg-primary"
            >
              <i className="mgc_add_circle_line me-3"></i> Add
            </button>
          </div>
        </div>

        <div className="py-6">
          <div className="px-6">
            {institutionData?.groupWiseInstitution.map(
              (item: any, index: number) => (
                <React.Fragment key={index}>
                  <InstitutionCard
                    data={item}
                    refetch={refetch}
                    handleEdit={handleEdit}
                  />
                  <hr />
                </React.Fragment>
              )
            )}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={clearInput}
        title={
          editInstitution ? "Edit Institution" : `Add Institution in ${title}`
        }
        handleSubmit={handleSubmit}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="shortName"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Short Name
            </label>
            <div className="md:col-span-3">
              <input
                type="text"
                className="form-input"
                id="shortName"
                placeholder="Enter short name"
                value={formData.shortName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="fullName"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Full Name
            </label>
            <div className="md:col-span-3">
              <input
                type="text"
                className="form-input"
                id="fullName"
                placeholder="Enter full name"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {!editInstitution && (
            <div className="grid grid-cols-4 items-center gap-6">
              <label
                htmlFor="password"
                className="text-gray-800 text-sm font-medium inline-block mb-2"
              >
                Password
              </label>
              <div className="md:col-span-3">
                <input
                  type="password"
                  className="form-input"
                  id="password"
                  placeholder="Enter password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          )}

          <div className="grid grid-cols-4 items-center gap-6">
            <label className="text-gray-800 text-sm font-medium inline-block mb-2">
              Available Category
            </label>
            <div className="gap-2 grid grid-cols-3 col-span-3">
              {categoriesData?.categories?.map((category: any) => (
                <div className="flex items-center space-x-2" key={category.id}>
                  <input
                    type="checkbox"
                    name="category"
                    className="rounded"
                    id={category.id}
                    checked={formData.category?.includes(category.id)}
                    onChange={handleChange}
                  />
                  <label htmlFor={category.id}> {category.name}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-6">
            <label className="text-gray-800 text-sm font-medium inline-block mb-2">
              Available Program Category
            </label>
            <div className=" grid grid-cols-4 col-span-3 gap-2">
              {programCategoriesData?.programCategories?.map(
                (programCategory: any) => (
                  <div
                    className="flex items-center space-x-2"
                    key={programCategory.id}
                  >
                    <input
                      type="checkbox"
                      name="programCategory"
                      className="rounded"
                      id={programCategory.id}
                      checked={formData.programCategory?.includes(
                        programCategory.id
                      )}
                      onChange={handleChange}
                    />
                    <label htmlFor={programCategory.id}>
                      {" "}
                      {programCategory.name}
                    </label>
                  </div>
                )
              )}
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="cluster"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Cluster
            </label>
            <div className="md:col-span-3">
              <select
                id="cluster"
                className="form-select"
                value={formData.cluster}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a Cluster
                </option>
                {clustersData?.clusters.map((cluster: any) => (
                  <option key={cluster.id} value={cluster.id}>
                    {cluster.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="role"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Role
            </label>
            <div className="md:col-span-3">
              <select
                id="role"
                className="form-select"
                value={formData.role}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a Role
                </option>
                {rolesData?.roles.map((role: any) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-4 items-center gap-6">
            <label
              htmlFor="institutionCategory"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Category
            </label>
            <div className="md:col-span-3">
              <select
                id="institutionCategory"
                className="form-select"
                value={formData.institutionCategory}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a Category
                </option>
                {categoriesData?.categories.map((institutionCategory: any) => (
                  <option
                    key={institutionCategory.id}
                    value={institutionCategory.id}
                  >
                    {institutionCategory.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default GroupCard;
