import React, { useState } from "react";

interface Message {
    id: number;
    sender: string;
    profilePath: string;
    content: string;
    imageUrl?: string;
}

const SupportChat: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([
        { id: 1, sender: "support", profilePath: "img/logo-sm.png", content: "Hello user, the weather is nice. If you encounter any issues, feel free to ask me. I'm here at your service." },
        {
            id: 2,
            profilePath: "img/user.png",
            sender: "user",
            content: "I like to know about item registration, when will it start?",
        },
        {
            id: 3,
            profilePath: "img/logo-sm.png",
            sender: "support",
            content: "Item registration will start at 12:00 AM  on 2024-11-07. We will inform you  about the details via email.",
        },
    ]);
    const [showOptions, setShowOptions] = useState(false);
    const [newMessage, setNewMessage] = useState("");

    const handleSendMessage = () => {
        if (newMessage.trim() === "") return;

        setMessages((prevMessages) => [
            ...prevMessages,
            { id: Date.now(), sender: "user", profilePath: "img/user.png", content: newMessage },
        ]);
        setNewMessage("");
    };

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const endChat = () => {
        setMessages([]);
        setShowOptions(false);
    }

    const sendMessage = (e: any) => {
        if (newMessage.trim() === "") return;
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    }

    return (
        <div className="w-full max-w-sm h-[500px] mx-auto bg-white shadow-lg rounded-3xl rounded-br-none overflow-hidden flex flex-col z-50">
            <div className="flex items-center justify-between p-4 bg-red text-white">
                <div className="flex items-center">
                    <div className="relative p mr-2 bg-white rounded-full">
                        <img
                            src="img/logo-sm.png"
                            alt="Sibaq Logo"
                            className="w-8 h-8 rounded-full object-cover"
                        />
                        <div className="absolute bottom-0 right-0 w-3 h-3 border-2 border-red rounded-full bg-green-500"></div>
                    </div>
                    <span className="font-medium">SIBĀQ Support</span>
                </div>
                <div className="relative">
                    <button onClick={toggleOptions} className="focus:outline-none">
                        <i className="mgc_more_1_fill"></i>
                    </button>
                    {showOptions && (
                        <div className="absolute right-0 mt-2 w-40 bg-white border rounded-lg shadow-lg z-10">
                            <button className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100"
                                onClick={endChat}
                            >
                                <i className="mgc_delete_2_line"> </i>
                                Clear Chat
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex-1 p-4 overflow-y-auto space-y-4">
                {messages.map((msg) => (
                    <div
                        key={msg.id}
                        className={`flex justify-start items-end ${msg.sender === "support" ? "flex-row" : "flex-row-reverse"
                            }`}
                    >
                        {msg.sender === "support" ?
                            (
                                <img
                                    src={msg.profilePath}
                                    alt={`${msg.sender} Profile`}
                                    className="w-8 h-8 rounded-full mx-2 object-cover"
                                />
                            ) :
                            (
                                <div className="px-2 py-1 rounded-full mx-2 grid place-items-center bg-red text-white">
                                    {msg.sender[0].toUpperCase()}
                                </div>
                            )
                        }
                        <div
                            className={`${msg.sender === "support" ? "bg-chat-green bg-opacity-60 text-gray-800 rounded-bl-none" : "bg-chat-blue bg-opacity-50 text-black rounded-br-none"
                                } p-3 rounded-xl max-w-xs`}
                        >
                            <p>{msg.content}</p>
                            {/* {msg.imageUrl && (
                                <img
                                    src={msg.imageUrl}
                                    alt="Attached"
                                    className="mt-2 rounded-lg"
                                />
                            )} */}
                        </div>
                    </div>
                ))}
            </div>

            <div className="border-t p-4 flex items-center">
                <input
                    type="text"
                    placeholder="Type a message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={sendMessage}
                    className="flex-1 border-none rounded-lg px-4 py-2 focus:outline-none focus:ring-0"
                />
                <button
                    onClick={handleSendMessage}
                    className={`text-xl text-blue-400 focus:outline-none ${newMessage.length > 0 ? "block" : "hidden"}`}
                >
                    <i className="mgc_send_line"></i>
                </button>
            </div>
        </div>
    );
};

export default SupportChat;
