
interface ProgramProps {
  program: {
    concept: string;
    tools: [string];
    duration: string;
    noOfCandidates: string;
  };
}

function ProgramDetails({ program }: ProgramProps) {
  return (
    <div className="card">
      <div className="p-6">
        <h4 className="text-gray-900 font-semibold mb-3 border-b border-gray-200">
          Concept Note
        </h4>

        <p className="text-gray-600 text-sm my-5">{program.concept}</p>
        <div className="flex justify-between">
          <div>
            <h4 className="text-gray-900 font-semibold mb-3 border-b border-gray-200 mt-4">
              Tools
            </h4>
            <div className="flex space-x-3">
              {program.tools.length ? (
                program.tools.map((tool, index) => (
                  <p
                    className="text-blue-800 text-sm uppercase font-semibold"
                    key={index}
                  >
                    {tool}
                  </p>
                ))
              ) : (
                <p className="text-blue-800 text-sm uppercase font-semibold">
                  No Tools
                </p>
              )}
            </div>
          </div>
          <div>
            <h4 className="text-gray-600 font-semibold mb-3 border-b border-gray-200 mt-4">
              Duration
            </h4>
            <p>{program.duration} Minutes </p>
          </div>
          <div>
            <h4 className="text-gray-600 font-semibold mb-3 border-b border-gray-200 mt-4">
              No Of Candidates
            </h4>
            <p>{program.noOfCandidates} Candidates </p>
          </div>
        </div>
      </div>
      {/* <div className="p-5 flex justify-between items-center bg-gray-200">
        <div>
          <p>Date And Time</p>
          <p className="font-semibold text-gray-600">21 Nov 2024</p>
        </div>
        <div>
          <p>Venue 1</p>
          <p className="font-semibold text-gray-600">21 Nov 2024</p>
        </div>
        <AssignedTo />
        <AssignedTo />
        <AssignedTo />
      </div> */}
    </div>
  );
}

export default ProgramDetails;
