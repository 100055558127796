import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { GET_PARTICIPANTS_BY_ID, GET_REGISTRATION_PARTICIPATIONS } from '../../graphql/queries/participation';
import { CREATE_ITEM_REGISTRATION, UPDATE_ITEM_REGISTRATION } from "../../graphql/mutations/itemReg";
import toast from "react-hot-toast";
import UploadImage from "./UploadImage";
import { GET_TOPIC_SELECTIONS, GET_TOPIC_SELECTIONS_BY_ID } from "../../graphql/queries/topicSelection";
import { useAuth } from "../../context/AuthContext";
import { UPDATE_TOPIC_SELECTION } from "../../graphql/mutations/topicSelection";
import { UPDATE_REGISTRATION_STATUS } from "../../graphql/mutations/participation";

interface RegisterItemProps {
    selectedItem: any; // For editing, the selected item will be passed
    onSave: () => void; // Callback when saving is complete
    onCancel: () => void; // Callback when canceling the edit
    refetch: () => void;
}

const RegisterItem: React.FC<RegisterItemProps> = ({ selectedItem, onSave, onCancel, refetch }) => {
    const [loadingFile, setLoadingFile] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [filteredCandidates, setFilteredCandidates] = useState<any[]>([]); // To store candidates based on program
    const [topics, setTopics] = useState<any[]>([]);
    // const [ setSelectedCandidate] = useState<any>(null);
    const [isTopicSelection, setIsTopicSelection] = useState(false);
    const [group, setGroup] = useState<string>("");
    const [programCode, setProgramCode] = useState<string>("");
    const [topic, setTopic] = useState<string>("");
    const [topicId, setTopicId] = useState<string>("");
    const [selectedTopic, setSelectedTopic] = useState<string>("");
    const firstCandidate = filteredCandidates[0];

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedParticipation, setSelectedParticipation] = useState<any>({});
    const dropdownRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        institution: '',
        program: '',
        link: '',
        participation: '',
        file: null,
        remarks: ''
    });

    const { loading, error, data, refetch: refetchData } = useQuery(GET_REGISTRATION_PARTICIPATIONS);
    const { error: topicsError, data: topicsData, refetch: topicsRefetch } = useQuery(GET_TOPIC_SELECTIONS);
    const { loading: topicLoading, refetch: topicRefetch } = useQuery(GET_TOPIC_SELECTIONS_BY_ID, {
        variables: { id: topicId },
        skip: !topicId
    });
    const {  refetch: participationRefetch } = useQuery(GET_PARTICIPANTS_BY_ID, {
        variables: { id: formData.participation },
        skip: !formData.participation
    });

    // Filter options based on search term
    const filteredOptions = data?.ItemRegistrableParticipations.filter((option: any) =>
        option?.program?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [createItemReg] = useMutation(CREATE_ITEM_REGISTRATION);
    const [updateItemReg] = useMutation(UPDATE_ITEM_REGISTRATION);
    const [updateRegistrationStatus] = useMutation(UPDATE_REGISTRATION_STATUS);
    const [updateTopic] = useMutation(UPDATE_TOPIC_SELECTION);

    const { user } = useAuth();


    // Load form data if editing an item

    const handleCandidateChange = (selectedParticipationId: string) => {
        const participation = data?.ItemRegistrableParticipations
            .filter((participation: any) => participation?.id === selectedParticipationId);
        setSelectedParticipation(participation?.[0]?.program)
        setFormData({ ...formData, program: participation[0]?.program?.id, institution: participation[0]?.institution?.id, participation: selectedParticipationId })
        setFilteredCandidates(participation[0]?.candidate || []);
        // setSelectedCandidate(participation[0]?.candidate[0]); // Set the first candidate by default
    };
    
    const handleTopicSelection = (selectedProgramId: string) => {
        topicsRefetch();
        if (selectedItem) {
            let selectedTopicId = topicsData?.topicSelections?.filter((item: any) => item?.program?.id === selectedProgramId && item.topic === selectedItem.title);
            setSelectedTopic(selectedTopicId[0]?.id);
        }
        let topics = topicsData.topicSelections.filter((item: any) => item?.program?.id === selectedProgramId);

        switch (user?.institution?.groupId?.name) {
            case "GROUP A":
                setGroup("groupA");
                setTopics(topics.filter((item: any) => item.groupA === true))
                break;
            case "GROUP B":
                setGroup("groupB");
                setTopics(topics.filter((item: any) => item.groupB === true))
                break;
            case "GROUP C":
                setGroup("groupC");
                setTopics(topics.filter((item: any) => item.groupC === true))
                break;
            default:
                setGroup("");
                setTopics(topics)
                break;
        }
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        if (isOpen) {
            searchInputRef.current?.focus();
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        if (selectedItem) {
            handleCandidateChange(selectedItem.program.id);
            setFormData({
                title: selectedItem.title || '',
                description: selectedItem.description || '',
                institution: selectedItem.institution?.id || '',
                participation: selectedItem.participation?.id || '',
                program: selectedItem.program?.id || '',
                link: selectedItem.link || '',
                file: selectedItem.file,
                remarks: '',
            },);
            setSelectedParticipation(selectedItem.program)
            if (selectedItem.program?.programCode === "TW21" || selectedItem.program?.programCode === "AW18") {
                setIsTopicSelection(true);
                setProgramCode(selectedItem.program?.programCode)
                handleTopicSelection(selectedItem.program?.id);
            } else {
                setIsTopicSelection(false);
            }
        }
    }, [selectedItem]);

    // Handle program change and filter candidates based on the selected program


    // Filter candidates based on the selected program

    const handleProgramChange = (participationId: string, programCode: string, programId: string) => {
        const selectedParticipationId = participationId;
        handleCandidateChange(selectedParticipationId);
        if (programCode === "TW21" || programCode === "AW18") {
            setIsTopicSelection(true);
            handleTopicSelection(programId);
        } else {
            setIsTopicSelection(false);
        }
    };

    // Handle candidate selection
    // const handleCandidateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     const candidateId = e.target.value;
    //     const selectedCandidate = filteredCandidates.find(cand => cand.id === candidateId);
    //     setSelectedCandidate(selectedCandidate);
    //     setFormData({ ...formData, participation: candidateId });
    // };

    const handleFileChange = (file: any | null) => {
        setFormData({ ...formData, file });
    };

    const uploadFile = async (file: File): Promise<string> => {
        const uploadFormData = new FormData();
        uploadFormData.append('file', file);
        uploadFormData.append('upload_preset', import.meta.env.VITE_UPLOAD_PRESET);

        try {
            const response = await fetch(`https://api.cloudinary.com/v1_1/${import.meta.env.VITE_CLOUD_NAME}/image/upload`, {
                method: 'POST',
                body: uploadFormData,
            });
            const data = await response.json();
            return data.secure_url;
        } catch (error) {
            console.error("Error uploading file:", error);
            throw new Error("File upload failed");
        }
    };

    const clearForm = () => {
        setPreviewUrl(null);
        onCancel()
        handleCandidateChange('')
        setFormData({
            title: '',
            description: '',
            institution: '',
            program: '',
            link: '',
            file: null,
            participation: '',
            remarks: ''
        });
        setIsTopicSelection(false);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        setLoadingFile(true);
        e.preventDefault();
        try {
            // console.log(filteredCandidates)

            if (!formData.program) {
                toast.error("Select the program");
                return;
            }
            if (!formData.title) {
                toast.error("Fill the name field");
                return;
            }
            setLoadingFile(true);
            let fileUrl = '';

            if (formData.file) {
                fileUrl = await uploadFile(formData.file);
            }

            if (isTopicSelection) {
                const { data: updatedTopicData } = await topicRefetch(); // Get updated data directly
                if (!updatedTopicData?.topicSelection[group]) {
                    toast.error("Selected topic is already registered");
                    handleProgramChange(formData.program, programCode, formData.program)
                    return;
                }
            }

            if (selectedItem) {
                // Update existing item
                await updateItemReg({
                    variables: {
                        id: selectedItem.id,
                        input: {
                            program: formData.program,
                            title: isTopicSelection ? topic : formData.title,
                            description: formData.description,
                            file: fileUrl || selectedItem.file,
                            link: formData.link,
                            participation: formData.participation,
                            institution: formData.institution,
                            status: 'PENDING',
                            remarks: '',
                        }
                    }
                });
                if (isTopicSelection) {
                    await updateTopic({
                        variables: {
                            id: formData.title,
                            input: {
                                [group]: false
                            }
                        }
                    });
                    await updateTopic({
                        variables: {
                            id: selectedTopic,
                            input: {
                                [group]: true
                            }
                        }
                    });
                    topicsRefetch();
                }
                toast.success('Item updated successfully');
            } else {
                // Create new item

                const { data: updatedParticipationData } = await participationRefetch(); // Get updated data directly
                if (updatedParticipationData?.participation?.isRegistered) {
                    toast.error("Already registered for this program");
                    return;
                }

                await createItemReg({
                    variables: {
                        input: {
                            program: formData.program,
                            title: isTopicSelection ? topic : formData.title,
                            description: formData.description,
                            file: fileUrl,
                            link: formData.link,
                            participation: formData.participation,
                            institution: formData.institution,
                            status: "PENDING"
                        }
                    }
                });
                await updateRegistrationStatus({
                    variables: {
                        id: formData.participation,
                        input: {
                            isRegistered: true
                        }
                    }
                })
                if (isTopicSelection) {
                    await updateTopic({
                        variables: {
                            id: formData.title,
                            input: {
                                [group]: false
                            }
                        }
                    })
                    topicsRefetch();
                }
                toast.success('Item registration successful');
            }
            refetchData();
            onSave();
            refetch();
            clearForm()
        } catch (err) {
            console.error('Error during registration:', err);
            toast.error('Item registration failed');
        } finally {
            setLoadingFile(false);
        }
    };

    if (error) return <p>Error: {error.message}</p>;
    if (topicsError) return <p>Error: {topicsError.message}</p>;

    return (
        <div className="bg-white h-fit p-6 rounded-lg shadow-sm dark:bg-slate-800">
            <div className="flex justify-between flex-1 align-center">
                <h2 className="text-base font-medium mb-4">{selectedItem ? 'Edit Item' : 'Register an Item'}</h2>
                {selectedItem ? <button className="bg-gray-400 text-white px-2 text-xs rounded-lg max-h-8" onClick={clearForm}>Cancel</button> : ''}
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                    <label className="block text-gray-700 text-sm mb-2">Select Program</label>

                    <div className="relative" ref={dropdownRef}>
                        {/* Selected Option Display */}
                        <div className="w-full">
                            <div className="relative">
                                <input
                                    type="button"
                                    value={selectedParticipation?.name ? `${selectedParticipation?.name} | ${selectedParticipation?.category?.name}` : `${loading ? "Loading Programs" : "Select Program"}`}
                                    onClick={() => setIsOpen(!isOpen)}
                                    placeholder={loading ? "Loading Programs" : "Select Program"}
                                    className="form-input text-left cursor-pointer w-full p-2 border border-gray-300 rounded-md"
                                />
                                <span className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <i className="mgc_down_fill me-2 text-xl"></i>
                                </span>
                            </div>
                        </div>
                        {/* Dropdown Options */}
                        {isOpen && !selectedItem && (
                            <div className="absolute top-full left-0 w-full mt-2 bg-white border rounded shadow-lg z-10">

                                <div className="w-full">
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        ref={searchInputRef}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="search Program"
                                        className="form-input cursor-pointer w-full p-2 border border-gray-300 rounded-md"
                                    />
                                </div>

                                {/* Options List */}
                                <ul className="max-h-48 overflow-y-auto">
                                    {filteredOptions?.map((option: any, index: any) => (
                                        <li
                                            key={index}
                                            onClick={() => {
                                                // let code = data?.ItemRegistrableParticipations?.filter((item: any) => item?.id === e.target.value);
                                                setIsOpen(false)
                                                setSearchTerm("");
                                                setProgramCode(option.program?.programCode)
                                                handleProgramChange(option.id, option.program?.programCode, option.program?.id);

                                            }}
                                            className="px-4 py-2 cursor-pointer hover:bg-blue-500 hover:text-white"
                                        >
                                            {`${option.program?.name} | ${option.program?.category?.name}`}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    {/* <select
                        id="program"
                        name="program"
                        value={formData.participation}
                        onChange={(e) => {
                            let code = data?.ItemRegistrableParticipations?.filter((item: any) => item?.id === e.target.value);
                            setProgramCode(code[0]?.program?.programCode)
                            handleProgramChange(e.target.value, code[0].program?.programCode, code[0].program?.id);
                        }}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="">{loadingFile ? "Loading..." : "Select Program"}</option>
                        {selectedItem &&
                            <option key={selectedItem?.participation?.id} value={selectedItem?.participation?.id}>
                                {selectedItem?.program?.name} | {selectedItem?.program?.category?.name}
                            </option>
                        }
                        {data?.ItemRegistrableParticipations?.map((participation: any) => (
                            <option key={participation?.id} value={participation?.id}>
                                {participation?.program?.name} | {participation?.program?.category?.name}
                            </option>
                        ))}
                    </select> */}

                </div>

                {filteredCandidates.length > 0 && (
                    <div className="flex justify-start flex-1">
                        <img src={firstCandidate?.photo} alt={firstCandidate?.name} className="w-10 h-10 rounded-full mr-3" />
                        <div className="flex flex-col justify-between flex-1">
                            <h5 className="font-semibold">{firstCandidate?.name}</h5>
                            <p>{firstCandidate?.category}</p>
                        </div>
                    </div>
                )}

                {isTopicSelection ?

                    <div>
                        {selectedItem &&
                            <div>
                                <h3 className="text-md mb-4"><span className=" font-semibold">Selected Topic:</span> {selectedItem.title}</h3>
                            </div>
                        }
                        <label className="block text-gray-700 text-sm mb-2">Select Topic</label>
                        <select
                            id="program"
                            name="title"
                            value={formData.title}
                            disabled={topicLoading}
                            onChange={(e) => {
                                let selectedTopic = topicsData.topicSelections.filter((item: any) => item?.id === e.target.value);
                                setTopic(selectedTopic[0].topic);
                                setTopicId(e.target.value)
                                setFormData({ ...formData, title: e.target.value });
                            }}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">Select Topic</option>
                            {topics?.map((topic: any) => (
                                <option key={topic?.id} value={topic?.id}>
                                    {topic?.topic}
                                </option>
                            ))}
                        </select>
                    </div>
                    :
                    <>
                        <div>
                            <label className="block text-gray-700 text-sm mb-2">Name / Topic / <span className="italic">Kithab</span></label>
                            <input
                                type="text"
                                className="form-input w-full p-2 border border-gray-300 rounded-md"
                                placeholder="Name / Topic / Text"
                                name="title"
                                value={formData.title}
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm mb-2">Description / Lyrics / Author / Context / Portion</label>
                            <textarea
                                className="form-input w-full p-2 border border-gray-300 rounded-md"
                                placeholder="Description / Lyrics / Author / Context / Portion"
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            ></textarea>
                        </div>

                        <div>
                            <label className="block text-gray-700 text-sm mb-2">Link</label>
                            <input
                                type="text"
                                className="form-input w-full p-2 border border-gray-300 rounded-md"
                                placeholder="Enter link of the item"
                                value={formData.link}
                                onChange={(e) => setFormData({ ...formData, link: e.target.value })}
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium">File</label>
                            <UploadImage
                                defaultImageUrl={formData.file}
                                onFileChange={handleFileChange}
                                previewUrl={previewUrl}
                                setPreviewUrl={setPreviewUrl}
                            />
                        </div>
                    </>
                }
                <div className="flex justify-between">
                    <button type="submit" disabled={loadingFile} className={`${selectedItem ? "bg-blue-500" : "bg-green-500"} disabled:bg-gray-500 disabled:text-white text-white disabled:cursor-not-allowed py-2 px-4 rounded-md w-full`} >
                        {loadingFile ? "Submitting..." : selectedItem ? "Update Item" : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default RegisterItem;