import React, { useState } from "react";

interface Tab {
  id: string;
  label: string;
  content: React.ReactNode;
}

const TabComponent: React.FC<{ tabs: Tab[] }> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].id);

  const activeContent = tabs.find((tab) => tab.id === activeTab)?.content;

  return (
    <div>
      {tabs.length > 1 && <div className="flex text-center justify-center ">
        <ul className="flex text-center justify-center w-fit text-gray-500 bg-gray-300 rounded-full p-1">
          {tabs.map((tab) => (
            <li key={tab.id}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(tab.id);
                }}
                className={`flex justify-center p-2 px-4 h-fit w-fit cursor-pointer ${
                  activeTab === tab.id
                    ? "bg-white rounded-full shadow text-indigo-900"
                    : ""
                }`}
              >
                {tab.label}
              </div>
            </li>
          ))}
        </ul>
      </div>}

      <div className="mt-4 p-4">
        <p>{activeContent}</p>
      </div>
    </div>
  );
};

export default TabComponent;
