import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import JudgeNav from "../../components/judgement/JudgeNav";
import JudgeCard from "../../components/judgement/JudgeCard";
import { GET_PROGRAMS } from "../../graphql/queries/program";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "../../components/UIElements/Model";
import toast from "react-hot-toast";
import { CREATE_JUDGES, UPDATE_JUDGES } from "../../graphql/mutations/judgement";
import { GET_HOSTS } from "../../graphql/queries/host";
import { GET_JUDGEMENT } from "../../graphql/queries/judgement";
import { UPDATE_PROGRAM } from "../../graphql/mutations/program";

interface IJudges {
  id: string;
  name: string;
  host: string;
  email: string;
  phoneNumber: string;
}

interface Program {
  id: string;
  name: string;
  skill: {
    id: string;
    name: string;
  };
  category: {
    id: string;
    name: string;
  };
  eliminationJudge: IJudges[];
  eliminationStartingTime: string;
  finalDate: string;
  eliminationDate: string;
  eliminationVenue: {
    id: string;
    name: string;
  };
  finalJudge: IJudges[];
  finalStartingTime: string;
  finalVenue: {
    id: string;
    name: string;
  };
  programCategory: {
    id: string;
    name: string;
  }
}

const Judgement: React.FC = () => {
  const { data: hostData } = useQuery(GET_HOSTS);
  const { data: judgesData, refetch: judgesFetch } = useQuery(GET_JUDGEMENT);
  const { data: programData, loading, error, refetch } = useQuery(GET_PROGRAMS);

  const [createJudge] = useMutation(CREATE_JUDGES);
  const [updateJudge] = useMutation(UPDATE_JUDGES);
  const [updateProgram] = useMutation(UPDATE_PROGRAM);
  // console.log(programData);

  // State variables
  const [programList, setProgramList] = useState<Program[]>([]);
  const [judgeList, setJudgeList] = useState<IJudges[]>([]);
  const [filteredProgramData, setFilteredProgramData] = useState<Program[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedJudge, setSelectedJudge] = useState<IJudges | null>(null);
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isJudgeView, setIsJudgeView] = useState(false);
  const [selectedRound, setSelectedRound] = useState<string>("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    host: "",
    phoneNumber: "",
  });

  const [formProgramData, setFormProgramData] = useState({
    // name: "",
    // skill: "",
    // category: "",
    eliminationJudge: [] as string[],
    finalJudge: [] as string[],
  });

  // Effect to load program data
  useEffect(() => {
    if (programData && programData.programs) {
      setProgramList(programData.programs);
    }
  }, [programData]);

  useEffect(() => {
    if (judgesData && judgesData.judges) {
      setJudgeList(judgesData.judges);
    }
  }, [judgesData]);

  // Set form data when a judge is selected
  useEffect(() => {
    if (selectedJudge) {
      setFormData({
        name: selectedJudge.name,
        email: selectedJudge.email,
        host: selectedJudge.host,
        phoneNumber: selectedJudge.phoneNumber,
      });
    }
  }, [selectedJudge]);

  // Set program data when a program is selected
  useEffect(() => {
    if (selectedProgram) {
      setFormProgramData({
        eliminationJudge: selectedProgram.eliminationJudge.map((judge) => judge.id),
        finalJudge: selectedProgram.finalJudge.map((judge) => judge.id),
      });
    }
  }, [selectedProgram]);

  // Handle search
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    const filteredData = programList.filter((program) =>
      program.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredProgramData(filteredData);
  };

  // Clear form data
  const clearForm = () => {
    setFormData({
      name: "",
      email: "",
      host: "",
      phoneNumber: "",
    });
    setIsModalOpen(false);
    setIsJudgeView(false);
    setSelectedJudge(null);
  };

  // Create a new judge
  const handleCreate = () => {
    setIsEditing(true);
    clearForm();
    setIsModalOpen(true);
    setIsJudgeView(true);
  };

  // Handle changes in judge selection for program
  const handleJudgeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newJudgeId = e.target.value;
    const filteredData = judgeList.filter(judge => judge.id === newJudgeId)[0];
    setSelectedJudge(filteredData);

    if (selectedRound === "elimination") {
      setFormProgramData((prev) => ({
        ...prev,
        eliminationJudge: [...prev.eliminationJudge, newJudgeId],
      }));
    } else if (selectedRound === "final") {
      setFormProgramData((prev) => ({
        ...prev,
        finalJudge: [...prev.finalJudge, newJudgeId],
      }));
    }
  };

  const handleHostChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHostId = e.target.value;
    setFormData({ ...formData, host: selectedHostId })

  };

  // Add judge to a program
  const handleAddJudge = (program: Program): void => {
    setIsEditing(true);
    clearForm();
    setIsModalOpen(true);
    setIsJudgeView(false);
    setSelectedProgram(program);
  };

  // Handle judge submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const inputData = { ...formData };

      if (selectedJudge) {
        await updateJudge({ variables: { id: selectedJudge.id, input: inputData } });
        toast.success("Judge updated successfully");
      } else {
        await createJudge({ variables: { input: inputData } });
        toast.success("Judge created successfully");
      }

      clearForm();
      judgesFetch();
      refetch();
    } catch (error) {
      console.error("Error during judge submission:", error);
      toast.error("Error saving judge");
    }
  };

  // Handle judge submission
  const handleProgramJudgeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Destructure the relevant fields from selectedProgram
      const { skill, category, programCategory, id, eliminationJudge, finalJudge } = selectedProgram || {};
      console.log(skill, category, programCategory, id, eliminationJudge, finalJudge);

      // Create the updatedProgram object without __typename or any extra fields
      const updatedProgram = {
        skill: skill?.id, // Extract the skill ID
        category: category?.id, // Extract the category ID
        programCategory: programCategory?.id, // Extract the programCategory ID
        eliminationJudge: [] as string[],
        finalJudge: [] as string[],

      };
      updatedProgram.eliminationJudge = formProgramData?.eliminationJudge?.map((judgeId) => judgeId);
      updatedProgram.finalJudge = formProgramData?.finalJudge?.map((judgeId) => judgeId);
      console.log(updatedProgram);

      // Make sure you're passing the correct id and input structure for the mutation
      const updateLoading = toast.loading('Adding judge to the program')
      await updateProgram({
        variables: { id, input: updatedProgram },
      });
      refetch();
      toast.remove(updateLoading);
      toast.success("Judge added to the program successfully!");
      clearForm();
    } catch (error) {
      console.error("Error adding judge to the program:", error);
      toast.error("Error adding judge to the program.");
    }
  };

  const removeJudge = async (programId: string, judgeId: string, round: string) => {
    const confirmRemoval = confirm('Are you sure you want to remove the judge from the program?');
    if (confirmRemoval) {
      try {
        // Find the program in the list
        const program = programList.find((program) => program.id === programId);
        if (!program) throw new Error('Program not found');
  
        // Prepare the updated judge list based on the round
        const updatedProgram = {
          skill: program.skill?.id,
          category: program.category?.id,
          programCategory: program.programCategory?.id,
          eliminationJudge: program.eliminationJudge?.map(j => j.id),
          finalJudge: program.finalJudge?.map(j => j.id),
        };
  
        if (round === 'eliminationJudge') {
          updatedProgram.eliminationJudge = updatedProgram.eliminationJudge.filter((id) => id !== judgeId);
        } else if (round === 'finalJudge') {
          updatedProgram.finalJudge = updatedProgram.finalJudge.filter((id) => id !== judgeId);
        } else {
          throw new Error('Invalid round specified');
        }
  
        // Trigger the loading state
        const updateLoading = toast.loading('Removing judge from the program');
  
        // Call the mutation with the updated data
        await updateProgram({
          variables: { id: programId, input: updatedProgram },
        });
  
        // Refetch the updated data
        refetch();
        toast.remove(updateLoading);
        toast.success("Judge removed from the program successfully!");
      } catch (error) {
        console.error("Error removing judge from the program:", error);
        toast.error("Error removing judge from the program.");
      }
    }
  };
  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <PageTitle pagetitle="Judgement" subtitle="Judgement" />
      <JudgeNav
        handleSearch={handleSearch}
        searchQuery={searchQuery}
        setFilteredProgramData={setFilteredProgramData}
        programList={programList}
        handleCreate={handleCreate}
        judgesData={judgesData}
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center">
        {filteredProgramData.map((programData) => (
          <JudgeCard
            key={programData.id}
            programData={programData}
            handleAddJudge={handleAddJudge}
            removeJudge={removeJudge}
          />
        ))}
      </div>

      {/* isModalOpen && (
        // <Modal
        //   title={!isEditing ? "View Judge" : selectedJudge ? "Edit Judge" : "Add Judge"}
        //   isOpen={isModalOpen}
        //   setIsOpen={setIsModalOpen}
        // >
        //   {isJudgeView ? (
        //     <form className="flex flex-col px-2 text-gray-700" onSubmit={handleSubmit}>
        //       <div className="w-full px-1 mt-1 text-gray-700">
        //         <label>Name</label>
        //         <input
        //           type="text"
        //           name="name"
        //           value={formData.name}
        //           onChange={(e) =>
        //             setFormData({ ...formData, name: e.target.value })
        //           }
        //           required
        //           placeholder="Judge Name"
        //         />
        //       </div>

        //       {/* Additional form inputs for email, host, etc. 

        //       <button type="submit" className="btn-primary mt-3">
        //         {selectedJudge ? "Update" : "Submit"}
        //       </button>
        //     </form>
        //   ) : (
        //     <form className="flex flex-col px-2 text-gray-700" onSubmit={handleProgramJudgeSubmit}>
        //       {/* Select Judges for rounds 

        //       <button type="submit" className="btn-primary mt-3">
        //         Assign Judge
        //       </button>
        //     </form>
        //   )}
        // </Modal> */}
      {isModalOpen && (
        isJudgeView ? (
          <Modal
            title={!isEditing ? 'View Judge' : selectedJudge ? 'Edit Judge' : 'Add Judge'}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            handleSubmit={() => handleSubmit}
          >
            <form className="flex flex-col justify-center items-center px-2 text-gray-700" onSubmit={handleSubmit}>
              <div className="w-full px-2">
                <label className="text-gray-600 mt-1 inline-block">Name:</label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
                  placeholder="Enter Name"
                  disabled={!isEditing}
                />
              </div>
              <div className="w-full px-2">
                <label className="text-gray-600 mt-1 inline-block">Email:</label>
                <input
                  type="text"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
                  placeholder="Enter Email"
                  disabled={!isEditing}
                />
              </div>
              <div className="w-full px-2">
                <label className="text-gray-600 mt-1 inline-block">Phone Number:</label>
                <input
                  type="text"
                  value={formData.phoneNumber}
                  onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
                  placeholder="Enter Phone Number"
                  disabled={!isEditing}
                />
              </div>
              <div className="w-full px-2">
                <label className="block text-gray-600 text-sm mt-1">Select Host</label>
                <select
                  id="host"
                  name="host"
                  value={formData.host}
                  onChange={handleHostChange}
                  className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
                >
                  <option value="">Select Host</option>
                  {hostData?.hosts?.map((host: any) => (
                    <option key={host?.id} value={host?.id}>
                      {host?.name} | {host?.place}
                    </option>
                  ))}
                </select>
              </div>

              <button type="submit" className={`${selectedJudge ? "bg-blue-500" : "bg-green-500"} text-white py-2 px-4 m-4 rounded-md w-full`} >
                {selectedJudge ? "Update Item" : "Submit"}
              </button>

            </form>
          </Modal >
        ) : (
          <Modal
            title="Add Judge to Program"
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            handleSubmit={handleProgramJudgeSubmit} // Call the correct submit function
          >
            <form
              className="flex flex-col justify-center items-center px-2 text-gray-700"
              onSubmit={handleProgramJudgeSubmit}
            >
              {/* Select Round */}
              <div className="w-full px-2">
                <label className="block text-gray-600 text-sm mt-1">Select Round</label>
                <select
                  id="round"
                  name="round"
                  value={selectedRound}
                  onChange={(e) => setSelectedRound(e.target.value)} // Set the round
                  className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
                >
                  <option value="">Select Round</option>
                  <option value="elimination">Elimination Round</option>
                  <option value="final">Final Round</option>
                </select>
              </div>

              {/* Select Judge */}
              <div className="w-full px-2">
                <label className="block text-gray-600 text-sm mt-1">Select Judge</label>
                <select
                  id="name"
                  name="judge"
                  value={selectedJudge?.id}
                  onChange={(e) => handleJudgeChange(e)}
                  className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-2 inline-block"
                >
                  <option value="">Judge | Host</option>
                  {judgesData?.judges?.map((judge: any) => (
                    <option key={judge?.id} value={judge?.id}>
                      {judge?.name} | {judge?.host}
                    </option>
                  ))}
                </select>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="bg-green-500 text-white py-2 px-4 m-4 rounded-md w-full"
              >
                Add Judge
              </button>
            </form>
          </Modal>


        ))}
    </>
  );
};

export default Judgement;
