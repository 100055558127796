import React, { useState } from 'react'
import MarkCard from './MarkCard';

interface Props {
    marks: [];
    type: string;
    category: string;
}

const MarkCardStructure: React.FC<Props> = ({ marks, type, category }) => {
    const [isOpen, setIsOpen] = useState(false);

    const filterData = (type: string, category: string) => {
        let filtered = marks.filter((mark: any) => {
            return mark.type === type && mark.category === category;
        })
        filtered.sort((a: any, b: any) => (a.name > b.name) ? 1 : -1);
        return filtered;
    }

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            <h3 onClick={handleClick} className="text-lg pr-1 cursor-pointer capitalize w-full inline-flex justify-between items-center"><span>{category} {type}</span> <i className={`${isOpen ? "mgc_up_line" : "mgc_down_line"} text-lg`}></i></h3>
            {isOpen && <div className="space-y-4 bg-gray-100 rounded-xl overflow-hidden px-3 py-4">
                {filterData(type, category).map((mark: any, index: number) => (
                    <MarkCard
                        key={index}
                        item={mark}
                        title={mark.name}
                        category={mark.category}
                        mark={mark.mark}
                        textClass="text-base"
                    />
                ))}
            </div>}
        </div>
    )
}

export default MarkCardStructure