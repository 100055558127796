import { useQuery } from "@apollo/client";
import React from "react";
import PageTitle from "../../components/PageTitle";
// import CategoryCard from "../../components/dashboard/CategoryCard";
import DashboardCard from "../../components/dashboard/DashboardCard";
// import DashboardNotification from "../../components/dashboard/DashboardNotification";
import DashboardStatistics from "../../components/dashboard/DashboardStatitics";
import DashboardSummary from "../../components/dashboard/DashboardSummary";
import SkillOverview from "../../components/dashboard/SkillOverveiw";
import { TOTAL_CANDIDATES } from "../../graphql/queries/candidates";
import { TOTAL_INSTITUTIONS } from "../../graphql/queries/institution";
// import { GET_NOTIFICATIONS } from "../../graphql/queries/notification";
import { GET_PROGRAMS } from "../../graphql/queries/program";
import { Navigate } from "react-router-dom";
const Dashboard: React.FC = () => {
  const {
    data: candidates,
    error: candidatesError,
    loading: candidatesLoading,
  } = useQuery(TOTAL_CANDIDATES);

  // const {
  //   data: notificationData,
  //   error: notificationError,
  //   loading: notificationLoading,
  // } = useQuery(GET_NOTIFICATIONS);

  const {
    data: programs,
    error: programsError,
    loading: programsLoading,
  } = useQuery(GET_PROGRAMS);

  const {
    data: institutions,
    error: institutionsError,
    loading: institutionsLoading,
  } = useQuery(TOTAL_INSTITUTIONS);

  const cardsData = [
    {
      id: 1,
      count: "10000+",
      label: "Total Students",
      avatar: "mgc_group_fill",
      className: "bg-primary/25 text-primary",
    },
    {
      id: 2,
      count: candidates?.totalCandidates?.length,
      label: "Total Candidates",
      avatar: "mgc_group_line",
      className: "bg-primary/25 text-primary",
    },
    {
      id: 3,
      count: programs?.programs?.length,
      label: "Total Programs",
      avatar: "mgc_microphone_line",
      className: "bg-primary/25 text-primary",
    },
    {
      id: 4,
      count: institutions?.totalInstitutions?.length,
      label: "Total Institutions",
      avatar: "mgc_school_line",
      className: "bg-primary/25 text-primary",
    },
  ];
  
  // const usersData = [
  //   {
  //     name: "user 1",
  //     description: "user for a long time",
  //   },
  //   {
  //     name: "user 2",
  //     description: "user for a long time",
  //   },
  //   {
  //     name: "user 3",
  //     description: "user for a long time",
  //   },
  //   {
  //     name: "user 4",
  //     description: "user for a long time",
  //   },
  //   {
  //     name: "user 5",
  //     description: "user for a long time",
  //   },
  //   {
  //     name: "user 6",
  //     description: "user for a long time",
  //   },
  //   {
  //     name: "user 7",
  //     description: "user for a long time",
  //   },
  // ];

  // const [selectedDate, setSelectedDate] = useState<string>("Today");

  // const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedDate(event.target.value);
  // };

  if (
    candidatesError ||
    programsError ||
    institutionsError 
    // notificationError
  ) {
    return <Navigate  to={'/candidates'}/>;
  }
  if (
    candidatesLoading ||
    programsLoading ||
    institutionsLoading 
    // notificationLoading
  ) {
    return ;
  }

  return (
    <>
      <PageTitle pagetitle={"Dashboard"} subtitle={"Dashboard"} />
      <div className="grid 2xl:grid-cols-4 gap-6 mb-6">
        <div className="2xl:col-span-3 grid gap-6">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-6">
            {cardsData.map((data) => (
              <DashboardCard
                key={data.id}
                count={data.count}
                label={data.label}
                avatar={data.avatar}
                className={data.className}
              />
            ))}
          </div>
          <DashboardStatistics />
        </div>
        <DashboardSummary />
      </div>
      {/* <div className="grid lg:grid-cols-5 md:grid-cols-2 gap-3 mb-6">
        {categories.map((category, key) => (
          <CategoryCard
            key={key}
            title={category.title}
            programsCount={category.programCount}
            candidatesCount={category.programCount}
            maxPoints={category.maxPoints}
          />
          
        ))}
      </div> */}
      <div className="grid 2xl:grid-cols-4 md:grid-cols-2 gap-6">
        <SkillOverview />
        <div className="col-span-1">
          <div className="card">
            {/* <div className="card-header">
              <div className="flex justify-between items-center">
                <h4 className="card-title">Notifications</h4>
                <div>
                  <select
                    className="form-input form-select-sm"
                    value={selectedDate}
                    onChange={handleDateChange}
                  >
                    <option value="Today">Today</option>
                    <option value="Yesterday">Yesterday</option>
                    <option value="Tomorrow">Tomorrow</option>
                  </select>
                </div>
              </div>
            </div> */}

            {/* <div className="py-6">
              <div
                className="px-6"
                style={{ maxHeight: "304px", overflowY: "auto" }}
              >
                <h4 className="card-title">Notifications</h4>
                <div className="space-y-4">
                  {notificationData.notifications.map(
                    (notificationData: any) => (
                      <DashboardNotification
                        key={notificationData.id} // Use a unique identifier for keys
                        title={notificationData.title}
                        description={notificationData.description}
                      />
                    )
                  )}
                  <div className="flex items-center justify-center">
                    <div className="animate-spin flex">
                      <i className="mgc_loading_2_line text-xl"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="col-span-1 overflow-hidden">
          <div className="card">
            <div className="card-header flex justify-between items-center">
              <h4 className="card-title">Users</h4>
              <div>
                <select className="form-select form-select-sm">
                  <option selected>Active</option>
                  <option value="1">Offline</option>
                </select>
              </div>
            </div>

            <div className="py-6">
              <div
                className="px-6 overflow-y-scroll"
                data-simplebar
                style={{ maxHeight: "304px" }}
              >
                <div className="space-y-6">
                  <div className="space-y-4">
                    {usersData.map((usersData, key) => (
                      <DashboardUser
                        key={key}
                        name={usersData.name}
                        description={usersData.description}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default Dashboard;
