import React, { useState, useEffect } from "react";
import MenuItem from "./MenuItem";
import SubMenu from "./SubMenu";
import Private from "../../context/Private";

const Sidebar: React.FC = () => {
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);

  const handleSubMenuClick = (menuText: string | null) => {
    setOpenSubMenu((prev) => (prev === menuText ? null : menuText));
  };

  useEffect(() => {
    initSidenav();
  }, []);

  const initSidenav = () => {
    const pageUrl = window.location.href.split(/[?#]/)[0];
    document.querySelectorAll("ul.menu a.menu-link").forEach((element) => {
      const anchorElement = element as HTMLAnchorElement;
      if (anchorElement.href === pageUrl) {
        element.classList.add("active");
        const parentMenu = element.parentElement?.parentElement?.parentElement;
        if (parentMenu && parentMenu.classList.contains("menu-item")) {
          const collapseElement = parentMenu.querySelector(
            '[data-fc-type="collapse"]'
          );
          if (collapseElement && (window as any).frost != null) {
            const collapse = (window as any).frost.Collapse.getInstanceOrCreate(
              collapseElement
            );
            collapse.show();
          }
        }
      }
    });

    setTimeout(() => {
      const activatedItem = document.querySelector(
        "ul.menu .active"
      ) as HTMLElement;
      if (activatedItem != null) {
        const simplebarContent = document.querySelector(
          ".app-menu .simplebar-content-wrapper"
        ) as HTMLElement;
        const offset = activatedItem.offsetTop - 300;
        if (simplebarContent && offset > 100) {
          scrollTo(simplebarContent, offset, 600);
        }
      }
    }, 200);

    const scrollTo = (element: HTMLElement, to: number, duration: number) => {
      const start = element.scrollTop;
      const change = to - start;
      let currentTime = 0;
      const increment = 20;

      const animateScroll = () => {
        currentTime += increment;
        const val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    };

    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };
  };

  return (
    <div className="app-menu">
      {/* Sidenav Brand Logo */}
      <a href="/" className="logo-box">
        {/* Light Brand Logo */}
        <div className="logo-light">
          <img
            src="/img/logo-light.png"
            className="logo-lg h-6"
            alt="Light logo"
          />
          <img src="/img/logo-sm.png" className="logo-sm" alt="Small logo" />
        </div>

        {/* Dark Brand Logo */}
        <div className="logo-dark">
          <img
            src="/img/logo-dark.png"
            className="logo-lg h-6"
            alt="Dark logo"
          />
          <img src="/img/logo-sm.png" className="logo-sm" alt="Small logo" />
        </div>
      </a>

      {/* Sidenav Menu Toggle Button */}
      <button
        id="button-hover-toggle"
        className="absolute top-5 end-2 rounded-full p-1.5"
      >
        <span className="sr-only">Menu Toggle Button</span>
        <i className="mgc_round_line text-xl"></i>
      </button>

      {/* Menu */}
      <div className="srcollbar">
        <ul className="menu" data-fc-type="accordion">
          <Private
            permissions={[
              "|dashboard",
              "|institution",
              "|candidates",
              "|programs",
              "|curb-list",
              "|item-registration",
              "|proposal-submission",
              "|schedule",
              "|participants-count",
            ]}
            element={<li className="menu-title">Menu</li>}
          />
          <Private
            permissions={["dashboard"]}
            element={
              <MenuItem
                to="/"
                iconClass="mgc_home_3_line"
                text="Dashboard"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["institution"]}
            element={
              <MenuItem
                to="/institution"
                iconClass="mgc_bank_line"
                text="Institution"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["candidates"]}
            element={
              <MenuItem
                to="/candidates"
                iconClass="mgc_group_line"
                text="Candidates"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["programs"]}
            element={
              <MenuItem
                to="/programs"
                iconClass="mgc_list_check_3_line"
                text="Programs"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["curb-list"]}
            element={
              <MenuItem
                to="/curb-list"
                iconClass="mgc_sitemap_line"
                text="Curb List"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["item-registration"]}
            element={
              <MenuItem
                to="/item-registration"
                iconClass="mgc_registered_line"
                text="Item Registration"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["proposal-submission"]}
            element={
              <MenuItem
                to="/proposal-submission"
                iconClass="mgc_file_export_line"
                text="Proposal Submission"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["participants-count"]}
            element={
              <MenuItem
                to="/participants-count"
                iconClass="mgc_group_3_line"
                text="Participants Count"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["schedule"]}
            element={
              <MenuItem
                to="/schedule"
                iconClass="mgc_calendar_line"
                text="Schedule"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={[
              "|news",
              "|gallery",
              "|notifications",
              "|downloads",
              "|support",
            ]}
            element={<li className="menu-title">Media</li>}
          />

          <Private
            permissions={["news"]}
            element={
              <MenuItem
                to="/news"
                iconClass="mgc_announcement_line"
                text="News"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />
          <Private
            permissions={["gallery"]}
            element={
              <MenuItem
                to="/gallery"
                iconClass="mgc_photo_album_line"
                text="Gallery"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["notifications"]}
            element={
              <MenuItem
                to="/notifications"
                iconClass="mgc_notification_line"
                text="Notifications"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["downloads"]}
            element={
              <MenuItem
                to="/downloads"
                iconClass="mgc_download_line"
                text="Downloads"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["support"]}
            element={
              <MenuItem
                to="/support"
                iconClass="mgc_chat_1_line"
                text="Suppport"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            permissions={["|result", "|result"]}
            element={<li className="menu-title">Result</li>}
          />

          <Private
            permissions={["debate-result"]}
            element={
              <MenuItem
                to="/result/debate"
                iconClass="mgc_speech_line"
                text="Debate"
                onClick={() => handleSubMenuClick(null)}
              />
            }
          />

          <Private
            roles={["administration"]}
            element={<li className="menu-title">Settings</li>}
          />

          <Private
            roles={["administration"]}
            element={
              <SubMenu
                iconClass="mgc_user_3_line"
                text="Admin"
                items={[
                  { to: "/role", text: "Roles" },
                  { to: "/admins", text: "Admins" },
                ]}
                isOpen={openSubMenu === "Admin"}
                onClick={() => handleSubMenuClick("Admin")}
              />
            }
          />

          <Private
            roles={["administration"]}
            element={
              <SubMenu
                iconClass="mgc_settings_4_line"
                text="Settings"
                items={[
                  { to: "/general", text: "General" },
                  { to: "/functions", text: "Functions" },
                ]}
                isOpen={openSubMenu === "Settings"}
                onClick={() => handleSubMenuClick("Settings")}
              />
            }
          />
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
