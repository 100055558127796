import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  CREATE_PROGRAM,
  UPDATE_PROGRAM,
} from "../../graphql/mutations/program";
import { GET_CATEGORIES } from "../../graphql/queries/category";
import { GET_PROGRAM_CATEGORIES } from "../../graphql/queries/programCategory";
import { GET_SECTIONS } from "../../graphql/queries/section";
import { GET_SKILLS } from "../../graphql/queries/skill";
import { useNavigate } from "react-router-dom";
import { GET_PROGRAMS } from "../../graphql/queries/program";

interface ProgramFormProps {
  initialProgramData?: ProgramData;
}

interface ProgramData {
  id: string;
  programCode: string;
  name: string;
  concept: string;
  duration: number;
  skill: { id: string; name: string }; // Updated to match the structure from your query
  noOfCandidates: number;
  maxSelection: number;
  tools: string[];
  category: { id: string; name: string }; // Updated to match the structure from your query
  programCategory: { id: string; name: string }; // Updated to match the structure from your query
  mode: "stage" | "non_stage";
  type: "single" | "group";
  isRegistrable: boolean;
  isGroupwiseResult: boolean;
  isDebate: boolean;
  isItemRegistrable: boolean;
  isProposalSubmission: boolean;
  isStarred: boolean;
  section: { id: string; name: string }; // Updated to match the structure from your query
}

const ProgramForm: React.FC<ProgramFormProps> = ({ initialProgramData }) => {
  const { data: skillsData } = useQuery(GET_SKILLS);
  const { data: categories } = useQuery(GET_CATEGORIES);
  const { data: programCategories } = useQuery(GET_PROGRAM_CATEGORIES);
  const { data: sectionData } = useQuery(GET_SECTIONS);

  const [programCode, setProgramCode] = useState("");
  const [name, setName] = useState("");
  const [concept, setConcept] = useState("");
  const [duration, setDuration] = useState<null | number>(null);
  const [skill, setSkill] = useState("");
  const [noOfCandidates, setNoOfCandidates] = useState<null | number>(null);
  const [maxSelection, setmaxSelection] = useState<null | number>(null);
  const [tools, setTools] = useState<string[]>([]);
  const [category, setCategory] = useState("");
  const [programCategory, setProgramCategory] = useState("");
  const [mode, setMode] = useState<"stage" | "non_stage">("stage");
  const [type, setType] = useState<"single" | "group">("single");
  const [isRegistrable, setIsRegistrable] = useState(false);
  const [isGroupwiseResult, setIsGroupwiseResult] = useState(false);
  const [isDebate, setIsDebate] = useState(false);
  const [isItemRegistrable, setisItemRegistrable] = useState(false);
  const [isProposalSubmission, setisProposalSubmission] = useState(false);
  const [isStarred, setIsStarred] = useState(false);
  const [section, setSection] = useState("");

  const navigate = useNavigate();

  const [createProgram, { loading: createLoading }] = useMutation(
    CREATE_PROGRAM,
    { refetchQueries: [{ query: GET_PROGRAMS }] }
  );
  const [updateProgram, { loading: updateLoading }] = useMutation(
    UPDATE_PROGRAM,
    { refetchQueries: [{ query: GET_PROGRAMS }] }
  );

  const handleToolsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const toolsArray = inputValue
      .split(",")
      .map((tool) => tool.trim())
      .filter((tool) => tool !== "");
    setTools(toolsArray);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log(e);
    try {
      if (initialProgramData) {
        await updateProgram({
          variables: {
            id: initialProgramData.id,
            input: {
              programCode,
              name,
              concept,
              duration,
              skill,
              noOfCandidates,
              maxSelection,
              tools,
              category,
              programCategory,
              mode,
              type,
              isRegistrable,
              isGroupwiseResult,
              isDebate,
              isStarred,
              isItemRegistrable,
              isProposalSubmission,
              section,
            },
          },
        });
        toast.success("Program Updated Successfully");
      } else {
        await createProgram({
          variables: {
            input: {
              programCode,
              name,
              concept,
              duration,
              skill,
              noOfCandidates,
              maxSelection,
              tools,
              category,
              programCategory,
              mode,
              type,
              isRegistrable,
              isGroupwiseResult,
              isDebate,
              isStarred,
              isItemRegistrable,
              isProposalSubmission,
              section,
            },
          },
        });
        toast.success("Program Added Successfully");
      }
      navigate("/programs");
    } catch (error) {
      console.error("Error handling program:", error);
    }
  };

  useEffect(() => {
    setSection(localStorage.getItem("section") || "");
    if (initialProgramData) {
      setProgramCode(initialProgramData.programCode);
      setName(initialProgramData.name);
      setConcept(initialProgramData.concept);
      setDuration(initialProgramData.duration);
      setSkill(initialProgramData.skill.id); // Use skill ID for selection
      setNoOfCandidates(initialProgramData.noOfCandidates);
      setmaxSelection(initialProgramData.maxSelection);
      setTools(initialProgramData.tools || []);
      setCategory(initialProgramData.category.id); // Use category ID for selection
      setProgramCategory(initialProgramData.programCategory.id); // Use program category ID for selection
      setMode(initialProgramData.mode);
      setType(initialProgramData.type);
      setIsRegistrable(initialProgramData.isRegistrable);
      setIsGroupwiseResult(initialProgramData.isGroupwiseResult);
      setIsDebate(initialProgramData.isDebate);
      setisItemRegistrable(initialProgramData.isItemRegistrable);
      setisProposalSubmission(initialProgramData.isProposalSubmission);
      setIsStarred(initialProgramData.isStarred);
      setSection(initialProgramData.section.id); // Use section ID for selection
    }
  }, [initialProgramData, localStorage.getItem("section")]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-7 mt-4 gap-6">
      {/* Left Section */}
      <div className="col-span-1 md:col-span-2 space-y-6">
        <div className="p-6 card flex flex-col bg-white shadow-md rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <p className="card-title text-xl font-semibold">
              {initialProgramData ? "Edit Program" : "Add Program"}
            </p>
            <div className="inline-flex items-center justify-center rounded-lg bg-slate-100 dark:bg-slate-700 w-9 h-9">
              <i className="mgc_add_line"></i>
            </div>
          </div>
          <label htmlFor="program-code" className="mb-2 block">
            Program Code
          </label>
          <input
            required
            type="text"
            id="program-code"
            className="form-input border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Enter Program Code"
            value={programCode}
            onChange={(e) => setProgramCode(e.target.value)}
            aria-label="Program Code"
          />
          <label htmlFor="program-category-id" className="mb-2 block mt-4">
            Program Category
          </label>
          <select
            required
            id="program-category-id"
            className="form-select border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            value={programCategory}
            onChange={(e) => setProgramCategory(e.target.value)}
            aria-label="Program Category"
          >
            <option value="">Select Program Category</option>
            {programCategories?.programCategories?.map((category: any) => (
              <option value={category.id} key={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="p-6 card flex flex-col bg-white shadow-md rounded-lg">
          <label htmlFor="isGroupwiseResult" className="mb-2 block">
            Is Groupwise result?
          </label>
          <div className="flex space-x-4 mb-4">
            <div>
              <input
                type="radio"
                id="yes_isGroupwiseResult"
                name="isGroupwiseResult"
                value="yes"
                checked={isGroupwiseResult}
                onChange={() => setIsGroupwiseResult(true)}
              />
              <label htmlFor="yes_isGroupwiseResult" className="ml-2">
                Yes
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="no_isGroupwiseResult"
                name="isGroupwiseResult"
                value="no"
                checked={!isGroupwiseResult}
                onChange={() => setIsGroupwiseResult(false)}
              />
              <label htmlFor="no_isGroupwiseResult" className="ml-2">
                No
              </label>
            </div>
          </div>

          <label htmlFor="isDebate" className="mb-2 block">
            Is Debate?
          </label>
          <div className="flex space-x-4 mb-4">
            <div>
              <input
                type="radio"
                id="yes_isDebate"
                name="isDebate"
                value="yes"
                checked={isDebate}
                onChange={() => setIsDebate(true)}
              />
              <label htmlFor="yes_isDebate" className="ml-2">
                Yes
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="no_isDebate"
                name="isDebate"
                value="no"
                checked={!isDebate}
                onChange={() => setIsDebate(false)}
              />
              <label htmlFor="no_isDebate" className="ml-2">
                No
              </label>
            </div>
          </div>
          <label htmlFor="duration" className="mb-2 block">
            Duration (in minutes)
          </label>
          <input
            required
            type="number"
            id="duration"
            className="form-input border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
            value={duration ?? ""}
            onChange={(e) => setDuration(Number(e.target.value))}
            aria-label="Duration"
          />
          <div className="my-2">
            <label htmlFor="no-of-candidates" className="mb-2 block mt-4">
              No of Candidates
            </label>
            <input
              required
              type="number"
              id="no-of-candidates"
              className="form-input border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
              value={noOfCandidates ?? ""}
              onChange={(e) => setNoOfCandidates(Number(e.target.value))}
              aria-label="Number of Candidates"
            />
          </div>
          <div className="my-2">
            <label htmlFor="maxSelection" className="mb-2 block mt-4">
              Max Selection
            </label>
            <input
              required
              type="number"
              id="maxSelection"
              className="form-input border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
              value={maxSelection ?? ""}
              onChange={(e) => setmaxSelection(Number(e.target.value))}
              aria-label="Number of Candidates"
            />
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="col-span-1 md:col-span-1 lg:col-span-5 lg:col-start-3 w-full space-y-6">
        {/* General Program Data Card */}
        <div className="p-6 card flex flex-col bg-white shadow-md rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <p className="card-title">Program details</p>
          </div>

          {/* Program Name */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="name" className="mb-2 block">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                required
                type="text"
                id="name"
                className="form-input border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Enter Program Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                aria-label="Program Name"
              />
            </div>

            {/* Skill */}
            <div>
              <label htmlFor="skill" className="mb-2 block">
                Skill
              </label>
              <select
                required
                id="skill"
                className="form-select border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={skill}
                onChange={(e) => setSkill(e.target.value)}
                aria-label="Skill"
              >
                <option value="">Select Skill</option>
                {skillsData?.skills?.map((skill: any) => (
                  <option value={skill.id} key={skill.id}>
                    {skill.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div>
              <label htmlFor="section" className="mb-2 block">
                Category
              </label>
              <select
                required
                id="section"
                className="form-select border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                aria-label="Section"
              >
                <option value="">Select Category</option>
                {categories?.categories?.map((category: any) => (
                  <option value={category.id} key={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Mode */}
            <div>
              <label htmlFor="section" className="mb-2 block">
                Section
              </label>
              <select
                required
                id="section"
                className="form-select border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={section}
                onChange={(e) => setSection(e.target.value)}
                aria-label="Section"
                disabled
              >
                <option value="">Select Section</option>
                {sectionData?.sections?.map((section: any) => (
                  <option value={section.id} key={section.id}>
                    {section.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Other Fields */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
            <div>
              <label htmlFor="mode" className="mb-2 block">
                Mode
              </label>
              <div className="flex space-x-4">
                <div>
                  <input
                    type="radio"
                    id="stage"
                    name="mode"
                    value="stage"
                    checked={mode === "stage"}
                    onChange={() => setMode("stage")}
                  />
                  <label htmlFor="stage" className="ml-2">
                    Stage
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="non_stage"
                    name="mode"
                    value="non_stage"
                    checked={mode === "non_stage"}
                    onChange={() => setMode("non_stage")}
                  />
                  <label htmlFor="non_stage" className="ml-2">
                    Non-stage
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="type" className="mb-2 block">
                Type
              </label>
              <div className="flex space-x-4">
                <div>
                  <input
                    type="radio"
                    id="single"
                    name="type"
                    value="single"
                    checked={type === "single"}
                    onChange={() => setType("single")}
                  />
                  <label htmlFor="single" className="ml-2">
                    Single
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="group"
                    name="type"
                    value="group"
                    checked={type === "group"}
                    onChange={() => setType("group")}
                  />
                  <label htmlFor="group" className="ml-2">
                    Group
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="is-starred" className="mb-2 block mt-4">
                Is Starred?
              </label>
              <div className="flex space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="isStarred"
                    checked={isStarred === true}
                    onChange={() => setIsStarred(true)}
                    className="form-radio"
                    value="Yes"
                  />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="isStarred"
                    value="No"
                    checked={isStarred === false}
                    onChange={() => setIsStarred(false)}
                    className="form-radio"
                  />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
            <div>
              <label htmlFor="isRegisterable" className="mb-2 block">
                Is Registrable?
              </label>
              <div className="flex space-x-4">
                <div>
                  <input
                    type="radio"
                    id="yes_register"
                    name="registerable"
                    value="yes"
                    checked={isRegistrable}
                    onChange={() => setIsRegistrable(true)}
                  />
                  <label htmlFor="yes_register" className="ml-2">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="no_register"
                    name="registerable"
                    value="no"
                    checked={!isRegistrable}
                    onChange={() => setIsRegistrable(false)}
                  />
                  <label htmlFor="no_register" className="ml-2">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="topicRegistration" className="mb-2 block">
                is Item Registration?
              </label>
              <div className="flex space-x-4">
                <div>
                  <input
                    type="radio"
                    id="yes_topic"
                    name="topic_registration"
                    value="yes"
                    checked={isItemRegistrable}
                    onChange={() => setisItemRegistrable(true)}
                  />
                  <label htmlFor="yes_topic" className="ml-2">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="no_topic"
                    name="topic_registration"
                    value="no"
                    checked={!isItemRegistrable}
                    onChange={() => setisItemRegistrable(false)}
                  />
                  <label htmlFor="no_topic" className="ml-2">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="ProposalSubmission" className="mb-2 block">
                is Proposal Submission?
              </label>
              <div className="flex space-x-4">
                <div>
                  <input
                    type="radio"
                    id="yes_topic"
                    name="ProposalSubmission"
                    value="yes"
                    checked={isProposalSubmission}
                    onChange={() => setisProposalSubmission(true)}
                  />
                  <label htmlFor="yes_topic" className="ml-2">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="no_topic"
                    name="ProposalSubmission"
                    value="no"
                    checked={!isProposalSubmission}
                    onChange={() => setisProposalSubmission(false)}
                  />
                  <label htmlFor="no_topic" className="ml-2">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* Section */}

          {/* Type */}
          {/* Program Concept */}
          <div className="mt-4">
            <label htmlFor="concept" className="mb-2 block">
              Program Concept
            </label>
            <textarea
              id="concept"
              className="form-textarea border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter Concept"
              value={concept}
              rows={6}
              onChange={(e) => setConcept(e.target.value)}
              aria-label="Program Concept"
            ></textarea>
          </div>

          {/* Tools */}
          <div className="mt-4">
            <label htmlFor="tools" className="mb-2 block">
              Tools (comma-separated)
            </label>
            <input
              type="text"
              id="tools"
              className="form-input border rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Enter Tools"
              value={tools.join(", ")}
              onChange={handleToolsInputChange}
              aria-label="Tools"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end mt-6 space-x-4">
          <button
            type="button"
            onClick={() => navigate("/programs")}
            className="bg-red-500 text-white px-4 py-2 rounded-md"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className={`text-white px-4 py-2 rounded-md ${
              createLoading || updateLoading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-600"
            }`}
            disabled={createLoading || updateLoading}
          >
            {createLoading || updateLoading
              ? "Loading.."
              : initialProgramData
              ? "Update Program"
              : "Add Program"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgramForm;
