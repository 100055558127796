import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation Register(
    $username: String!
    $password: String!
    $role: ID!
    $section: ID
    $group: ID
    $category: ID
    $institution: ID
  ) {
    Register(
      input: {
        username: $username
        password: $password
        role: $role
        section: $section
        group: $group
        category: $category
        institution: $institution
      }
    ) {
      user {
        id
        username
      }
    }
  }
`;

// Update Role Mutation
export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $username: String
    $role: ID
    $section: ID
    $group: ID
    $category: ID
    $institution: ID
  ) {
    updateUser(
      id: $id
      input: {
        username: $username
        role: $role
        section: $section
        group: $group
        category: $category
        institution: $institution
      }
    ) {
      id
      username
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
