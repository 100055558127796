import React, { useEffect, useState } from 'react';
import { GET_PROGRAM_PARTICIPANTS } from '../../graphql/queries/participation';
import { useQuery } from '@apollo/client';
import { GET_GROUP_INSTITUTIONS } from '../../graphql/queries/institution';

interface ProgramParticipantsProps {
    id: string;
    code: string;
    name: string;
    category: string;
    institutionsGroup: string;
    onDelete: () => void;
}

const candidatesData: string[][] = [];

const ProgramParticipants: React.FC<ProgramParticipantsProps> = ({ id, code, name, category, institutionsGroup, onDelete }) => {
    const [duplicates, setDuplicates] = useState<string[]>([]);
    const [institutions, setInstitutions] = useState<string[]>([]);

    const { data: groupInstitutionsData } = useQuery(GET_GROUP_INSTITUTIONS, {
        variables: {
            groupId: institutionsGroup
        }
    });
    const { data: participants, error, loading } = useQuery(GET_PROGRAM_PARTICIPANTS, {
        variables: {
            programId: id,
        }
    });

    useEffect(() => {
        if (groupInstitutionsData) {
            const fetchedInstitutions = groupInstitutionsData?.groupWiseInstitution?.map((groupInstitution: any) => groupInstitution.id);
            setInstitutions(fetchedInstitutions);
        }
    }, [groupInstitutionsData]);

    if (loading) {
        return <p>Loading...</p>;
    }
    if (error) {
        return <p>{error.message}</p>;
    }

    const filteredParticipants = participants.programParticipants.filter((participant: any) =>
        institutions?.length > 0 ? institutions.includes(participant.candidate[0].institution.id) : true
    );

    const candidateList: string[] = [];

    participants.programParticipants.forEach((participant: any) => {
        candidateList.push(participant.candidate[0].chestNo);
    });

    const isMatch = candidatesData.some(candidate =>
        candidate.length === candidateList.length && candidate.every((value, index) => value === candidateList[index])
    );

    if (!isMatch) candidatesData.push(candidateList);

    const flatArray = candidatesData.flat();
    const countMap = new Map();

    flatArray.forEach(item => {
        countMap.set(item, (countMap.get(item) || 0) + 1);
    });

    countMap.forEach((count, item) => {
        if (count > 1) {
            !duplicates.includes(item) &&
                setDuplicates([...duplicates, item]);
        }
    });

    const deleteItem = () => {
        const candidateList: string[] = [];

        participants.programParticipants.forEach((participant: any) => {
            candidateList.push(participant.candidate[0].chestNo);
        });

        console.log(candidatesData);
        candidatesData.splice(candidatesData.indexOf(candidateList), 1);
        console.log(candidatesData);

        onDelete();
    }

    return (
        <div className="w-full h-full rounded-3xl bg-white overflow-hidden">
            <div className="w-full px-10 py-3 flex items-center justify-between bg-zinc-700 text-white">
                <div>
                    <p className="text-md font-semibold">{code} - {name}</p>
                    <p className="text-xs">{category}</p>
                </div>
                <i className="mgc_close_line cursor-pointer" onClick={deleteItem}></i>
            </div>
            <div className="w-full px-5 py-3">
                {filteredParticipants.map((participant: any, index: number) => (
                    <div key={index} className={`w-full flex items-center justify-normal border-b px-4 py-2 gap-4 rounded-2xl ${duplicates.includes(participant.candidate[0].chestNo) ? "bg-red-500 text-white" : "bg-white"}`}>
                        <img src={participant.candidate[0].photo} alt="title" className="w-12 h-12 object-cover rounded-full" />
                        <div className="">
                            <p className="text-md font-semibold">{participant.candidate[0].chestNo}- {participant.candidate[0].name}</p>
                            <p>{participant.candidate[0].institution.name}</p>
                        </div>
                    </div>
                )
                )}
            </div>
        </div>
    )
}

export default ProgramParticipants;