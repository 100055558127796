import React, { useEffect, useState } from "react";
import ChatItem from "../../components/support/ChatItem";
import MessageBubble from "../../components/support/MessageBubble";

interface ChatItemProps {
    id: string;
    name: string;
    time: string;
    isPinned?: boolean;
    isUnread?: boolean;
    typingStatus?: string;
    profileImg: string;
    messages?: MessageProps[];
}

interface MessageProps {
    time: string;
    content: string;
    isCurrentUser?: boolean;
}

const SupportPage: React.FC = () => {
    const [privateChats] = useState<ChatItemProps[]>([
        {
            id: "idforshic",
            name: "SHIC Parappur",
            time: "09:12 AM",
            profileImg: "img/logo-sm.png",
            typingStatus: "Typing...",
            messages: [
                {
                    time: "08:34 AM",
                    content: "There are many things we don't understand. Some rules are hard to understand.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "We hope you can assist us on understanding these things. We apologise  for any inconvenience caused.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "No need to mention. Just let us know what bothers you. We will try our best to assist you.",
                    isCurrentUser: true,
                },
            ]
        },
        {
            id: "idfordhdc",
            name: "DHDC Manoor",
            time: "06:25 AM",
            profileImg: "img/logo-sm.png",
            isUnread: true,
            messages: [
                {
                    time: "08:34 AM",
                    content: "A Message from DHDC Manoor.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "Another Message from DHDC Manoor.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "We are happy to hear from you.",
                    isCurrentUser: true,
                },
            ]
        },
        {
            id: "idfordhiu",
            name: "DHIU Chemmad",
            time: "03:11 AM",
            profileImg: "img/logo-sm.png",
            messages: [
                {
                    time: "08:34 AM",
                    content: "A Message from DHIU Chemmad.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "Another Message from DHIU Chemmad.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "We are happy to hear from you.",
                    isCurrentUser: true,
                },
            ]
        },
    ]);

    const [publicChats] = useState<ChatItemProps[]>([
        {
            id: "idfortruth",
            name: "Truth Finder",
            time: "09:12 AM",
            profileImg: "img/logo-sm.png",
            isUnread: true,
            messages: [
                {
                    time: "08:34 AM",
                    content: "A Message from Truth Finder.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "Another Message from Truth Finder.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "We are happy to hear from you.",
                    isCurrentUser: true,
                },
            ]
        },
        {
            id: "idforanonymous",
            name: "Anonymous User",
            time: "06:25 AM",
            profileImg: "img/logo-sm.png",
            isUnread: true,
            messages: [
                {
                    time: "08:34 AM",
                    content: "A Message from Anonymous User.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "Another Message from Anonymous User.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "We are happy to hear from you.",
                    isCurrentUser: true,
                },
            ]
        },
        {
            id: "idforgood",
            name: "Good Hoper",
            time: "03:11 AM",
            profileImg: "img/logo-sm.png",
            messages: [
                {
                    time: "08:34 AM",
                    content: "A Message from Good Hoper.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "Another Message from Good Hoper.",
                    isCurrentUser: false,
                },
                {
                    time: "08:34 AM",
                    content: "We are happy to hear from you.",
                    isCurrentUser: true,
                },
            ]
        },
    ]);

    const [isPrivateSelected, setIsPrivateSelected] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedChat, setSelectedChat] = useState("");
    const [chat, setChat] = useState<ChatItemProps>();
    const [newMessage, setNewMessage] = useState("");
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    useEffect(() => {
        privateChats.map((privateChat) => {
            privateChat.id === selectedChat && privateChat.messages && setChat(privateChat);
        });

        publicChats.map((publicChat) => {
            publicChat.id === selectedChat && publicChat.messages && setChat(publicChat);
        });
    }, [selectedChat]);

    const filteredPrivateChats = privateChats.filter((privateChat: any) => {
        const matchesSearch = privateChat.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        return (matchesSearch);
    })

    const filteredPublicChats = publicChats.filter((publicChat: any) => {
        const matchesSearch = publicChat.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
        return (matchesSearch);
    })

    const handleSendMessage = () => {
        if (newMessage.trim() === "") return;

        const now = new Date();

        let hours = now.getHours();
        const minutes = now.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;

        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        const time = `${hours}:${formattedMinutes} ${ampm}`;

        chat?.messages?.push({ time: time, content: newMessage, isCurrentUser: true });
        setNewMessage("");
    };

    const sendMessage = (e: any) => {
        if (newMessage.trim() === "") return;
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    }

    const toggleSearchBar = () => {
        isSearchOpen && setSearchTerm("");
        isSearchOpen ? setIsSearchOpen(false) : setIsSearchOpen(true);
    }

    return (
        <div className="flex h-full max-h-[650px] gap-4 overflow-hidden">
            <div className="w-1/4 bg-white rounded-xl p-4 space-y-4">
                <header className="relative flex items-center justify-between mb-4">
                    <div className=" flex items-center">
                        <div className="relative mr-2 rounded-full">
                            <img
                                src="img/logo-sm.png"
                                alt="Sibaq Logo"
                                className="w-12 h-12 rounded-full object-cover"
                            />
                            <div className="absolute bottom-1 right-1 w-3 h-3 border-2 border-white rounded-full bg-green-500"></div>
                        </div>
                        <div>
                            <h1 className="text-md font-bold text-black">SIBĀQ</h1>
                            <p className="text-xs text-gray-500">Support Team</p>
                        </div>
                    </div>
                    <div className="absolute right-0 px-3 py-2 flex items-center justify-between rounded-full bg-gray-200">
                        {isSearchOpen && <input type="search" value={searchTerm} onChange={(e) => { setSearchTerm(e.target.value) }} placeholder="Search Chats" className="h-4 border-none bg-transparent focus:outline-none focus:ring-0" />}
                        <i className={`${isSearchOpen ? "mgc_close_line" : "mgc_search_2_line"} text-xl text-gray-300 cursor-pointer`} onClick={toggleSearchBar}></i>
                    </div>
                </header>
                <div className="w-full grid place-items-center">
                    <div className="w-fit rounded-full px-2 py-1.5 flex items-center justify-evenly bg-gray-100">
                        <p className={`py-1 px-4 rounded-full cursor-pointer ${isPrivateSelected && "bg-white"}`}
                            onClick={() => setIsPrivateSelected(true)}
                        >Private</p>
                        <p className={`py-1 px-4 rounded-full cursor-pointer ${!isPrivateSelected && "bg-white"}`}
                            onClick={() => setIsPrivateSelected(false)}
                        >Public</p>
                    </div>
                </div>
                <div>
                    <h2 className="max-h-full text-gray-600 font-semibold mt-4">Messages</h2>
                    {isPrivateSelected ? filteredPrivateChats.map((chat) => (
                        <ChatItem key={chat.id} {...chat} handleClick={() => setSelectedChat(chat.id)} />
                    )) :
                        filteredPublicChats.map((chat) => (
                            <ChatItem key={chat.id} {...chat} handleClick={() => setSelectedChat(chat.id)} />
                        ))}
                </div>
            </div>

            {chat ? (
                <div className="w-3/4 flex flex-col rounded-xl overflow-hidden">
                    <header className="flex items-center justify-between p-4 bg-white border-b">
                        <div className="flex items-center space-x-2">
                            {chat?.profileImg && <img src={chat?.profileImg} alt="Chat Icon" className="w-12 h-12 rounded-full object-cover" />}
                            <div>
                                <h3 className="font-semibold text-black">{chat?.name}</h3>
                                <span className="text-xs text-green-500">
                                    {chat?.typingStatus ? chat?.typingStatus : ""}
                                </span>
                            </div>
                        </div>
                        <div className="flex space-x-3 text-gray-500">
                            <i className=""></i>
                            <i className="mgc_more_2_fill"></i>
                        </div>
                    </header>
                    <div style={{ backgroundImage: "linear-gradient( rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) ), url(img/chat-bg.jpg)" }} className="max-h-full flex-1 p-4 space-y-4 overflow-y-scroll bg-gray-50 bg-opacity-5">
                        {chat?.messages?.map((msg, index) => (
                            <MessageBubble key={index} {...msg} />
                        ))}
                    </div>
                    <div className="bg-white border-t p-4 flex items-center">
                        <input
                            type="text"
                            placeholder="Type a message..."
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyDown={sendMessage}
                            className="flex-1 border-none rounded-lg px-4 py-2 focus:outline-none focus:ring-0"
                        />
                        <button
                            onClick={handleSendMessage}
                            className={`text-xl text-blue-400 focus:outline-none ${newMessage.length > 0 ? "block" : "hidden"}`}
                        >
                            <i className="mgc_send_line"></i>
                        </button>
                    </div>
                </div>
            ) :
                (
                    <div className="w-3/4 flex flex-col rounded-xl overflow-hidden bg-white">

                    </div>
                )}
        </div>
    );
};

export default SupportPage;
