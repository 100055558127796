import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import PageTitle from "../../components/PageTitle";
import DownloadItem from "../../components/downloads/DownloadItem";
import {
  CREATE_DOWNLOAD,
  DELETE_DOWNLOAD,
  UPDATE_DOWNLOAD,
} from "../../graphql/mutations/downloads";
import { GET_DOWNLOADS } from "../../graphql/queries/downloads";

const CLOUD_NAME = "dyxfbp3tf";
const UPLOAD_PRESET = "akjg9xbx";

const Downloads: React.FC = () => {
  const [title, setTitle] = useState("");
  const [_, setStatus] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState("All Downloads");
  const [loading, setLoading] = useState(false);

  const {
    data,
    loading: queryLoading,
    error: queryError,
    refetch,
  } = useQuery(GET_DOWNLOADS);
  const [createDownload, { loading: mutationLoading, error: mutationError }] =
    useMutation(CREATE_DOWNLOAD);
  const [updateDownload] = useMutation(UPDATE_DOWNLOAD);
  const [deleteDownload] = useMutation(DELETE_DOWNLOAD);

  const downloads = data?.downloads || [];

  const uploadFile = async (file: File): Promise<string> => {
    const uploadFormData = new FormData();
    uploadFormData.append("file", file);
    uploadFormData.append("upload_preset", UPLOAD_PRESET);

    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/downloads/upload`,
      {
        method: "POST",
        body: uploadFormData,
      }
    );
    const data = await response.json();
    return data.secure_url;
  };

  const filteredDownloads = downloads.filter((download: any) => {
    if (activeTab === "All Downloads") return true;
    if (activeTab === "Published") return download.status === "Published";
    if (activeTab === "Draft") return download.status === "Draft";
    return false;
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!file && !editingId) {
      return;
    }

    setLoading(true);

    try {
      let uploadedFileUrl = file ? await uploadFile(file) : null;

      if (editingId) {
        await updateDownload({
          variables: {
            id: editingId,
            input: {
              title,
              file:
                uploadedFileUrl ||
                downloads.find((d: any) => d.id === editingId)?.file,
              // status,
            },
          },
        });
      } else {
        await createDownload({
          variables: {
            input: {
              title,
              file:
                uploadedFileUrl ||
                downloads.find((d: any) => d.id === editingId)?.file,
              // status,
            },
          },
        });
      }

      refetch();
      setTitle("");
      setFile(null);
      setEditingId(null);
    } catch (error) {
      console.error("Error in form submission:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (download: any) => {
    setTitle(download.title);
    setFile(null);
    setEditingId(download.id);
  };

  const handleDelete = async (downloadId: string) => {
    try {
      await deleteDownload({ variables: { id: downloadId } });
      refetch();
    } catch (error) {
      console.error("Error deleting download:", error);
    }
  };

  if (queryLoading) return <p>Loading downloads...</p>;
  if (queryError) return <p>Error loading downloads: {queryError.message}</p>;

  return (
    <>
      <PageTitle pagetitle="Downloads" subtitle="Downloads" />
      <div className="grid place-items-center lg:grid-cols-5 gap-6 lg:gap-11 p-4 lg:p-6 w-full max-w-7xl mx-auto">
        <div className="h-full bg-white p-6 lg:p-8 rounded-lg shadow-lg lg:col-span-3 w-full">
          <h2 className="text-2xl font-bold text-center mb-6">Add Downloads</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Title
              </label>
              <input
                type="text"
                placeholder="Enter Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                File
              </label>
              <input
                type="file"
                onChange={(e) => setFile(e.target.files?.[0] || null)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
              />
            </div>

            {loading && <p>Uploading file, please wait...</p>}

            <div className="flex mt-16 px-36 justify-around">
              <button
                type="submit"
                onClick={() => setStatus("Draft")}
                className="w-36 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                disabled={loading || mutationLoading}
              >
                Draft
              </button>
              <button
                type="submit"
                onClick={() => setStatus("Published")}
                className="w-36 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                disabled={loading || mutationLoading}
              >
                Publish
              </button>
            </div>
          </form>

          {mutationError && (
            <p>Error submitting download: {mutationError.message}</p>
          )}
        </div>

        <div className="w-full lg:col-span-2 h-full bg-white shadow-md rounded-lg p-4 lg:p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-xl font-bold">Downloads</h2>
            <input
              type="text"
              placeholder="Search Downloads"
              className="border-none text-right rounded-lg py-2 px-3 text-sm outline-none focus:outline-none"
            />
          </div>

          <div className="w-full flex justify-between items-center mb-4 border-b border-gray-200">
            <div className="flex items-center border-b border-gray-200">
              {["All Downloads", "Published", "Draft"].map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 text-sm font-semibold focus:outline-none ${
                    activeTab === tab
                      ? "border-b-2 border-blue-500 text-black"
                      : "text-gray-500"
                  }`}
                >
                  {tab}
                </button>
              ))}
            </div>
          </div>

          <div className="max-h-60 overflow-y-scroll">
            {filteredDownloads.map((download: any) => (
              <DownloadItem
                key={download.id}
                title={download.title}
                status="Draft"
                // status={download.status}
                file={download.file}
                onEdit={() => handleEdit(download)}
                onDelete={() => handleDelete(download.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Downloads;
