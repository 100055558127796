import { gql } from "@apollo/client";

export const UPDATE_MARK = gql`
  mutation UpdateMark($id: ID!, $mark: Int!) {
    updateMark(id: $id, input: { mark: $mark }) {
      name
      category
    }
  }
`;
