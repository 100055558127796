import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { CREATE_ROLE, UPDATE_ROLE } from "../../graphql/mutations/role";
import { GET_PERMISSIONS } from "../../graphql/queries/permmission";
import toast from "react-hot-toast";
import Skeleton from "../UIElements/Skeleton";

interface AddRoleProps {
  editingRole?: {
    id: string;
    name: string;
    institution: boolean;
    category: boolean;
    section: boolean;
    group: boolean;
    permissions: string[];
  };
  onRoleSaved: () => void;
}

const AddRole: React.FC<AddRoleProps> = ({ editingRole, onRoleSaved }) => {
  const [roleName, setRoleName] = useState<string>("");
  const [institution, setInstitution] = useState<boolean>(false);
  const [category, setCategory] = useState<boolean>(false);
  const [section, setSection] = useState<boolean>(false);
  const [group, setGroup] = useState<boolean>(false);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const { loading, error, data } = useQuery(GET_PERMISSIONS);
  const [createRole, { loading: createLoading }] = useMutation(CREATE_ROLE, {
    onCompleted: () => onRoleSaved(),
  });
  const [updateRole, { loading: updateLoading }] = useMutation(UPDATE_ROLE, {
    onCompleted: () => onRoleSaved(),
  });

  useEffect(() => {
    if (editingRole) {
      setRoleName(editingRole.name);
      setInstitution(editingRole.institution);
      setSection(editingRole.section);
      setCategory(editingRole.category);
      setGroup(editingRole.group);
      setSelectedPermissions(
        editingRole.permissions.map((permission: any) => permission.id)
      );
    }
  }, [editingRole]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const variables = {
      name: roleName,
      permissions: selectedPermissions,
      institution,
      section,
      category,
      group,
    };

    const clearInput = () => {
      setRoleName("");
      setSelectedPermissions([]);
      setInstitution(false);
      setCategory(false);
      setSection(false);
      setGroup(false);
    };

    if (editingRole) {
      try {
        updateRole({ variables: { id: editingRole.id, ...variables } })
          .then(() => toast.success("Role updated!"))
          .catch(() => toast.error("Failed to update Role."));
        clearInput();
      } catch (error) {
        console.error("Failed to update Role:", error);
        toast.error("Failed to update Role.");
      }
    } else {
      try {
        createRole({ variables })
          .then(() => toast.success("Role created!"))
          .catch(() => toast.error("Failed to create Role."));
        clearInput();
      } catch (error) {
        console.error("Failed to create Role:", error);
        toast.error("Failed to create Role.");
      }
    }
  };

  const handlePermissionChange = (permissionId: string) => {
    setSelectedPermissions((prevPermissions) =>
      prevPermissions.includes(permissionId)
        ? prevPermissions.filter((id) => id !== permissionId)
        : [...prevPermissions, permissionId]
    );
  };

  if (loading) return <Skeleton />;
  if (error) return <p>Error loading permissions.</p>;

  return (
    <div className="card">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <h4 className="card-title">
            {editingRole ? "Edit Role" : "Add Role"}
          </h4>
        </div>
      </div>
      <div className="p-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label
              htmlFor="exampleInputRoleName"
              className="text-gray-800 text-sm font-medium inline-block mb-2"
            >
              Role Name
            </label>
            <input
              type="text"
              className="form-input"
              id="exampleInputRoleName"
              placeholder="Enter Role Name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
              required
            />
          </div>
          <p className="text-primary text-sm font-medium inline-block mb-4">
            Select Permissions
          </p>
          <div className="grid grid-cols-3 gap-3">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="form-checkbox rounded border border-gray-200"
                id={`institution`}
                checked={institution}
                onChange={() => setInstitution((prev) => !prev)}
              />
              <label
                className="text-gray-800 text-sm font-medium inline-block"
                htmlFor={`institution`}
              >
                Institution
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="form-checkbox rounded border border-gray-200"
                id={`category`}
                checked={category}
                onChange={() => setCategory((prev) => !prev)}
              />
              <label
                className="text-gray-800 text-sm font-medium inline-block"
                htmlFor={`category`}
              >
                Category
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="form-checkbox rounded border border-gray-200"
                id={`section`}
                checked={section}
                onChange={() => setSection((prev) => !prev)}
              />
              <label
                className="text-gray-800 text-sm font-medium inline-block"
                htmlFor={`section`}
              >
                Section
              </label>
            </div>

            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="form-checkbox rounded border border-gray-200"
                id={`group`}
                checked={group}
                onChange={() => setGroup((prev) => !prev)}
              />
              <label
                className="text-gray-800 text-sm font-medium inline-block"
                htmlFor={`group`}
              >
                Group
              </label>
            </div>

            {data.permissions.map(
              (permission: { id: string; name: string }) => (
                <div
                  className="flex items-center gap-2"
                  key={permission.id}
                >
                  <input
                    type="checkbox"
                    className="form-checkbox rounded border border-gray-200"
                    id={`permission-${permission.id}`}
                    checked={selectedPermissions.includes(permission.id)}
                    onChange={() => handlePermissionChange(permission.id)}
                  />
                  <label
                    className="text-gray-800 text-sm font-medium inline-block"
                    htmlFor={`permission-${permission.id}`}
                  >
                    {permission.name}
                  </label>
                </div>
              )
            )}
          </div>

          <button
            type="submit"
            className="btn bg-primary text-white mb-6 float-right"
            disabled={editingRole ? updateLoading : createLoading}
          >
            {editingRole
              ? updateLoading
                ? "Updating..."
                : "Update"
              : createLoading
              ? "Adding..."
              : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddRole;
