import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/PageTitle';
import ProgramCard from '../../components/gallery/ProgramCard';
import UploadImage from '../../components/gallery/UploadImage';
import { useMutation, useQuery } from '@apollo/client';
import { GET_GALLERY } from '../../graphql/queries/gallery';
import { CREATE_GALLERY, UPDATE_GALLERY } from '../../graphql/mutations/gallery';
import toast from 'react-hot-toast';
import Modal from '../../components/UIElements/Model';

// Define interface for the Gallery type
interface Gallery {
  id: string;
  title: string;
  description: string;
  date: string;
  photo: string;
  time: string;
  status: string;
}

const Gallery: React.FC = () => {
  const { data, loading, error, refetch } = useQuery(GET_GALLERY);
  const [createGallery] = useMutation(CREATE_GALLERY);
  const [updateGallery] = useMutation(UPDATE_GALLERY);

  // State variables
  const [selectedGallery, setSelectedGallery] = useState<Gallery | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    photo: '',
    time: '',
    status: '',
  });
  const [loadingFile, setLoadingFile] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [galleryList, setGalleryList] = useState<Gallery[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  // Toggle dropdown for a specific gallery item
  const toggleDropdown = (id: string) => {
    setOpenDropdownId(prev => (prev === id ? null : id)); // If the same id is clicked, close it, otherwise open
  };

  // Load gallery data into state when fetched
  useEffect(() => {
    if (data?.gallery) {
      setGalleryList(data.gallery);
    }
  }, [data]);

  // Populate form when editing a gallery item
  useEffect(() => {
    if (selectedGallery) {
      setFormData({
        title: selectedGallery.title,
        description: selectedGallery.description,
        date: selectedGallery.date ? new Date(Number(selectedGallery.date)).toISOString().split('T')[0] : '',
        photo: selectedGallery.photo,
        time: selectedGallery.time,
        status: selectedGallery.status,
      });
      setPreviewUrl(selectedGallery.photo);
    }
  }, [selectedGallery]);

  // Filter gallery based on search query
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredGallery = galleryList.filter((gallery) =>
    gallery.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Clear form data
  const clearForm = () => {
    setFormData({
      title: '',
      description: '',
      date: '',
      photo: '',
      time: '',
      status: '',
    });
    setPreviewUrl(null);
    setIsModalOpen(false);
    setSelectedGallery(null);
  };

  const handleCreate = () => {
    setIsEditing(true);
    clearForm();
    setIsModalOpen(true);
  }

  // Handle editing gallery
  const handleEdit = (image: Gallery) => {
    setSelectedGallery(image);
    console.log(image);
    setIsEditing(true);
    toggleDropdown(image.id)
    setIsModalOpen(true);
  };

  // Handle viewing gallery details
  const handleView = (image: Gallery) => {
    setSelectedGallery(image);
    setIsEditing(false);
    toggleDropdown(image.id)
    setIsModalOpen(true);
  };

  // Upload file to Cloudinary
  const uploadFile = async (file: string): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', import.meta.env.VITE_UPLOAD_PRESET);

    try {
      const response = await fetch(`https://api.cloudinary.com/v1_1/${import.meta.env.VITE_CLOUD_NAME}/image/upload`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error('File upload error:', error);
      throw new Error('File upload failed');
    }
  };

  // Handle submission (create gallery as draft or published)
  const handleSubmit = async (e: React.FormEvent, status: string) => {
    e.preventDefault();
    try {
      setLoadingFile(true);
      const fileUrl = formData.photo ? await uploadFile(formData.photo) : previewUrl;

      const inputData = {
        ...formData,
        photo: fileUrl,
        status,
      };

      if (selectedGallery) {
        await updateGallery({ variables: { id: selectedGallery.id, input: inputData } });
        toast.success('Gallery updated successfully');
      } else {
        await createGallery({ variables: { input: inputData } });
        toast.success('Gallery created successfully');
      }

      clearForm();
      refetch();
    } catch (error) {
      console.error('Error during gallery submission:', error);
      toast.error('Error saving gallery');
    } finally {
      setLoadingFile(false);
      setIsModalOpen(false)
    }
  };

  const handleFileChange = (photo: any | null) => {
    setFormData({ ...formData, photo });
  };

  if (loading || loadingFile) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <PageTitle pagetitle="Gallery" subtitle="Media" />
      <div className="w-full">
        <div className="flex justify-end items-center mb-4">
          <input
            type="text"
            placeholder="Search registered gallery..."
            className="form-input w-1/2 rounded-lg"
            value={searchQuery}
            onChange={handleSearch}
          />
          <button
            onClick={() => {
              handleCreate()
            }}
            className="bg-green-500 h-full block py-2 px-3 ml-4 text-white rounded-xl"
          >
            + Add Image
          </button>
        </div>

        <div className="grid grid-cols-4 gap-4">
          {filteredGallery.map((image) => (
            <ProgramCard
              key={image.id}
              image={image}
              onEdit={() => handleEdit(image)}
              onView={() => handleView(image)}
              refetch={refetch}
              isDropdownOpen={openDropdownId === image.id} // Open dropdown only for the specific card
              toggleDropdown={toggleDropdown}
            // formateDate= {() => formatDate(formData.date)}
            />
          ))}
        </div>
      </div>

      {isModalOpen && (
        <Modal
          title={isEditing ? 'Edit Gallery' : selectedGallery ? 'View Gallery' : 'Add Gallery'}
          isOpen={isModalOpen}
          setIsOpen={clearForm}
          handleSubmit={() => handleEdit}
        >
          <div className="flex flex-col justify-center items-center px-2 text-gray-700">
            <div className="w-full px-2">
              <label className="text-gray-600 mt-1 inline-block">Title:</label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-1 inline-block"
                placeholder="Enter title"
                disabled={!isEditing}
              />
            </div>
            <div className="w-full px-2">
              <label className="text-gray-600 mt-1 inline-block">Description:</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-1"
                placeholder="Enter description"
                disabled={!isEditing}
              />
            </div>
            <div className="w-full px-2 flex justify-between items-center">
              <div className="w-6/12">
                <label className="text-gray-600 mt-1 inline-block">Date:</label>
                <input
                  type="date"
                  value={formData.date}
                  // value={formatForInput(formData.date)} 
                  onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  className="text-sm m-1 ml-0 inline-block rounded-lg w-full border-2 border-gray-300 p-1"
                  disabled={!isEditing}
                />
              </div>
              <div className="w-5/12">
                <label className="text-gray-600 mt-1 inline-block">Time:</label>
                <input
                  type="time"
                  value={formData.time}
                  onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                  className="text-sm m-1 ml-0 inline-block rounded-lg w-full border-2 border-gray-300 p-1"
                  disabled={!isEditing}
                />
              </div>
            </div>
            <UploadImage
              onFileChange={handleFileChange}
              isEditing={isEditing}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
            />
          </div>

          {isEditing && (
            <div className="flex flex-row items-center justify-end p-2 gap-4">
              <button
                onClick={(e) => handleSubmit(e, 'draft')}
                className="bg-blue-400 text-white py-2 px-4 rounded-lg"
              >
                Save as Draft
              </button>
              <button
                onClick={(e) => handleSubmit(e, 'published')}
                className="bg-green-500 text-white py-2 px-4 rounded-lg"
              >
                Publish
              </button>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default Gallery;
