import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/PageTitle';
import ProgramCard from '../../components/news/ProgramCard';
import UploadImage from '../../components/news/UploadImage';
import { useMutation, useQuery } from '@apollo/client';
import { GET_NEWS } from '../../graphql/queries/news';
import { CREATE_NEWS, UPDATE_NEWS } from '../../graphql/mutations/news';
import toast from 'react-hot-toast';
import Modal from '../../components/UIElements/Model';

// Define interface for the News type
interface News {
  id: string;
  title: string;
  description: string;
  date: string;
  photo: string;
  time: string;
  status: string;
}

const News: React.FC = () => {
  const { data, loading, error, refetch } = useQuery(GET_NEWS);
  const [createNews] = useMutation(CREATE_NEWS);
  const [updateNews] = useMutation(UPDATE_NEWS);

  // State variables
  const [selectedNews, setSelectedNews] = useState<News | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    photo: '',
    time: '',
    status: '',
  });
  const [loadingFile, setLoadingFile] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [newsList, setNewsList] = useState<News[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  // Toggle dropdown for a specific news item
  const toggleDropdown = (id: string) => {
    setOpenDropdownId(prev => (prev === id ? null : id)); // If the same id is clicked, close it, otherwise open
  };

  // Load news data into state when fetched
  useEffect(() => {
    if (data?.news) {
      setNewsList(data.news);
    }
  }, [data]);

  // Populate form when editing a news item
  useEffect(() => {
    if (selectedNews) {
      setFormData({
        title: selectedNews.title,
        description: selectedNews.description,
        date: selectedNews.date ? new Date(Number(selectedNews.date)).toISOString().split('T')[0] : '',
        photo: selectedNews.photo,
        time: selectedNews.time,
        status: selectedNews.status,
      });
      
      setPreviewUrl(selectedNews.photo);
    }
  }, [selectedNews]);

  // Filter news based on search query
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredNews = newsList.filter((newsItem) =>
    newsItem.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Clear form data
  const clearForm = () => {
    setFormData({
      title: '',
      description: '',
      date: '',
      photo: '',
      time: '',
      status: '',
    });
    setPreviewUrl(null);
    setIsModalOpen(false);
    setSelectedNews(null);
  };

  const handleCreate = () => {
    setIsEditing(true);
    clearForm();
    setIsModalOpen(true);
  }

  // Handle editing news
  const handleEdit = (newsItem: News) => {
    setSelectedNews(newsItem);
    setIsEditing(true);
    toggleDropdown(newsItem.id)
    setIsModalOpen(true);
  };

  // Handle viewing news details
  const handleView = (newsItem: News) => {
    setSelectedNews(newsItem);
    setIsEditing(false);
    toggleDropdown(newsItem.id)
    setIsModalOpen(true);
  };

  // Upload file to Cloudinary
  const uploadFile = async (file: string): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', import.meta.env.VITE_UPLOAD_PRESET);

    try {
      const response = await fetch(`https://api.cloudinary.com/v1_1/${import.meta.env.VITE_CLOUD_NAME}/image/upload`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error('File upload error:', error);
      throw new Error('File upload failed');
    }
  };

  // Handle submission (create news as draft or published)
  const handleSubmit = async (e: React.FormEvent, status: string) => {
    e.preventDefault();
    try {
      setLoadingFile(true);
      const fileUrl = formData.photo ? await uploadFile(formData.photo) : previewUrl;

      const inputData = {
        ...formData,
        photo: fileUrl,
        status,
      };

      if (selectedNews) {
        await updateNews({ variables: { id: selectedNews.id, input: inputData } });
        toast.success('News updated successfully');
      } else {
        await createNews({ variables: { input: inputData } });
        toast.success('News created successfully');
      }

      clearForm();
      refetch();
    } catch (error) {
      console.error('Error during news submission:', error);
      toast.error('Error saving news');
    } finally {
      setLoadingFile(false);
      setIsModalOpen(false)
    }
  };

  const handleFileChange = (photo: any | null) => {
    setFormData({ ...formData, photo });
  };

  if (loading || loadingFile) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <PageTitle pagetitle="News" subtitle="Media" />
      <div className="w-full">
        <div className="flex justify-end items-center mb-4">
          <input
            type="text"
            placeholder="Search registered news..."
            className="form-input w-1/2 rounded-lg"
            value={searchQuery}
            onChange={handleSearch}
          />
          <button
            onClick={() => {
              handleCreate()
            }}
            className="bg-green-500 h-full block py-2 px-3 ml-4 text-white rounded-xl"
          >
            + Add News
          </button>
        </div>

        <div className="grid grid-cols-4 gap-4">
          {filteredNews.map((newsItem) => (
            <ProgramCard
              key={newsItem.id}
              news={newsItem}
              onEdit={() => handleEdit(newsItem)}
              onView={() => handleView(newsItem)}
              refetch={refetch}
              isDropdownOpen={openDropdownId === newsItem.id} // Open dropdown only for the specific card
              toggleDropdown={toggleDropdown}
            // formateDate= {() => formatDate(formData.date)}
            />
          ))}
        </div>
      </div>

      {isModalOpen && (
        <Modal
          title={isEditing ? 'Edit News' : selectedNews ? 'View News' : 'Add News'}
          isOpen={isModalOpen}
          setIsOpen={clearForm}
          handleSubmit={() => handleEdit}
        >
          <div className="flex flex-col justify-center items-center px-2 text-gray-700">
            <div className="w-full px-2">
              <label className="text-gray-600 mt-1 inline-block">Title:</label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-1 inline-block"
                placeholder="Enter title"
                disabled={!isEditing}
              />
            </div>
            <div className="w-full px-2">
              <label className="text-gray-600 mt-1 inline-block">Description:</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                className="text-sm m-1 ml-0 rounded-lg min-w-56 w-full border-2 border-gray-300 p-1"
                placeholder="Enter description"
                disabled={!isEditing}
              />
            </div>
            <div className="w-full px-2 flex justify-between items-center">
              <div className="w-6/12">
                <label className="text-gray-600 mt-1 inline-block">Date:</label>
                <input
                  type="date"
                  value={formData.date}
                  // value={formatForInput(formData.date)} 
                  onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  className="text-sm m-1 ml-0 inline-block rounded-lg w-full border-2 border-gray-300 p-1"
                  disabled={!isEditing}
                />
              </div>
              <div className="w-5/12">
                <label className="text-gray-600 mt-1 inline-block">Time:</label>
                <input
                  type="time"
                  value={formData.time}
                  onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                  className="text-sm m-1 ml-0 inline-block rounded-lg w-full border-2 border-gray-300 p-1"
                  disabled={!isEditing}
                />
              </div>
            </div>
            <UploadImage
              onFileChange={handleFileChange}
              isEditing={isEditing}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
            />
          </div>

          {isEditing && (
            <div className="flex flex-row items-center justify-end p-2 gap-4">
              <button
                onClick={(e) => handleSubmit(e, 'draft')}
                className="bg-blue-400 text-white py-2 px-4 rounded-lg"
              >
                Save as Draft
              </button>
              <button
                onClick={(e) => handleSubmit(e, 'published')}
                className="bg-green-500 text-white py-2 px-4 rounded-lg"
              >
                Publish
              </button>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default News;
