import * as React from 'react';
import { useState, useEffect } from 'react';
import { GET_PROGRAMS } from '../../graphql/queries/program';
import { GET_CATEGORIES } from '../../graphql/queries/category';
import { GET_PROGRAM_CATEGORIES } from '../../graphql/queries/programCategory';
// import { GET_SKILLS } from '../../graphql/queries/skill';
import { useLazyQuery, useQuery } from '@apollo/client';
import PageTitle from '../../components/PageTitle'
import ProgramParticipants from '../../components/schedule/ProgramParticipants';
import { GET_GROUPS } from '../../graphql/queries/group';
import { GET_GROUP_INSTITUTIONS } from '../../graphql/queries/institution';

interface selectedData {
    id: string;
    code: string;
    name: string;
    category: string;
}

const CompareSchedules: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedProgramCategory, setSelectedProgramCategory] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [institutions, setInstitutions] =  useState<string[]>([]);
    // const [selectedSkill, setSelectedSkill] = useState("");
    const [selectedDatas, setSelectedDatas] = useState<selectedData[]>([]);

    const { data: programsData, error, loading } = useQuery(GET_PROGRAMS);
    const { data: categoryData } = useQuery(GET_CATEGORIES);
    const { data: programCategoryData } = useQuery(GET_PROGRAM_CATEGORIES);
    const { data: groupData } = useQuery(GET_GROUPS);
    const [getGroupInstitutions, { data: groupInstitutionsData }] = useLazyQuery(GET_GROUP_INSTITUTIONS);
    // const { data: skillData } = useQuery(GET_SKILLS);

    useEffect(() => {
        getGroupInstitutions({ variables: { groupId: selectedGroup } });
    }, [selectedGroup]);

    useEffect(() => {
        setInstitutions([]);
        groupInstitutionsData && groupInstitutionsData?.groupWiseInstitution?.map((groupInstitution: any) => {
            institutions.push(groupInstitution.id);
        })
    }, [groupInstitutionsData]);

    if (loading) {
        return <p>Loading...</p>;
    }
    if (error) {
        return <p>{error.message}</p>;
    }

    const filteredPrograms = programsData.programs.filter(
        (program: {
            name: string;
            category: {
                id: string;
                name: string;
            };
            programCategory: {
                id: string;
                name: string;
            };
            // skill: {
            //     id: string;
            //     name: string;
            // };
        }) => {
            const matchesSearch = program.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            const matchesCategory = selectedCategory
                ? program?.category?.id === selectedCategory
                : true;
            const matchesProgramCategory = selectedProgramCategory
                ? program?.programCategory?.id === selectedProgramCategory
                : true;
            // const matchesSkill = selectedSkill
            //     ? program?.skill?.id === selectedSkill
            //     : true;

            return (
                matchesSearch &&
                matchesCategory &&
                matchesProgramCategory
                // matchesSkill
            );
        }
    );

    const selectProgram = (id: string, code: string, name: string, category: string) => {
        setSelectedDatas((prevSelectedDatas) => [
            ...prevSelectedDatas,
            { id, code, name, category }
        ]);

        setSearchTerm("");
    }

    const deleteFromSelectedData = (index: number) => {
        const tempDatas = [...selectedDatas];
        tempDatas.splice(index, 1);
        setSelectedDatas(tempDatas);
    }

    return (
        <>
            <PageTitle pagetitle="Compare Schedules" subtitle="Shedule" />
            <div className="flex flex-col sm:flex-row gap-2 mb-7">
                <div className="relative w-1/3">
                    <div className="w-full flex relative">
                        <div className="pointer-events-none absolute top-3.5 left-4 text-gray-900 text-opacity-40 dark:text-gray-200">
                            <i className="mgc_search_line text-xl"></i>
                        </div>
                        <input
                            type="search"
                            className="h-12 w-full border rounded-lg bg-transparent pl-11 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                            placeholder="Search Programs..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    {searchTerm && (
                        <div className="absolute w-full h-56 rounded-xl overflow-hidden bg-white shadow-xl overflow-y-scroll">
                            {filteredPrograms.map((filteredProgram: any, index: number) => (
                                <div
                                    key={index}
                                    className="px-3 py-1 border-b cursor-pointer hover:bg-gray-200"
                                    onClick={() => selectProgram(filteredProgram.id, filteredProgram.programCode, filteredProgram.name, filteredProgram.category.name)}
                                >
                                    <p className="text-md font-semibold">{filteredProgram.programCode} - {filteredProgram.name}</p>
                                    <p className="text-xs">{filteredProgram.category.name}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="w-1/4 hidden sm:block">
                    <select
                        required
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                    >
                        <option value="">Select Category</option>
                        {categoryData?.categories?.map((category: any) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="w-1/4 hidden sm:block">
                    <select
                        required
                        value={selectedProgramCategory}
                        onChange={(e) => setSelectedProgramCategory(e.target.value)}
                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                    >
                        <option value="">Select Program Category</option>
                        {programCategoryData?.programCategories?.map((category: any) => (

                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="w-1/4 hidden sm:block">
                    <select
                        required
                        value={selectedGroup}
                        onChange={(e) => setSelectedGroup(e.target.value)}
                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                    >
                        <option value="">Select Group</option>
                        {groupData?.groups?.map((group: any) => (
                            <option key={group.id} value={group.id}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                </div>

                {/* <div className="w-1/4 hidden sm:block">
                    <select
                        required
                        value={selectedSkill}
                        onChange={(e) => setSelectedSkill(e.target.value)}
                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm">
                        <option value="">Select Skill</option>
                        {skillData?.skills?.map((skill: any) => (
                            <option key={skill.id} value={skill.id}>
                                {skill.name}
                            </option>
                        ))}
                    </select>
                </div> */}
            </div>
            <div className="w-full grid grid-cols-3 gap-6 px-3 py-8">
                {selectedDatas.map(({ id, code, name, category }, index) => (
                    <ProgramParticipants key={index} id={id} code={code} name={name} category={category} institutionsGroup={selectedGroup} onDelete={() => { deleteFromSelectedData(index) }} />
                ))}
            </div>
        </>
    )
}

export default CompareSchedules;