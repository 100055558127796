import React, { useState, useRef, useEffect } from "react";

interface DownloadItemProps {
  title: string;
  file: string;
  status: string;
  onEdit: () => void;
  onDelete: () => void;
}

const DownloadItem: React.FC<DownloadItemProps> = ({
  title,
  file,
  status,
  onEdit,
  onDelete,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDownload = () => {
    window.location.href = file;
  };

  return (
    <div className="flex items-start p-4 border-b border-gray-200 w-full cursor-pointer hover:bg-gray-200">
      <div className="relative">
        <div className="w-10 h-10 rounded-full object-cover bg-primary/25 grid place-items-center" onClick={handleDownload}>
          <i className="mgc_download_line text-3xl text-primary"></i>
        </div>
        <span className="absolute top-0 left-0 bg-blue-500 w-3 h-3 rounded-full"></span>
      </div>

      <div className="w-full">
        <div className="ml-4 flex justify-between items-center">
          <h4 className="font-bold text-sm">{title}</h4>

          <div className="relative">
            <i
              className="mgc_more_2_fill cursor-pointer"
              onClick={() => setShowPopup(!showPopup)}
            ></i>

            {showPopup && (
              <div
                ref={popupRef}
                className="absolute right-0 mt-2 w-32 bg-white shadow-lg rounded-lg py-2 z-50"
              >
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={handleDownload}
                >
                  Download
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    onEdit();
                    setShowPopup(false);
                  }}
                >
                  Edit
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100"
                  onClick={() => {
                    onDelete();
                    setShowPopup(false);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="ml-4 flex justify-between">
          <div
            className={`text-[10px] ${
              status === "Published"
                ? "text-white bg-green-500"
                : "text-white bg-orange-500"
            } px-2 py-1 rounded-xl`}
          >
            {status}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadItem;
