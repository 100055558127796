import { gql } from "@apollo/client";
export const GET_ITEM_REG_PROGRAMS = gql`
  query Programs {
    programs {
      isItemRegistrable
      name
      id
      category {
        id
        name
      }
    }
  }
`;
export const GET_PROGRAMS = gql`
  query GetPrograms {
    programs {
      category {
        id
        name
      }
      concept
      duration
      eliminationResultStatus
      eliminationVenue {
        id
        name
      }
      maxSelection
      noOfCandidates
      tools
      eliminiationDate
      eliminiationEndingTime
      eliminiationStartingTime
      finalDate
      finalEndingTime
      finalResultStatus
      finalStartingTime
      finalVenue {
        id
        name
      }
      id
      isItemRegistrable
      isProposalSubmission
      isRegistrable
      isGroupwiseResult
      isDebate
      isStarred
      maxSelection
      mode
      name
      noOfCandidates
      programCategory {
        id
        name
      }
      programCode
      publishTimeElimination
      publishTimeFinal
      section {
        id
        name
      }
      status
      tools
      type
      skill {
        id
        name
      }
    }
  }
`;
export const GET_PROGRAM_BY_ID = gql`
  query GetProgramById($id: ID!) {
    program(id: $id) {
      id
      programCode
      name
      concept
      duration
      skill {
        id
        name
      }
      noOfCandidates
      maxSelection
      tools
      category {
        id
        name
      }
      programCategory {
        id
        name
      }
      mode
      type
      isRegistrable
      isGroupwiseResult
      isDebate
      isItemRegistrable
      isProposalSubmission
      isStarred
      section {
        id
        name
      }
    }
  }
`;
export const PROGRAMS_HAVING = gql`
  query programsHaving {
    programsHaving {
      categoryName
      count
    }
  }
`;
export const PROGRAMS_HAVING_CATEGORIES = gql`
  query programsHavingCategories {
    programsHavingWithCategories {
      category
      finalAtDH
      eliminationAtUG
      offStageAtCluster
    }
  }
`;
export const GET_REGISTRABLE_PROGRAMS = gql`
  query registrablePrograms {
    registrablePrograms {
      category {
        id
        name
      }
      id
      programCategory {
        id
        name
      }
      name
      programCode
      section {
        id
        name
      }
    }
  }
`;

export const GET_DEBATE_PROGRAMS = gql`
  query DebateProgram {
    debateProgram {
      id
      name
    }
  }
`;
