import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import { GET_SECTIONS } from "../../graphql/queries/section";

import Modal from "../UIElements/Model";
import Input from "../ui-elements/Input";
import Select from "../ui-elements/Select";
import { GET_GROUPS } from "../../graphql/queries/group";
import { CREATE_GROUP, DELETE_GROUP, UPDATE_GROUP } from "../../graphql/mutations/group";
import toast from "react-hot-toast";
import Card from "./Card";
import CardStructure from "./CardStructure";
import Skeleton from "../UIElements/Skeleton";

const Groups: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [group, setGroup] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<string>("");
  const [editGroup, setEditGroup] = useState<any>(null);
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteGroupId, setDeleteGroupId] = useState<string>(""); 

  useEffect(() => {
    if (editGroup) {
      setGroup(editGroup.name);
      setSelectedSection(editGroup.section.id);
    }
  }, [editGroup]);

  // QUERY
  const {
    data: sectionData,
    loading: sectionLoading,
    error: sectionError,
  } = useQuery(GET_SECTIONS);
  const {
    data: groupData,
    loading: groupLoading,
    error: groupError,
    refetch,
  } = useQuery(GET_GROUPS);

  // MUTATION
  const [createGroup, { loading: createGroupLoading }] =
    useMutation(CREATE_GROUP);
  const [updateGroup, { loading: updateGroupLoading }] =
    useMutation(UPDATE_GROUP);
  const [deleteGroup, { loading: deleteGroupLoading }] =
    useMutation(DELETE_GROUP);

  if (sectionLoading || groupLoading) return <Skeleton />;

  if (sectionError)
    return <p>Error loading sections: {sectionError.message}</p>;
  if (groupError) return <p>Error loading sections: {groupError.message}</p>;

  const handleAddGroup = async () => {
    setEditGroup(null);
    clearInput();
    setIsOpen(true);
  };

  const handleEditGroup = async (item: any) => {
    setEditGroup(item);
    setGroup(item.name);
    setSelectedSection(item.section.id);
    setIsOpen(true);
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setDeleteGroupId(id);
  }

  const handleDelete = async() => {
    try {
      await deleteGroup({ variables: {id: deleteGroupId}});
      toast.success("Group deleted successfully!");
      setDeleteModel(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error("Failed to delete the group", error);
      toast.error("Failed to delete the group")
    }
  }

  const clearInput = () => {
    setGroup("");
    setSelectedSection("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!group.trim() || !selectedSection.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      if (editGroup) {
        await updateGroup({
          variables: {
            id: editGroup.id,
            name: group.trim(),
            section: selectedSection,
          },
        });
        toast.success("Group updated successfully!");
      } else {
        await createGroup({
          variables: {
            name: group.trim(),
            section: selectedSection,
          },
        });
        toast.success("Group created successfully!");
      }

      setIsOpen(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error(
        editGroup ? `Failed to update the group:` : `Failed to create the group:`,
        error
      );
      toast.error(
        editGroup ? `Failed to update the group:` : `Failed to create the group:`
      );
    }
  };

  return (
    <>
      <CardStructure
        Heading="Groups"
        addButton="Add"
        addButtonOnCLick={handleAddGroup}
        data={groupData.groups}
        renderCard={(item: any) => (
          <Card
            item={item}
            title={item.name}
            subTitle={item.section.name}
            handleEditButton={handleEditGroup}
            handleDeleteButton={handleDeleteConfirm}
            textClass="text-sm"
          />
        )}
      />
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title={editGroup ? "Edit Group" : "Add Group"}
        handleSubmit={handleSubmit}
        loading={editGroup ? updateGroupLoading : createGroupLoading}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <Input
            placeholder="Enter Group Name"
            label="name"
            value={group}
            onChange={(e) => setGroup(e.target.value)}
          />
          <Select
            label="section"
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.target.value)}
            options={sectionData.sections}
          />
        </form>
      </Modal>
      <Modal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        title="Delete Confirmation"
        handleSubmit={handleDelete}
        loading={deleteGroupLoading}
        danger={true}
        buttonText="Delete"
      >
        <p className="text-center bg-red-100 text-red-800 rounded-md border border-red-600 p-3">Are you sure want to delete this group?</p>
      </Modal>
    </>
  );
};

export default Groups;
