import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import Candidate from "../../components/programs/Candidate";
import ProgramDetails from "../../components/programs/ProgramDetails";
import ProgramDetailsHeader from "../../components/programs/ProgramDetailsHeader";
import { GET_PROGRAM_BY_ID } from "../../graphql/queries/program";
import { GET_PROGRAM_PARTICIPANTS } from "../../graphql/queries/participation";
import Private from "../../context/Private";
import Modal from "../../components/UIElements/Model";
import ParticipationForm from "../../components/participation/ParticipationForm";
import toast from "react-hot-toast";
import { DELETE_PARTICIPATION } from "../../graphql/mutations/participation";
import { useAuth } from "../../context/AuthContext";

const Program: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean[]>([]);
  const [isCreateModalOpen, setCreateModalIsOpen] = useState<boolean>(false);
  const [deleteParticipationId, setDeleteParticipationId] = useState("");
  const [editParticipationId, setEditParticipationId] = useState("");
  const [deleteModel, setDeleteModel] = useState(false);

  const { user } = useAuth();
  const isInstitutionLogin = user?.institution ? true : false;
  const client = useApolloClient();

  const { programId } = useParams<{ programId: string }>();
  const {
    data: participants,
    error: participantsError,
    loading: participantsLoading,
  } = useQuery(GET_PROGRAM_PARTICIPANTS, { variables: { programId } });

  const { data, loading, error } = useQuery(GET_PROGRAM_BY_ID, {
    variables: { id: programId },
  });

  const [deleteParticipation, { loading: deleteLoading }] = useMutation(
    DELETE_PARTICIPATION,
    {
      variables: { id: deleteParticipationId },
      refetchQueries: [
        {
          query: GET_PROGRAM_PARTICIPANTS,
          variables: {
            programId,
          },
        },
      ],
      onCompleted: async () => {
        setDeleteModel(false);
        setDeleteParticipationId("");
        toast.success("Participation deleted successfully!");

        if (isInstitutionLogin) {
          await client.cache.evict({
            id: "ROOT_QUERY",
            fieldName: "institutionParticipants", // Replace with the exact name of the query field in the cache
            args: {
              programId,
              institutionId: user?.institution?.id,
            },
          });
        }
      },
      onError: (error) => {
        console.error("Delete error:", error);
        toast.error("Failed to delete the Participation!");
      },
    }
  );

  const handleDeleteConfirm = (id: string) => {
    setDeleteModel(!deleteModel);
    setDeleteParticipationId(id);
  };

  const handleDelete = () => deleteParticipation();

  const toggleModal = (index: number) => {
    var updatedIsOpen = [...isOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsOpen(updatedIsOpen);
  };

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>{error?.message}</p>;
  }
  return (
    <>
      <PageTitle pagetitle={"Program Details"} subtitle={"Programs"} />
      <ProgramDetailsHeader program={data?.program} />
      <div className="grid lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <ProgramDetails program={data?.program} />
        </div>
        <div className="col-span-1">
          <div className="card">
            <div className="card-header flex items-center justify-between">
              <h6 className="card-title">Candidates</h6>
              <div className="flex space-x-2">
                {/* When clicked, toggle the modal */}
                <Private
                  permissions={["create-participation"]}
                  element={
                    <span
                      className="text-xl cursor-pointer"
                      onClick={() => setCreateModalIsOpen(!isCreateModalOpen)}
                    >
                      <i className="mgc_user_add_line"></i>
                    </span>
                  }
                />

                {participants?.programParticipants?.length === 1 && (
                  <Private
                    permissions={["update-participation"]}
                    element={
                      <span
                        className="text-xl cursor-pointer"
                        onClick={() => {
                          setCreateModalIsOpen(!isCreateModalOpen);
                          setEditParticipationId(
                            participants?.programParticipants[0]?.id
                          );
                        }}
                      >
                        <i className="mgc_pencil_line"></i>
                      </span>
                    }
                  />
                )}

                {participants?.programParticipants?.length === 1 && (
                  <Private
                    permissions={["delete-participation"]}
                    element={
                      <div
                        onClick={() =>
                          handleDeleteConfirm(
                            participants?.programParticipants[0]?.id
                          )
                        }
                        className="text-xl text-red-600 cursor-pointer"
                      >
                        <i className="mgc_delete_2_line"></i>
                      </div>
                    }
                  />
                )}
              </div>
            </div>
            <div className="table overflow-hidden w-full">
              <div className="divide-y divide-gray-200 overflow-auto w-full max-w-full">
                {!participants?.programParticipants?.length && (
                  <p className="text-center m-3">No participation added</p>
                )}

                {participantsLoading && (
                  <p className="text-center m-3">Loading..</p>
                )}
                {participantsError && (
                  <p className="text-center m-3">Participation Data Error</p>
                )}

                {participants?.programParticipants?.length === 1 &&
                  participants?.programParticipants?.map(
                    (participant: any, index: number) => (
                      <div key={index}>
                        {participant?.candidate?.map((candidate: any) => (
                          <Candidate
                            photo={candidate.photo}
                            name={candidate.name}
                            chestNo={candidate.chestNo}
                            institution={candidate.institution?.name}
                            key={candidate.id}
                            candidate={candidate}
                          />
                        ))}
                      </div>
                    )
                  )}

                {participants?.programParticipants?.length > 1 &&
                  participants?.programParticipants?.map(
                    (participant: any, index: number) => (
                      <>
                        {participant?.candidate?.length === 1 &&
                          participant?.candidate?.map((candidate: any) => (
                            <Candidate
                              photo={candidate.photo}
                              name={candidate.name}
                              chestNo={candidate.chestNo}
                              institution={candidate.institution?.name}
                              key={candidate.id}
                              participationId={participant?.id}
                              handleDelete={handleDeleteConfirm}
                              setEditId={setEditParticipationId}
                              handleEdit={setCreateModalIsOpen}
                              edit={isCreateModalOpen}
                              candidate={candidate}
                            />
                          ))}

                        {participant?.candidate?.length > 1 && (
                          <>
                            <div
                              className="p-3 flex items-center justify-between"
                              key={index}
                            >
                              <p>{participant.institution.shortName}</p>
                              <div className="flex space-x-2">
                                <Private
                                  permissions={["update-participation"]}
                                  element={
                                    <span
                                      className="text-xl cursor-pointer"
                                      onClick={() => {
                                        setCreateModalIsOpen(
                                          !isCreateModalOpen
                                        );
                                        setEditParticipationId(participant.id);
                                      }}
                                    >
                                      <i className="mgc_pencil_line"></i>
                                    </span>
                                  }
                                />
                                <Private
                                  permissions={["delete-participation"]}
                                  element={
                                    <div
                                      onClick={() =>
                                        handleDeleteConfirm(participant.id)
                                      }
                                      className="text-xl text-red-600 cursor-pointer"
                                    >
                                      <i className="mgc_delete_2_line"></i>
                                    </div>
                                  }
                                />
                                <i
                                  className={`mgc_${
                                    isOpen[index] ? "down" : "right"
                                  }_line text-xl`}
                                  onClick={() => toggleModal(index)}
                                ></i>
                              </div>
                            </div>
                            {isOpen[index] &&
                              participant?.candidate?.map((candidate: any) => (
                                <Candidate
                                  photo={candidate.photo}
                                  name={candidate.name}
                                  chestNo={candidate.chestNo}
                                  institution={candidate.institution?.name}
                                  key={candidate.id}
                                  candidate={candidate}
                                />
                              ))}
                          </>
                        )}
                      </>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {isCreateModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="bg-black bg-opacity-50 absolute inset-0"
            onClick={() => {
              setCreateModalIsOpen(!isCreateModalOpen);
              setEditParticipationId("");
            }}
          ></div>
          <div className="bg-white p-6 rounded-lg z-10 max-w-lg w-full max-h-[90vh] overflow-y-auto no-scrollbar">
            <h2 className="text-2xl mb-4 text-gray-800">Add Participation</h2>
            <ParticipationForm
              programId={data?.program?.id}
              programName={data?.program?.name}
              noOfCandidates={data?.program?.noOfCandidates}
              programCode={data?.program?.programCode}
              category={data?.program?.category}
              isRegistrable={data?.program?.isRegistrable}
              closeModal={() => {
                setCreateModalIsOpen(!isCreateModalOpen);
                setEditParticipationId("");
              }}
              editParticipationId={editParticipationId}
            />
          </div>
        </div>
      )}

      <Modal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        title="Delete Confirmation"
        handleSubmit={handleDelete}
        loading={deleteLoading}
        danger={true}
        buttonText="Delete"
      >
        <p className="text-center bg-red-100 text-red-800 rounded-md border border-red-600 p-3">
          Are you sure want to delete this participation?
        </p>
      </Modal>
    </>
  );
};

export default Program;
