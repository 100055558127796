import { useQuery } from "@apollo/client";
import React, { useState } from "react";

import PageTitle from "../../components/PageTitle";
import Card from "../../components/participants/Card";
import { GET_CATEGORIES } from "../../graphql/queries/category";
import { GET_PROGRAM_CATEGORIES } from "../../graphql/queries/programCategory";
import { GET_INSTITUTIONS } from "../../graphql/queries/institution";
import { GET_PARTICIPATIONS } from "../../graphql/queries/participation";

const Participants: React.FC = () => {
    const { data: participationData, error, loading } = useQuery(GET_PARTICIPATIONS);
    const { data: institutionData } = useQuery(GET_INSTITUTIONS);
    const { data: categoryData } = useQuery(GET_CATEGORIES);
    const { data: programCategoryData } = useQuery(GET_PROGRAM_CATEGORIES);

    const [searchTerm, setSearchTerm] = useState("");
    // const [selectedCategory, setSelectedCategory] = useState("");
    // const [selectedInstitution, setSelectedInstitution] = useState("");
    // const [selectedProgramCategory, setSelectedProgramCategory] = useState("");
    const [searchInstitutions, setSearchInstitutions] = useState("");
    const [searchCategories, setSearchCategories] = useState("");
    const [searchProgramCategories, setSearchProgramCategories] = useState("");
    const [selectedInstitutions, setSelectedInstitutions] = useState<string[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedProgramCategories, setSelectedProgramCategories] = useState<string[]>([]);

    if (loading) {
        return <p>Loading...</p>;
    }
    if (error) {
        return <p>{error.message}</p>;
    }

    const candidatesAvail: string[] = ["5717"];

    // Filter programs based on search term and selected filters
    const filterParticipants = participationData.participations.filter(
        (participation: {
            institution: {
                id: string;
                name: string;
            }
            candidate: [{
                id: string;
                name: string;
                photo: string;
                chestNo: string;
            }]
            program: {
                name: string;
                category: {
                    id: string;
                    name: string;
                };
                programCategory: {
                    id: string;
                    name: string;
                };
            }
        }) => {
            const matchesSearch = participation?.candidate[0]?.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            const matchesInstitution = selectedInstitutions?.length > 0
                ? selectedInstitutions.includes(participation?.institution?.id)
                : true;
            const matchesCategory = selectedCategories?.length > 0
                ? selectedCategories.includes(participation?.program?.category?.id)
                : true;
            const matchesProgramCategory = selectedProgramCategories?.length > 0
                ? selectedProgramCategories.includes(participation?.program?.programCategory?.id)
                : true;
            // const matchesInstitution = selectedInstitution
            //     ? participation?.institution?.id === selectedInstitution
            //     : true;
            // const matchesCategory = selectedCategory
            //     ? participation?.program?.category?.id === selectedCategory
            //     : true;
            // const matchesProgramCategory = selectedProgramCategory
            //     ? participation?.program?.programCategory?.id === selectedProgramCategory
            //     : true;
            return (
                matchesSearch
                && matchesInstitution
                && matchesCategory
                && matchesProgramCategory
            );
        }
    );

    const filteredParticipants = filterParticipants.filter(
        (participation: {
            institution: {
                id: string;
                name: string;
            }
            candidate: [{
                id: string;
                name: string;
                photo: string;
                chestNo: string;
            }]
            program: {
                name: string;
                category: {
                    id: string;
                    name: string;
                };
                programCategory: {
                    id: string;
                    name: string;
                };
            }
        }) => {
            const isDuplicate = candidatesAvail.includes(participation?.candidate[0]?.id);
            !isDuplicate && candidatesAvail.push(participation?.candidate[0]?.id);
            return !isDuplicate;
        }
    );

    const searchedInstitutions = institutionData?.institutions?.filter((institution: any) => institution.name.toLowerCase().includes(searchInstitutions.toLowerCase()));
    const searchedCategories = categoryData?.categories?.filter((category: any) => category.name.toLowerCase().includes(searchCategories.toLowerCase()));
    const searchedProgramCategories = programCategoryData?.programCategories?.filter((category: any) => category.name.toLowerCase().includes(searchProgramCategories.toLowerCase()));

    const handleInstitutionsCheckbox = (institutionId: string) => {
        setSelectedInstitutions((prevSelected) => {
            if (prevSelected.includes(institutionId)) {
                return prevSelected.filter((id) => id !== institutionId);
            } else {
                return [...prevSelected, institutionId];
            }
        });
    };

    const handleCategoriesCheckbox = (categoryId: string) => {
        setSelectedCategories((prevSelected) => {
            if (prevSelected.includes(categoryId)) {
                return prevSelected.filter((id) => id !== categoryId);
            } else {
                return [...prevSelected, categoryId];
            }
        });
    };

    const handleProgramCategoriesCheckbox = (categoryId: string) => {
        setSelectedProgramCategories((prevSelected) => {
            if (prevSelected.includes(categoryId)) {
                return prevSelected.filter((id) => id !== categoryId);
            } else {
                return [...prevSelected, categoryId];
            }
        });
    };

    // const handleDownload = () => {
    //   // flatten object like this {id: 1, title:'', category: ''};
    //   const rows = filteredPrograms.map((program: any) => ({
    //     programCode: program.programCode,
    //     name: program.name,
    //     concept: program.concept,
    //     duration: program.duration,
    //     skill: program.skill?.name,
    //     noOfCandidates: program.noOfCandidates,
    //     tools: program.tools,
    //     section: program.section?.name,
    //     category: program.category?.name,
    //     programCategory: program.programCategory?.name,
    //     mode: program.mode,
    //     type: program.type,
    //     curbGroup: program.curbGroup?.name,
    //     isRegistrable: program.isRegistrable ? "Yes" : "No",
    //     isItemRegistrable: program.isItemRegistrable ? "Yes" : "No",
    //     isProposalSubmission: program.isProposalSubmission ? "Yes" : "No",
    //     isStarred: program.isStarred ? "Yes" : "No",
    //     publishTimeElimination: program.publishTimeElimination,
    //     eliminationResultStatus: program.eliminationResultStatus,
    //     publishTimeFinal: program.publishTimeFinal,
    //     finalResultStatus: program.finalResultStatus,
    //     maxSelection: program.maxSelection,
    //     status: program.status,
    //     finalDate: program.finalDate,
    //     finalStartingTime: program.finalStartingTime,
    //     finalEndingTime: program.finalEndingTime,
    //     finalVenue: program.finalVenue?.name,
    //     eliminiationDate: program.eliminiationDate,
    //     eliminiationStartingTime: program.eliminiationStartingTime,
    //     eliminiationEndingTime: program.eliminiationEndingTime,
    //     eliminationVenue: program.eliminationVenue?.name,
    //   }));

    //   // create workbook and worksheet
    //   const workbook = XLSX.utils.book_new();
    //   const worksheet = XLSX.utils.json_to_sheet(rows);

    //   XLSX.utils.book_append_sheet(workbook, worksheet, "Programs");

    //   XLSX.writeFile(workbook, "Programs.xlsx", { compression: true });
    // };

    return (
        <>
            <PageTitle pagetitle={"Participants Count"} subtitle={"Participants"} />

            {/* Responsive form controls */}
            <div className="flex flex-col sm:flex-row gap-2 mb-7">
                <div>
                    <div className="flex flex-grow relative">
                        <div className="pointer-events-none absolute top-3.5 left-4 text-gray-900 text-opacity-40 dark:text-gray-200">
                            <i className="mgc_search_line text-xl"></i>
                        </div>
                        <input
                            type="search"
                            className="h-12 w-full border rounded-lg bg-transparent pl-11 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                            placeholder="Search Programs..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>

                {/* <div className="flex-grow hidden sm:block">
                    <select
                        required
                        value={selectedInstitution}
                        onChange={(e) => setSelectedInstitution(e.target.value)}
                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                    >
                        <option value="">Select Institution</option>
                        {institutionData?.institutions?.map((institution: any) => (
                            <option key={institution.id} value={institution.id}>
                                {institution.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex-grow hidden sm:block">
                    <select
                        required
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                    >
                        <option value="">Select Category</option>
                        {categoryData?.categories?.map((category: any) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div> */}

                {/* <div className="flex-grow hidden sm:block">
                    <select
                        required
                        value={selectedProgramCategory}
                        onChange={(e) => setSelectedProgramCategory(e.target.value)}
                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                    >
                        <option value="">Select Program Category</option>
                        {programCategoryData?.programCategories?.map((category: any) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div> */}

                <div className="w-72 hidden sm:block">
                    <div>
                        <input
                            type="search"
                            placeholder="Select Institutions"
                            value={searchInstitutions}
                            onChange={(e) => setSearchInstitutions(e.target.value)}
                            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                        />
                    </div>
                    {searchInstitutions && (
                        <div className="absolute w-72 max-h-56 rounded-xl overflow-hidden bg-white z-50 shadow-xl overflow-y-scroll">
                            {searchedInstitutions?.map((institution: any, index: number) => (
                                <div
                                    key={index}
                                    className="flex items-center px-3 py-1 border-b cursor-pointer hover:bg-gray-200"
                                    onClick={() => handleInstitutionsCheckbox(institution.id)}
                                >
                                    <input
                                        type="checkbox"
                                        id={`institution-${institution.id}`}
                                        checked={selectedInstitutions.includes(institution.id)}
                                        onChange={() => handleInstitutionsCheckbox(institution.id)}
                                        className="mx-2"
                                    />
                                    <label htmlFor={`institution-${institution.id}`} className="text-md font-semibold">
                                        {institution.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="w-72 hidden sm:block">
                    <div>
                        <input
                            type="search"
                            placeholder="Select Categories"
                            value={searchCategories}
                            onChange={(e) => setSearchCategories(e.target.value)}
                            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                        />
                    </div>
                    {searchCategories && (
                        <div className="absolute w-72 max-h-56 rounded-xl overflow-hidden bg-white z-50 shadow-xl overflow-y-scroll">
                            {searchedCategories?.map((category: any, index: number) => (
                                <div
                                    key={index}
                                    className="flex items-center px-3 py-1 border-b cursor-pointer hover:bg-gray-200"
                                    onClick={() => handleCategoriesCheckbox(category.id)}
                                >
                                    <input
                                        type="checkbox"
                                        id={`category-${category.id}`}
                                        checked={selectedCategories.includes(category.id)}
                                        onChange={() => handleCategoriesCheckbox(category.id)}
                                        className="mx-2"
                                    />
                                    <label htmlFor={`category-${category.id}`} className="text-md font-semibold">
                                        {category.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="w-72 hidden sm:block">
                    <div>
                        <input
                            type="search"
                            placeholder="Select Program Categories"
                            value={searchProgramCategories}
                            onChange={(e) => setSearchProgramCategories(e.target.value)}
                            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                        />
                    </div>
                    {searchProgramCategories && (
                        <div className="absolute w-72 max-h-56 rounded-xl overflow-hidden bg-white z-50 shadow-xl overflow-y-scroll">
                            {searchedProgramCategories?.map((category: any, index: number) => (
                                <div
                                    key={index}
                                    className="flex items-center px-3 py-1 border-b cursor-pointer hover:bg-gray-200"
                                    onClick={() => handleProgramCategoriesCheckbox(category.id)}
                                >
                                    <input
                                        type="checkbox"
                                        id={`category-${category.id}`}
                                        checked={selectedProgramCategories.includes(category.id)}
                                        onChange={() => handleProgramCategoriesCheckbox(category.id)}
                                        className="mx-2"
                                    />
                                    <label htmlFor={`category-${category.id}`} className="text-md font-semibold">
                                        {category.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="flex-grow hidden sm:block">
                    <div className="h-12 w-full flex items-center justify-center font-semibold border rounded-lg bg-red p-2 text-white placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm">
                        Count: {filteredParticipants?.length}
                    </div>
                </div>

                {/* <Private
          permissions={["show-programs"]}
          element={
            <div>
              <button
                onClick={handleDownload}
                className="btn w-full h-full bg-green-300 inline-flex items-center justify-center text-sm font-medium text-green-700 hover:text-white hover:bg-green-500"
              >
                <i className="mgc_arrow_down_circle_line me-2 text-xl"></i>{" "}
                Download
              </button>
            </div>
          }
        /> */}
            </div>

            {/* Responsive program cards */}
            <div className="relative overflow-x-auto">
                <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
                    <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                            >
                                Chest No.
                            </th>
                            {/* <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                            >
                                Photo
                            </th> */}
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                            >
                                Full Name
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                            >
                                Institution
                            </th>
                            {/* <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                            >
                                Program
                            </th> */}
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                            >
                                Category
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
                            >
                                Program Category
                            </th>

                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {filteredParticipants?.map((participation: any) => (
                            <Card
                                participantId={participation?.id}
                                key={participation?.id}
                                chestNo={participation?.candidate[0]?.chestNo}
                                name={participation?.candidate[0]?.name}
                                // photo={participation?.candidate[0]?.photo}
                                institution={participation?.institution?.name}
                                // program={participation?.program?.name}
                                category={participation?.program?.category?.name}
                                programCategory={participation?.program?.programCategory?.name}
                            />
                        ))}


                        {/* <div className="text-center mt-6">
              <button
                type="button"
                className="btn bg-transparent border-gray-300 dark:border-gray-700"
              >
                <i className="mgc_loading_4_line me-2 animate-spin"></i>
                <span>Load More</span>
              </button>
            </div> */}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default Participants;
