import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { gql, useQuery } from "@apollo/client";

interface Skill {
  name: string;
  totalPrograms: number;
  totalCandidates: number;
}

const GET_SKILL_SUMMARIES = gql`
  query GetSkillSummaries {
    getSkillSummaries {
      name
      totalPrograms
      totalCandidates
    }
  }
`;

const SkillSummaryComponent: React.FC = () => {
  const { loading, error, data } = useQuery(GET_SKILL_SUMMARIES);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  if (loading) return <div className="text-center">Loading...</div>;
  if (error)
    return (
      <div className="text-red-500 text-center">Error: {error.message}</div>
    );

  const skills = data.getSkillSummaries;

  // Predefined chart colors
  const chartColors = ["#45f", "#4f5", "#fb7185", "#ffeb3b"];

  const chartOptions = {
    options: {
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "16px",
            },
            value: {
              fontSize: "14px",
            },
          },
        },
      },
      labels: skills.map((skill: Skill) => skill.name),
      colors: chartColors, // Use predefined chart colors
    },
  };

  const chartSeries = skills.map((skill: Skill) => {
    const totalPrograms = skill.totalPrograms;
    const totalCandidates = skill.totalCandidates;

    // Handle division by zero for percentage
    return totalPrograms > 0 ? (totalCandidates / totalPrograms) * 100 : 0;
  });

  return (
    <div className="h-full 2xl:col-span-2 md:col-span-2">
      <div className="card h-full">
        <div className="p-6">
          <div className="flex justify-between items-center">
            <h4 className="card-title">Skill Overview</h4>
            <div>
              <button
                className="text-gray-600 dark:text-gray-400"
                onClick={toggleDropdown}
                type="button"
              >
                <i className="mgc_more_2_fill text-xl"></i>
              </button>

              <div
                className={`${
                  isDropdownOpen ? "opacity-100" : "hidden opacity-0"
                } fc-dropdown w-36 z-50 mt-2 transition-[margin,opacity] duration-300 bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 rounded-lg p-2`}
              >
                {["Today", "Yesterday", "Last Week", "Last Month"].map(
                  (option, index) => (
                    <a
                      key={index}
                      className="flex items-center gap-1.5 py-1.5 px-3.5 rounded text-sm transition-all duration-300 bg-transparent text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-200"
                      href="javascript:void(0)"
                    >
                      {option}
                    </a>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-2 items-center gap-4">
            <div className="md:order-1 order-2">
              <div className="flex flex-col gap-6">
                {skills.map((skill: Skill, index: number) => (
                  <div className="flex items-center" key={index}>
                    <div className="flex-shrink-0">
                      <i
                        className={`mgc_round_fill h-10 w-10 flex justify-center items-center rounded-full ${chartColors[index % chartColors.length]} text-lg`} // Cycle through colors for icons
                      ></i>
                    </div>
                    <div className="flex-grow ms-3">
                      <h5 className="fw-semibold mb-1">{skill.name}</h5>
                      <ul className="flex items-center gap-2">
                        <li className="list-inline-item">
                          <b>{skill.totalPrograms}</b> Programs
                        </li>
                        <li className="list-inline-item">
                          <div className="w-1 h-1 rounded bg-gray-400"></div>
                        </li>
                        <li className="list-inline-item">
                          <b>{skill.totalCandidates}</b> Candidates
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="md:order-2 order-1">
              <ReactApexChart
                options={chartOptions.options}
                series={chartSeries}
                type="radialBar"
                height={350}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillSummaryComponent;
