import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import KanbanBoard from "../../components/UIElements/KanbanBoard";
import AddCurbModal from "../../components/curblist/AddCurbModal";
import EditCurbModal from "../../components/curblist/EditCurbModal";
import DeleteCurbModal from "../../components/curblist/DeleteCurbModal";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CURB_DATA } from "../../graphql/queries/programcurbs";
import {
  CREATE_PROGRAM_CURB,
  UPDATE_PROGRAM_CURB,
  DELETE_PROGRAM_CURB,
} from "../../graphql/mutations/programCurb";
import { GET_CATEGORIES } from "../../graphql/queries/category";
import { GET_SKILLS } from "../../graphql/queries/skill";
import { GET_PROGRAMS } from "../../graphql/queries/program";
import toast from "react-hot-toast";

interface ProgramCurb {
  id: string;
  name: string;
  maximumProgramsCount: number;
  programs?: Program[];
}

interface Program {
  id: string;
  programCode: string;
  name: string;
  category: Category;
  skill: Skill;
}

interface Category {
  id: string;
  name: string;
}

interface Skill {
  id: string;
  name: string;
}

const CurbList: React.FC = () => {
  const [deleteProgramCurb] = useMutation(DELETE_PROGRAM_CURB);
  const [updateProgramCurb] = useMutation(UPDATE_PROGRAM_CURB);
  const [createProgramCurb] = useMutation(CREATE_PROGRAM_CURB);
  const {
    loading: loadingCategories,
    error: errorCategories,
    data: queryCategories,
  } = useQuery<{ categories: Category[] }>(GET_CATEGORIES);
  const {
    loading: loadingSkills,
    error: errorSkills,
    data: querySkills,
  } = useQuery<{ skills: Skill[] }>(GET_SKILLS);
  const {
    loading: loadingData,
    error: errorData,
    data: queryData,
    refetch,
  } = useQuery<{ programCurbs: ProgramCurb[] }>(GET_CURB_DATA);

  const {
    loading: loadingPrograms,
    error: errorPrograms,
    data: queryPrograms,
  } = useQuery<{ programs: Program[] }>(GET_PROGRAMS);

  const [programList, setProgramList] = useState<Program[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [data, setData] = useState<ProgramCurb[]>([]);
  const [isOpen, setIsOpen] = useState<boolean[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCurb, setSelectedCurb] = useState<ProgramCurb | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (!loadingCategories && !errorCategories && queryCategories) {
      setCategories(queryCategories.categories);
    }

    if (!loadingSkills && !errorSkills && querySkills) {
      setSkills(querySkills.skills);
    }

    if (!loadingPrograms && !errorPrograms && queryPrograms) {
      setProgramList(queryPrograms?.programs);
    }

    if (!loadingData && !errorData && queryData) {
      setData(queryData.programCurbs);
      setIsOpen(queryData.programCurbs.map(() => false));
    }
  }, [queryData, queryPrograms, querySkills, queryCategories]);

  const addNewCurb = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = async (
    name: string,
    maximumProgramsCount: number
  ) => {
    try {
      await createProgramCurb({
        variables: {
          input: {
            name,
            maximumProgramsCount,
          },
        },
      });
      toast.success("New program curb created successfully");
      console.log("New program curb created successfully");
      await refetch();
    } catch (error) {
      toast.error("Error creating program curb");
      console.error("Error creating program curb", error);
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleEdit = (curb: ProgramCurb) => {
    setSelectedCurb(curb);
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async (
    name: string,
    maximumProgramsCount: number
  ) => {
    if (!selectedCurb) return;

    try {
      await updateProgramCurb({
        variables: {
          id: selectedCurb.id,
          input: {
            name,
            maximumProgramsCount,
          },
        },
      });
      toast.success("program curb updated successfully");
      console.log("program curb updated successfully");
      await refetch();
    } catch (error) {
      toast.error("Error updating program curb");
      console.error("Error updating program curb", error);
    } finally {
      setIsEditModalOpen(false);
    }
  };

  const handleDelete = (curb: ProgramCurb) => {
    setSelectedCurb(curb);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!selectedCurb) return;

    try {
      await deleteProgramCurb({
        variables: {
          id: selectedCurb.id,
        },
      });
      toast.success("program curb deleted successfully");
      console.log("program curb deleted successfully");
      await refetch();
    } catch (error) {
      toast.error("Error deleting program curb");
      console.error("Error deleting program curb", error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };

  const handleDragEnd = async (result: DropResult) => {

    const { destination, source, draggableId } = result;

    var changedDataIndex = null;
    // Exit if no destination or if source and destination are the same
    if (!destination || source.droppableId === destination.droppableId) return;
    // Get the specific program from programList with the matching draggableId
    const movedProgram = programList.find(
      (program) => program.id === draggableId
    );

    if (!movedProgram) {
      toast.error("No program Found with this id")
      console.error("No program Found with this id");
      return "No program Found with this id"
    }

    // Clone the data to avoid mutating state directly
    const updatedData = data.map((item, index) => {
      if (item.id === destination.droppableId) {

        // Check if the moved program already exists in the target curb's programs
        const programExists = item.programs?.some(
          (program) => program.id === movedProgram.id
        );

        if (programExists) {
          changedDataIndex = null;
          toast.error("This Program is already exist in this CRUB");
          console.log("This Program is already exist in this CRUB");
          return {
            ...item,
            programs: [...(item.programs || [])],
          };
        } else {
          changedDataIndex = index;
          // Add the moved program to the target curb
          return {
            ...item,
            programs: [...(item.programs || []), movedProgram],
          };
        }
      }
      return item; // Return the item unchanged if conditions are not met
    });

    if (changedDataIndex != null) {
      try {
        await updateProgramCurb({
          variables: {
            id: updatedData[changedDataIndex].id,
            input: {
              programs: [
                ...(updatedData[changedDataIndex].programs?.map(
                  (item) => item.id
                ) || []),
              ],
            },
          },
        });
        changedDataIndex = null;
        toast.success("Program CURB updated successfully");
        console.log("Program CURB updated successfully");
      } catch (error) {
        toast.error("Error updating Program CURB");
        console.error("Error updating Program CURB", error);
      }
    }
    // Update the data state with the modified programs array
    setData(updatedData);
  };

  const removeItem = async (removeId: string, curbId: string) => {
    // Clone the current data to avoid mutating state directly
    const updatedData = data.map((curb) => {
      if (curb.id === curbId) {
        return {
          ...curb,
          programs: curb.programs?.filter((program) => program.id !== removeId),
        };
      }
      return curb;
    });
    // Find the updated curb to get the new list of program IDs
    const updatedCurb = updatedData.find((curb) => curb.id === curbId);

    try {
      // Make the API call to update the server
      await updateProgramCurb({
        variables: {
          id: curbId,
          input: {
            programs: updatedCurb?.programs?.map((program) => program.id),
          },
        },
      });

      // Update the state only after a successful server update
      setData(updatedData);
      toast.success("Program removed successfully");
      console.log("Program removed successfully");
    } catch (error) {
      toast.error("Error removing program");
      console.error("Error removing program", error);
    }
  };

  const updateIsOpen = (index: number) => {
    setIsOpen((prev) => prev.map((open, i) => (i === index ? !open : open)));
  };

  if (loadingData) return <p>Loading...</p>;
  if (errorData) return <p>Error: {errorData.message}</p>;

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="h-[75vh]">
        <PageTitle pagetitle={"Curb List"} subtitle={"Programs"} />
        <div className="grid md:grid-cols-3 grid-cols-1 gap-6 h-full">
          <div className="col-span-1 flex flex-col h-full overflow-y-auto">
            <div className="pb-2 border-b border-gray-200 dark:border-gray-700">
              <input
                type="search"
                className="h-10 w-full border-gray-300 ps-4 rounded-xl pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="grid grid-cols-2 mt-4 gap-2">
                <select
                  className="h-10 w-full border-gray-300 ps-4 rounded-xl pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <select
                  className="h-10 w-full border-gray-300 ps-4 rounded-xl pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                  value={selectedSkill?.id || ""}
                  onChange={(e) => {
                    const skill = skills.find(
                      (skill) => skill.id === e.target.value
                    );
                    setSelectedSkill(skill || null);
                  }}
                >
                  <option value="">Select Skill</option>
                  {skills.map((skill) => (
                    <option key={skill.id} value={skill.id}>
                      {skill.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <Droppable droppableId="programList">
              {(provided) => (
                <div
                  className="program_list mt-4 space-y-4 overflow-y-auto h-full no-scrollbar"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {programList
                    .filter((program) => {
                      const matchesQuery =
                        searchQuery === "" ||
                        program.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        program.programCode
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase());
                      const matchesCategory =
                        !selectedCategory ||
                        program.category.id === selectedCategory;
                      const matchesSkill =
                        !selectedSkill || program.skill.id === selectedSkill.id;

                      return matchesQuery && matchesCategory && matchesSkill;
                    })
                    .map((program, index) => (
                      <Draggable
                        key={program.id}
                        draggableId={program.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="border border-gray-200 bg-white bg-opacity-70 dark:border-gray-800 dark:bg-gray-800 rounded-xl p-4 cursor-grab"
                          >
                            <p className="font-semibold">{program.name}</p>
                            <p className="text-xs">{program.programCode}</p>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div className="grid place-content-start 2xl:grid-cols-3 md:grid-cols-2 grid-cols-1 col-span-2 gap-x-6 gap-y-2 overflow-y-auto no-scrollbar">
            {data.map((curb, index) => (
              <KanbanBoard
                data={curb.programs ?? []}
                id={curb.id}
                name={curb.name}
                key={index}
                index={index}
                isOpen={isOpen[index]}
                setIsOpen={updateIsOpen}
                handleEdit={() => handleEdit(curb)}
                handleDelete={() => handleDelete(curb)}
                removeItem={removeItem}
              />
            ))}
          </div>
        </div>

        <button
          onClick={addNewCurb}
          className="fixed items-center justify-center rounded-l-full z-10 bottom-3 -end-[6rem] pr-1 pl-4 py-2.5 bg-primary cursor-pointer shadow-lg text-white flex hover:end-0"
        >
          <i className="mgc_add_line text-lg pr-2"></i>
          <p>Add Curb List</p>
        </button>

        <AddCurbModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
        />

        <EditCurbModal
          isOpen={isEditModalOpen}
          curb={selectedCurb}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={handleEditSubmit}
        />

        <DeleteCurbModal
          isOpen={isDeleteModalOpen}
          curbName={selectedCurb?.name || ""}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteConfirm}
        />
      </div>
    </DragDropContext>
  );
};

export default CurbList;
