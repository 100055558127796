import React from "react";

import { GET_MARKS } from "../../graphql/queries/mark";
import { useQuery } from "@apollo/client";
import MarkCardStructure from "./MarkCardStructure";
import Skeleton from "../UIElements/Skeleton";

const Mark: React.FC = () => {

  // QUERY
  const {
    data: markData,
    loading: markLoading,
    error: markError,
  } = useQuery(GET_MARKS);

  if (markLoading) return <Skeleton />;

  if (markError)
    return <p>Error loading sections: {markError.message}</p>;

  return (
    <div className="bg-white p-4 rounded-lg">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl">Mark</h2>
      </div>
      <div className="space-y-4 bg-gray-100 rounded-xl overflow-hidden px-3 py-4">
        <MarkCardStructure
          marks={markData.marks}
          type="position"
          category="individual"
        />
        <MarkCardStructure
          marks={markData.marks}
          type="position"
          category="group"
        />
        <MarkCardStructure
          marks={markData.marks}
          type="position"
          category="kulliyyah"
        />
        <MarkCardStructure
          marks={markData.marks}
          type="grade"
          category="normal"
        />
        <MarkCardStructure
          marks={markData.marks}
          type="grade"
          category="starred"
        />
      </div>
    </div>
  );
};

export default Mark;
