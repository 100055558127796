import { gql } from "@apollo/client";

export const GET_DOWNLOADS = gql`
  query Downloads {
    downloads {
      id
      title
      file
    }
  }
`;
