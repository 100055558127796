import { gql } from "@apollo/client";

export const GET_HOSTS = gql`
  query Hosts {
    hosts {
      id
      name
      place
    }
  }
`;

export const GET_HOST_BY_ID = gql`
  query Host($id: ID!) {
    host(id: $id) {
      id
      name
      place
    }
  }
`;

