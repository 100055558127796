import { gql } from "@apollo/client";

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification(
    $id: ID!
    $input: UpdateNotificationInput!
  ) {
    updateNotification(id: $id, input: $input) {
      id
    }
  }
`;
