import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { GET_USERS } from "../../graphql/queries/admin";
import { DELETE_USER } from "../../graphql/mutations/admin";
import toast from "react-hot-toast";

interface AdminProps {
  data: {
    users: {
      id: string;
      username: string;
      role: {
        id: string;
        name: string;
      };
    }[];
  };
  onEdit: (data: any) => void;
}

const ShowAdmin: React.FC<AdminProps> = ({ data, onEdit }) => {
  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [{ query: GET_USERS }],
  });
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleDelete = (id: string) => {
    deleteUser({ variables: { id } })
      .then(() => toast.success("Admin deleted successfully"))
      .catch((err) => console.error("Failed to delete Admin:", err));
  };

  const filteredAdmins = data?.users.filter((admin: any) =>
    admin.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="card">
      <div className="card-header">
        <div className="flex justify-between items-center">
          <h4 className="card-title">Admins</h4>
        </div>
      </div>
      <div className="p-6">
        {/* Search Bar */}
        <div className="overflow-hidden rounded-xl bg-white transition-all dark:bg-slate-800 mb-2">
          <div className="relative">
            <div className="pointer-events-none absolute top-3.5 start-4 text-gray-900 text-opacity-40 dark:text-gray-200">
              <i className="mgc_search_line text-xl"></i>
            </div>
            <input
              type="search"
              className="h-12 w-full border-0 bg-gray-100 ps-11 pe-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {/* Display Roles */}
        {filteredAdmins?.map((admin: any) => (
          <div
            key={admin.id}
            className="flex items-center justify-between border border-gray-500 border-dashed p-6 mb-2"
          >
            <p className="text-gray-800 text-base font-medium inline-block">
              {admin.username}
              {"   "}
              <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-primary/25 text-sky-800">
                {admin.role?.name}
              </span>
            </p>
            <div className="flex">
              <div
                className="flex items-center cursor-pointer py-2 px-4 text-sm rounded text-white bg-primary hover:bg-slate-100 hover:text-slate-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 me-2"
                onClick={() => onEdit(admin)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-edit-3 w-4 h-4"
                >
                  <path d="M12 20h9"></path>
                  <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                </svg>
              </div>
              <div
                className="flex items-center cursor-pointer py-2 px-4 text-sm rounded text-white bg-danger hover:bg-slate-100 hover:text-slate-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                onClick={() => handleDelete(admin.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-trash w-4"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                </svg>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowAdmin;
