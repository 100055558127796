import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import ProgramForm from "../../components/programs/ProgramForm";
import { GET_PROGRAM_BY_ID } from "../../graphql/queries/program";
import PageTitle from "../../components/PageTitle";

const EditProgram = () => {
  const { programId } = useParams<{ programId: string }>(); // Get the program ID from the URL

  const { data, loading, error } = useQuery(GET_PROGRAM_BY_ID, {
    variables: { id: programId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading program details</p>;

  return (
    <>
      <PageTitle pagetitle={"Edit Programs"} subtitle={"Programs"} />

      <ProgramForm initialProgramData={data.program} />
    </>
  );
};

export default EditProgram;
