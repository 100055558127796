import React, { useState } from "react";
import Private from "../../../context/Private";
import AddCompatition from "./AddCompatition";
import AddSelected from "./AddSelected";

interface NavProps {
  programId: string;
}

const Nav: React.FC<NavProps> = ({ programId }) => {
  const [compatitionModel, setCompatitionModel] = useState<boolean>(false);
  const [selectedParticipationModal, setSelectedParticipationModal] =
    useState<boolean>(false);

  return (
    <div className="flex flex-col justify-end sm:flex-row gap-2 mb-7">
      <div>
        <div className="flex flex-grow relative">
          <div className="pointer-events-none absolute top-2 left-4 text-gray-900 text-opacity-40 dark:text-gray-200">
            <i className="mgc_search_line text-xl"></i>
          </div>
          <input
            type="search"
            className="h-11 w-full border rounded-lg bg-transparent pl-11 pr-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
            placeholder="Search Candidates..."
            // Uncomment and implement these handlers as needed
            // value={searchTerm}
            // onChange={(e) => {
            //   setSearchTerm(e.target.value);
            //   setPage(0);
            // }}
          />
        </div>
      </div>
      <Private
        permissions={["create-candidate"]}
        element={
          <div
            onClick={() => setCompatitionModel(!compatitionModel)}
            className="btn bg-green-300 inline-flex items-center justify-center text-sm font-medium h-11 text-green-700 hover:text-white hover:bg-green-600"
          >
            <i className="mgc_add_circle_line me-2 text-xl"></i> Add Competition
          </div>
        }
      />
      <Private
        permissions={["create-candidate"]}
        element={
          <div
            onClick={() =>
              setSelectedParticipationModal(!selectedParticipationModal)
            }
            className="btn bg-red-300 inline-flex items-center justify-center text-sm font-medium h-11 text-red-700 hover:text-white hover:bg-red-600"
          >
            <i className="mgc_add_circle_line me-2 text-xl"></i> Add Selected
            Participant
          </div>
        }
      />
      <AddCompatition
        isOpen={compatitionModel}
        setIsOpen={setCompatitionModel}
        programId={programId}
      />

      <AddSelected
        isOpen={selectedParticipationModal}
        setIsOpen={setSelectedParticipationModal}
        programId={programId}
      />
    </div>
  );
};

export default Nav;
