import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CATEGORIES } from "../../graphql/queries/category";
import CardStructure from "./CardStructure";
import Card from "./Card";
import Input from "../ui-elements/Input";
import Modal from "../UIElements/Model";
import toast from "react-hot-toast";
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from "../../graphql/mutations/category";
import Skeleton from "../UIElements/Skeleton";

const Category: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [chessNo, setChessNo] = useState<string>("");
  const [editCategory, setEditCategory] = useState<any>(null);
  const [colorCode, setColorCode] = useState("");
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<string>("");

  useEffect(() => {
    if (editCategory) {
      setName(editCategory.name);
      setChessNo(editCategory.chest_no_series);
      setColorCode(editCategory.ColorCode);
    }
  }, [editCategory]);

  const { data, loading, error, refetch } = useQuery(GET_CATEGORIES);

  // MUTATION
  const [createCategory, { loading: createLoading }] =
    useMutation(CREATE_CATEGORY);
  const [updateCategory, { loading: updateLoading }] =
    useMutation(UPDATE_CATEGORY);
  const [deleteCategory, { loading: deleteCategoryLoading }] =
    useMutation(DELETE_CATEGORY);

  if (loading) return <Skeleton />;

  if (error) return <p>Error loading Categories </p>;

  const handleAddCategory = async () => {
    setEditCategory(null);
    clearInput();
    setIsOpen(true);
  };

  const handleEditCategory = async (item: any) => {
    setEditCategory(item);
    setName(item.name);
    setChessNo(item.chest_no_series);
    setColorCode(item.colorCode);
    setIsOpen(true);
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setDeleteCategoryId(id);
  };

  const handleDelete = async () => {
    try {
      await deleteCategory({ variables: { id: deleteCategoryId } });
      toast.success("Category deleted successfully!");
      setDeleteModel(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error("Failed to delete the category", error);
      toast.error("Failed to delete the category");
    }
  };

  const clearInput = () => {
    setName("");
    setChessNo("");
    setColorCode("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name.trim() || !chessNo.trim() || !colorCode.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      if (editCategory) {
        await updateCategory({
          variables: {
            id: editCategory.id,
            name: name.trim(),
            chest_no_series: chessNo.trim(),
            colorCode: colorCode.trim(),
          },
        });
        toast.success("Category updated successfully!");
      } else {
        await createCategory({
          variables: {
            name: name.trim(),
            chest_no_series: chessNo.trim(),
            colorCode: colorCode.trim(),
          },
        });
        toast.success("Category created successfully!");
      }

      setIsOpen(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error(
        editCategory
          ? `Failed to update the category:`
          : `Failed to create the category`,
        error
      );
      toast.error(
        editCategory
          ? `Failed to update the category:`
          : `Failed to create the category`
      );
    }
  };

  return (
    <>
      <CardStructure
        Heading="Category"
        addButton="Add"
        addButtonOnCLick={handleAddCategory}
        data={data.categories}
        renderCard={(item: any) => (
          <Card
            item={item}
            title={item.name}
            subTitle={item.chest_no_series}
            handleEditButton={handleEditCategory}
            handleDeleteButton={handleDeleteConfirm}
            textClass="text-sm"
          />
        )}
      />
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title={editCategory ? "Edit Category" : "Add Category"}
        handleSubmit={handleSubmit}
        loading={editCategory ? updateLoading : createLoading}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <Input
            placeholder="Enter Name"
            label="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            placeholder="Enter Chess No Series"
            label="Chess No Series"
            value={chessNo}
            onChange={(e) => setChessNo(e.target.value)}
          />
          <Input
            placeholder="Enter Color Code"
            label="Color Code"
            value={colorCode}
            onChange={(e) => setColorCode(e.target.value)}
          />
        </form>
      </Modal>
      <Modal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        title="Delete Confirmation"
        handleSubmit={handleDelete}
        loading={deleteCategoryLoading}
        danger={true}
        buttonText="Delete"
      >
        <p className="text-center bg-red-100 text-red-800 rounded-md border border-red-600 p-3">
          Are you sure want to delete this category?
        </p>
      </Modal>
    </>
  );
};

export default Category;
