import React from 'react';

interface MessageProps {
    time: string;
    content: string;
    isCurrentUser?: boolean;
}

const MessageBubble: React.FC<MessageProps> = ({
    time,
    content,
    isCurrentUser,
}) => (
    <div className={`flex ${isCurrentUser ? "justify-end" : "justify-start"}`}>
        {/* {!isCurrentUser && profileImg && (
            <img src={profileImg} alt={sender} className="w-8 h-8 rounded-full mr-2" />
        )} */}
        <div>
            <div className="text-xs text-gray-500 m-1">{time}</div>
            <div
                className={`${isCurrentUser ? "bg-blue-600 text-white" : "bg-white text-black border border-gray-300"
                    } p-3 rounded-lg max-w-md`}
            >
                <p>{content}</p>
            </div>
        </div>
    </div>
);

export default MessageBubble;