import React, { Key, useState } from "react";
import Judge from "./Judge";

interface IJudges {
  id: string;
  name: string;
  host: string;
  email: string;
  phoneNumber: string;
}

interface Program {
  id: string;
  name: string;
  skill: {
    id: string;
    name: string;
  }
  category: {
    id: string;
    name: string;
  };
  eliminationJudge: IJudges[];
  eliminationStartingTime: string;
  finalDate: string;
  eliminationDate: string;
  eliminationVenue: {
    id: string;
    name: string;
  };
  finalJudge: IJudges[];
  finalStartingTime: string;
  finalVenue: {
    id: string;
    name: string;
  };
  programCategory: {
    id: string;
    name: string;
  }
}

interface JudgeCardProps {
  programData: Program;
  // onEdit: (judges: IJudges) => void;
  // onView: (judges: IJudges) => void;
  // isDropdownOpen: boolean,
  // toggleDropdown: (id: string) => void,
  // refetch: any;
  handleAddJudge: (program: Program) => void;
  removeJudge: ((programId: string, judgeId: string, round: string) => any);
}



const JudgeCard: React.FC<JudgeCardProps> = ({ programData, handleAddJudge, removeJudge
  // onEdit, onView, refetch, isDropdownOpen, toggleDropdown 
}) => {
  const [isEliminationSwitch, setIsEliminationSwitch] = useState(true);
  const toggleRoundSwitch = ((bool: boolean) => {
    setIsEliminationSwitch(bool)
  })


  return (
    <div className="bg-white p-4 rounded-3xl shadow-md w-full max-w-md flex flex-col justify-between">
      {/* <i
        className="mgc_more_2_fill text-xl text-black cursor-pointer shadow-gray-400 shadow-sm px-2 py-1 bg-transparent rounded-full"
        onClick={() => toggleDropdown(programData.id)} // Toggle only the dropdown for this card
      ></i>
      {isDropdownOpen && (
        <div className="absolute top-12 right-4 bg-white shadow-sm rounded-md w-32 z-10">
          <ul>
            <li>
              <button onClick={() => onView(programData)} className="block w-full px-4 py-2 text-left hover:bg-gray-100">
                View
              </button>
            </li>
            <li>
              <button onClick={() => onEdit(programData)} className="block w-full px-4 py-2 text-left hover:bg-gray-100">
                Edit
              </button>
            </li>
            <li>
              <button
                // onClick={() => handleDelete(programData.id)}
                className="w-full px-4 py-2 text-left text-red-500 hover:bg-gray-100"
              >
                Delete
              </button>
            </li>
          </ul>
        </div>
      )} */}
      <div className="flex justify-between items-start mt-1">
        <h3 className="text-lg font-semibold text-gray-800">{programData?.name}</h3>



        <div className="flex flex-col items-end" >
          <span className="text-gray-500 text-sm font-medium">{programData?.category?.name}</span>
          <div className="inline-flex rounded-md shadow-inner mt-1" role="group">

            <button type="button" onClick={() => { toggleRoundSwitch(true) }} className={`px-2 py-1 text-xs font-medium rounded-s-lg  ${isEliminationSwitch ? "z-10 bg-gray-200 text-black dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:bg-gray-700" : "text-gray-600 bg-transparent border border-gray-200 hover:bg-gray-200 hover:text-black"}`}>
              Elimination
            </button>

            <button type="button" onClick={() => { toggleRoundSwitch(false) }} className={`px-2 py-1 text-xs font-medium rounded-e-lg ${isEliminationSwitch ? "text-gray-600 bg-transparent border border-gray-200 hover:bg-gray-200 hover:text-black" : " z-10 bg-gray-200 text-black dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:bg-gray-700"}`}>
              Final
            </button>
          </div>
        </div>



      </div>
      {isEliminationSwitch ? (
        <div className="flex flex-col justify-start flex-1">
          {/* <h1>Eli Judge:</h1> */}
          {programData?.eliminationJudge?.map((judge?: any, index?: Key) => (
            <div className="">
              <Judge
                key={index}
                name={judge.name}
                host={judge.host}
                removeJudge={removeJudge}
                programId={programData.id}
                judgeId={judge.id}
                round='eliminationJudge'
              />
            </div>

          ))}
        </div>) : (

        <div className="flex flex-col justify-start flex-1">
          {/* <h1>Fin Judge:</h1> */}
          {programData?.finalJudge?.map((judge?: any, index?: any) => (
            <div className="">
              <Judge
                key={index}
                name={judge.name}
                host={judge.host}
                removeJudge={removeJudge}
                programId={programData.id}
                judgeId={judge.id}
                round="finalJudge"
              />
            </div>
          ))}
        </div>)}

      <div className="flex justify-between items-center mt-4">
        {isEliminationSwitch ? (
          <div className="text-gray-500 text-sm">
            <span>{programData?.eliminationVenue?.name}Eli</span>
            <span className="mx-2">•</span>
            <span>{programData?.eliminationDate}</span>
            <span className="mx-2">•</span>
            <span>{programData?.eliminationStartingTime}{programData?.skill?.name}</span>
          </div>) : (
          <div className="text-gray-500 text-sm">
            <span>{programData?.finalVenue?.name}Fin</span>
            <span className="mx-2">•</span>
            <span>{programData?.finalDate}</span>
            <span className="mx-2">•</span>
            <span>{programData?.finalStartingTime}{programData?.skill?.name}</span>
          </div>
        )}

        <button className="flex items-center text-sm font-medium text-blue-500 hover:underline" onClick={() => handleAddJudge(programData)}>
          <i className="mgc_add_circle_line mr-1"></i> Add judge
        </button>
      </div>
    </div>
  );
};

export default JudgeCard;
