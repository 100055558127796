// src/graphql/candidates.ts
import { gql } from "@apollo/client";

export const GET_CANDIDATES = gql`
  query Candidates(
    $page: Int
    $limit: Int
    $search: String
    $institutionId: ID
    $categoryId: [ID]
    $classId: ID
  ) {
    candidates(
      page: $page
      limit: $limit
      search: $search
      institutionId: $institutionId
      categoryId: $categoryId
      classId: $classId
    ) {
      candidates {
        id
        photo
        chestNo
        name
        email
        admissionNo
        gender
        dob
        section {
          id
          name
        }
        class {
          id
          name
        }
        category {
          id
          name
          colorCode
        }
        institution {
          id
          name
          shortName
        }
      }
      totalPages
      totalCandidates
    }
  }
`;

export const TOTAL_CANDIDATES = gql`
  query TotalCandidates {
    totalCandidates {
      id
      chestNo
      name
    }
  }
`;

export const GET_CANDIDATE_BY_ID = gql`
  query Candidate($id: ID!) {
    candidate(id: $id) {
      id
      photo
      chestNo
      email
      name
      section {
        id
        name
      }
      admissionNo
      class {
        name
        id
      }
      category {
        id
        name
      }
      institution {
        id
        name
      }
      dob
      gender
    }
  }
`;
export const GET_INSTITUTION_CANDIDATES = gql`
  query InstitutionCandidates {
    instituteCandidates {
      id
      photo
      chestNo
      name
      admissionNo
      dob
      gender
    }
  }
`;
