import React from "react";
import { DELETE_EVENTS } from "../../graphql/mutations/events";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

interface Events {
  id: string;
  title: string;
  description: string;
  date: string;
  photo: string;
  time: string;
  status: string;
}

interface ProgramCardProps {
  event: Events;
  onEdit: (events: Events) => void;
  onView: (events: Events) => void;
  isDropdownOpen: boolean;
  toggleDropdown: (id: string) => void;
  refetch: any;
  // formateDate: (date: string) => any;
}

const ProgramCard: React.FC<ProgramCardProps> = ({
  event,
  onEdit,
  onView,
  refetch,
  isDropdownOpen,
  toggleDropdown,
}) => {
  const [deleteEvents] = useMutation(DELETE_EVENTS);

  const handleDelete = async (id: string) => {
    toggleDropdown(id);
    if (confirm("Are you sure you want to delete the event?")) {
      const loadToast = toast.loading("Deleting....");
      try {
        await deleteEvents({ variables: { id } });
        toast.dismiss(loadToast);
        toast.success("Events deleted successfully");
        refetch();
      } catch (error) {
        console.error("Error deleting event:", error);
        toast.dismiss(loadToast);
        toast.error("Failed to delete event");
      }
    }
  };
  return (
    <div className="relative border border-gray-300 rounded-lg shadow-md bg-white">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div
          style={{ backgroundImage: "url(" + event.photo + ")" }}
          className="w-full h-36 bg-cover flex items-start justify-end p-2 relative shadow-sm shadow-gray-100"
        >
          <i
            className="mgc_more_2_fill text-xl text-black cursor-pointer shadow-gray-400 shadow-sm px-2 py-1 bg-transparent rounded-full"
            onClick={() => toggleDropdown(event.id)} // Toggle only the dropdown for this card
          ></i>
          {isDropdownOpen && (
            <div className="absolute top-12 right-4 bg-white shadow-sm rounded-md w-32 z-10">
              <ul>
                <li>
                  <button
                    onClick={() => onView(event)}
                    className="block w-full px-4 py-2 text-left hover:bg-gray-100"
                  >
                    View
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => onEdit(event)}
                    className="block w-full px-4 py-2 text-left hover:bg-gray-100"
                  >
                    Edit
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => handleDelete(event.id)}
                    className="w-full px-4 py-2 text-left text-red-500 hover:bg-gray-100"
                  >
                    Delete
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="px-4 py-2">
          <h3 className="font-semibold text-gray-800 text-sm">{event.title}</h3>
          <div className="text-gray-500 text-xs flex items-center gap-1">
            <i className="mgc_calendar_line text-lg mb-0.5"></i>
            <p>
              {event.date
                ? new Date(Number(event.date)).toLocaleDateString()
                : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramCard;
