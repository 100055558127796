import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/PageTitle';
import SubmitProposal from '../../components/prop-sub/SubmitProposal';
import ProgramCard from '../../components/prop-sub/ProgramCard';
import { GET_PROPOSAL_SUBMISSION } from '../../graphql/queries/propSub';
import { DELETE_PROPOSAL_SUBMISSION } from '../../graphql/mutations/propSub';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';

// Define an interface for the ProposalSubmission type
interface ProposalSubmission {
    id: string;
    proposalText: string;
    program: {
        id: string;
        name: string;
        category: {
            name:string;
            id:string;
        }
    };
    participation: {
        id: string;
        // candidate: {
        //     name:string;
        //     id:string;
        // }
    };
    institution: {
        id: string;
        name: string;
    };
    file: string;
    link: string;
    status: string;
    remarks: string;
}

const PropSub: React.FC = () => {
    const [proposalSubmissions, setProposalSubmissions] = useState<ProposalSubmission[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProposal, setSelectedProposal] = useState<ProposalSubmission | null>(null); // State to hold the selected proposal for editing
    const { data, loading, error, refetch } = useQuery(GET_PROPOSAL_SUBMISSION);
    const [deleteProposalSubmission] = useMutation(DELETE_PROPOSAL_SUBMISSION);

    // Load data into state
    useEffect(() => {
        if (data && data.proposalSubmissions) {
            setProposalSubmissions(data.proposalSubmissions);
        }
    }, [data]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // Filtered list of proposals based on search query
    const filteredProposalSubmissions = proposalSubmissions.filter(proposal =>
        proposal?.program?.name?.toLowerCase().includes(searchQuery.toLowerCase()) || proposal?.proposalText?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Handle editing an proposal
    const handleEdit = (proposal: ProposalSubmission) => {
        setSelectedProposal(proposal); // Set the selected proposal to edit
    };

    // Handle deletion of an proposal
    const handleDelete = async (id: string) => {
        try {
            if (confirm('Are you sure you want to delete the Submission?')) {
                await deleteProposalSubmission({ variables: { id } });
                setProposalSubmissions(proposalSubmissions.filter(proposal => proposal.id !== id));
                toast.success('Proposal deleted successfully');
            }
        } catch (error) {
            console.error('Error deleting proposal Submission:', error);
        }
    };

    // Close edit form and reset selected proposal
    const handleSave = () => {
        setSelectedProposal(null); // Clear the selected proposal after saving
        if (data && data.proposalSubmissions) {
            setProposalSubmissions(data.proposalSubmissions); // Reload the proposal Submissions after saving
        }
    };

    // Handle cancel action to reset selected proposal
    const handleCancel = () => {
        setSelectedProposal(null);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            <PageTitle pagetitle="Proposal Submission" subtitle="Submission" />
            <div className="w-full">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* SubmitProposal component for both Submiting and editing proposals */}
                    <SubmitProposal
                        selectedProposal={selectedProposal} // Passing selected proposal if editing
                        onSave={handleSave} // Handle save action
                        onCancel={handleCancel} // Handle cancel action
                        refetch = {refetch}
                    />
                    <div className="p-6 bg-white dark:bg-slate-800 rounded-lg col-span-2">
                        <div className="flex justify-between align-center">
                            <h2 className="text-base font-medium mb-4">Submited Proposals</h2>
                            <h2 className="text-sm font-medium mb-4">
                                <a className="cursor-pointer" onClick={() => setProposalSubmissions(data.proposalSubmissions)}>All</a> |
                                <a className="cursor-pointer" onClick={() => setProposalSubmissions(proposalSubmissions.filter(proposal => proposal.institution.id === 'yourInstitutionId'))}>Your Institution</a>
                            </h2>
                        </div>
                        <div className="flex justify-between proposals-center mb-4">
                            <input
                                type="text"
                                placeholder="Search submited proposals..."
                                className="form-input"
                                value={searchQuery}
                                onChange={handleSearch}
                            />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            {filteredProposalSubmissions.map((proposal) => (
                                <ProgramCard
                                    key={proposal.id} // Ensure a unique key prop
                                    proposal={proposal} // Pass proposal to ProgramCard
                                    onEdit={() => handleEdit(proposal)} // Handle edit action
                                    onDelete={() => handleDelete(proposal.id)} // Handle delete action
                                    refetch = {refetch}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PropSub;
