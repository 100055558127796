import { gql } from "@apollo/client";

export const CREATE_HOST = gql`
  mutation CreateHost($name: String!, $place: String!) {
    createHost(input: { name: $name, place: $place }) {
      id
      name
    }
  }
`;

export const UPDATE_HOST = gql`
  mutation UpdateHost($id: ID!, $name: String!, $place: String!) {
    updateHost(id: $id, input: { name: $name, place: $place }) {
      id
      name
    }
  }
`;

export const DELETE_HOST = gql`
  mutation DeleteHost($id: ID!) {
    deleteHost(id: $id)
  }
`;
