import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Functions from "./pages/settings/Functions";
import Role from "./pages/admin/Role";
import Admin from "./pages/admin/Admin";
import { useAuth } from "./context/AuthContext";
import Private from "./context/Private";
import General from "./pages/settings/General";
import Candidates from "./pages/candidates/Candidates";
import AddCandidate from "./pages/candidates/AddCandidate";
import Layout from "./Layout";
import Institution from "./pages/institution/Institution";
import Programs from "./pages/programs/Programs";
import AddProgram from "./pages/programs/AddProgram";
import Program from "./pages/programs/Program";
import CurbList from "./pages/curbList/CurbList";
import ItemReg from "./pages/item-reg/ItemReg";
import PropSub from "./pages/prop-sub/PropSub";
import Profile from "./pages/profile/Profile";
import EditProgram from "./pages/programs/EditProgram";
import Events from "./pages/events/Events";
import Judgement from "./pages/judgement/Judgement";
import Gallery from "./pages/gallery/Gallery";
import News from "./pages/news/News";
import Schedule from "./pages/schedule/Schedule";
import Notifications from "./pages/notifications/Notifications";
import Downloads from "./pages/downloads/Downloads";
// import SupportChat from "./components/suppport/SupportChat";
import SupportPage from "./pages/support/SupportPage";
import CompareSchedules from "./pages/schedule/CompareSchedules";
import Participants from "./pages/participants/Participant";
import Debate from "./pages/result/debate/Debate";

const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route
        path="/login"
        element={isAuthenticated ? <Navigate to="/" /> : <Login />}
      />
      <Route
        path="/"
        element={
          <Private
            permissions={["dashboard"]}
            route
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/institution"
        element={
          <Private
            route
            permissions={["institution"]}
            element={
              <Layout>
                <Institution />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/institution/:id"
        element={
          <Private
            route
            permissions={["show-institutions"]}
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/candidates"
        element={
          <Private
            route
            permissions={["candidates"]}
            element={
              <Layout>
                <Candidates />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/candidates/add"
        element={
          <Private
            route
            permissions={["create-candidate"]}
            element={
              <Layout>
                <AddCandidate />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/candidates/edit/:id"
        element={
          <Private
            route
            permissions={["update-candidate"]}
            element={
              <Layout>
                <AddCandidate isEdit={true} />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/programs"
        element={
          <Private
            permissions={["programs"]}
            route
            element={
              <Layout>
                <Programs />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/programs/add"
        element={
          <Private
            route
            permissions={["create-program"]}
            element={
              <Layout>
                <AddProgram />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/programs/edit/:programId"
        element={
          <Private
            route
            permissions={["update-program"]}
            element={
              <Layout>
                <EditProgram />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/programs/:programId"
        element={
          <Private
            permissions={["show-program"]}
            route
            element={
              <Layout>
                <Program />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/curb-list"
        element={
          <Private
            route
            permissions={["curb-list"]}
            element={
              <Layout>
                <CurbList />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/notifications"
        element={
          <Private
            permissions={["notifications"]}
            route
            element={
              <Layout>
                <Notifications />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/downloads"
        element={
          <Private
            permissions={["downloads"]}
            route
            element={
              <Layout>
                <Downloads />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/item-registration"
        element={
          <Private
            permissions={["item-registration"]}
            route
            element={
              <Layout>
                <ItemReg />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/events"
        element={
          <Private
            permissions={["events"]}
            route
            element={
              <Layout>
                <Events />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/judgement"
        element={
          <Private
            permissions={["judgement"]}
            route
            element={
              <Layout>
                <Judgement />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/schedule"
        element={
          <Private
            permissions={["schedule"]}
            route
            element={
              <Layout>
                <Schedule />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/proposal-submission"
        element={
          <Private
            permissions={["proposal-submission"]}
            route
            element={
              <Layout>
                <PropSub />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/events"
        element={
          <Private
            permissions={["events"]}
            route
            element={
              <Layout>
                <Events />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/news"
        element={
          <Private
            permissions={["news"]}
            route
            element={
              <Layout>
                <News />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/gallery"
        element={
          <Private
            permissions={["gallery"]}
            route
            element={
              <Layout>
                <Gallery />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/profile"
        element={
          <Private
            permissions={["profile"]}
            route
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/role"
        element={
          <Private
            roles={["administration"]}
            route
            element={
              <Layout>
                <Role />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/admins"
        element={
          <Private
            roles={["administration"]}
            route
            element={
              <Layout>
                <Admin />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/general"
        element={
          <Private
            roles={["administration"]}
            route
            element={
              <Layout>
                <General />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/functions"
        element={
          <Private
            roles={["administration"]}
            route
            element={
              <Layout>
                <Functions />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/support"
        element={
          <Private
            permissions={["support"]}
            route
            element={
              <Layout>
                <SupportPage />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/compare-schedules"
        element={
          <Private
            permissions={["compare-schedules"]}
            route
            element={
              <Layout>
                <CompareSchedules />
              </Layout>
            }
          />
        }
      />
      <Route
        path="/participants-count"
        element={
          <Private
            permissions={["participants-count"]}
            route
            element={
              <Layout>
                <Participants />
              </Layout>
            }
          />
        }
      />

      <Route
        path="/result/debate"
        element={
          <Private
            permissions={["debate-result"]}
            route
            element={
              <Layout>
                <Debate />
              </Layout>
            }
          />
        }
      />
    </Routes>
  );
};

export default AppRoutes;
