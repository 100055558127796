import { useQuery, gql } from "@apollo/client";
import { ApexOptions } from "apexcharts";
import React from "react";
import Chart from "react-apexcharts";
import {
  PROGRAMS_HAVING,
  PROGRAMS_HAVING_CATEGORIES,
} from "../../graphql/queries/program";

const DashboardStatistics: React.FC = () => {
  const GET_CATEGORY_SUMMARIES = gql`
    query GetCategorySummaries {
      getCategorySummaries {
        category {
          name
        }
        candidateCount
        programCount
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_CATEGORY_SUMMARIES);

  const {
    loading: programHavingLoading,
    error: programHavingError,
    data: programsHaving,
  } = useQuery(PROGRAMS_HAVING);
  const {
    loading: programHavingCategoriesLoading,
    error: programHavingCategoriesError,
    // data: programsHavingCategories,
  } = useQuery(PROGRAMS_HAVING_CATEGORIES);



  if (programHavingLoading || loading) return <p>Loading...</p>;
  if (programHavingCategoriesLoading) return <p>Loading...</p>;
  if (programHavingError || error)
    return <p>Error: {programHavingError?.message}</p>;
  if (programHavingCategoriesError)
    return <p>Error: {programHavingCategoriesError?.message}</p>;

  const programsHavingLabels = programsHaving.programsHaving.map(
    (program: any) => program.categoryName
  );
  const programsSeries = programsHaving.programsHaving.map(
    (program: any) => program.count
  );

  const chartOptions: { options: ApexOptions; series: number[] } = {
    options: {
      labels: programsHavingLabels, // Dynamic labels from the data
      legend: {
        position: "bottom" as "bottom", // Ensure correct literal type
      },
    },
    series: programsSeries, // Dynamic series (counts) from the data
  };

  // const projectStatisticsOptions: { options: ApexOptions; series: any[] } = {
  //   options: {
  //     labels: programsHavingCategories?.programsHavingWithCategories?.map(
  //       (item: any) => item.category
  //     ),
  //     legend: {
  //       position: "bottom" as "bottom", // Ensure correct literal type
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     plotOptions: {
  //       bar: {
  //         columnWidth: "50%",
  //       },
  //     },
  //     stroke: {
  //       show: true,
  //       width: 2,
  //       colors: ["#fff"],
  //     },
  //   },
  //   series: [
  //     {
  //       name: "Final at DH",
  //       data: programsHavingCategories?.programsHavingWithCategories?.map(
  //         (item: any) => item.finalAtDH
  //       ),
  //     },
  //     {
  //       name: "Elimination at UG",
  //       data: programsHavingCategories?.programsHavingWithCategories?.map(
  //         (item: any) => item.eliminationAtUG
  //       ),
  //     },
  //     {
  //       name: "Off-Stage at Cluster",
  //       data: programsHavingCategories?.programsHavingWithCategories?.map(
  //         (item: any) => item.offStageAtCluster
  //       ),
  //     },
  //   ],
  // };

  return (
    <div className="grid lg:grid-cols-3 gap-6">
      {/* Monthly Target Card */}
      <div className="col-span-1 h-full">
        <div className="card h-full">
          <div className="p-6">
            <h4 className="card-title">Programs having</h4>
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="donut"
              height="300px"
            />
          </div>
        </div>
      </div>
      <div className="lg:col-span-2">
        <div className="card h-full">
          <div className="p-6">
            <div className="flex justify-between items-center">
              <h4 className="card-title">Category Statistics</h4>
            </div>
            <Chart
              options={{
                chart: {
                  type: "bar",
                },
                xaxis: {
                  categories: data.getCategorySummaries.map(
                    (summary: any) => summary.category.name
                  ),
                },
                title: {
                  text: "Programs and Candidates by Category",
                  align: "center",
                },
                legend: {
                  position: "bottom",
                },
              }}
              series={[
                {
                  name: "Candidates",
                  data: data.getCategorySummaries.map(
                    (summary: any) => summary.candidateCount
                  ),
                },
                {
                  name: "Programs",
                  data: data.getCategorySummaries.map(
                    (summary: any) => summary.programCount
                  ),
                },
              ]}
              type="bar"
              height="300px"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardStatistics;
