import React from "react";

interface JudgeProps {
  name: string;
  host: string;
  removeJudge : ((programId: string, judgeId: string, round: string)=>any);
  programId: string;
  judgeId: string;
  round: string;
}

const Judge: React.FC<JudgeProps> = ({ name, host, removeJudge, programId, judgeId, round }) => {
  
  return (
    <div className="flex justify-between items-center p-2 bg-gray-100 rounded-lg mt-2">
      <div className="flex items-center space-x-3">
        <img
          src="https://via.placeholder.com/50"
          alt={name}
          className="w-9 h-9 rounded-full"
        />
        <div>
          <h4 className="text-sm font-medium text-gray-800">{name}</h4>
          <p className="text-xs text-gray-500">{host}</p>
        </div>
      </div>
      <i className="mgc_close_line text-md border-2 hover:border-red-300 hover:text-white focus:border-red-300 focus:text-red-400 rounded-full px-1.5 py-1.5" onClick={()=>removeJudge(programId, judgeId, round)}></i>
    </div>
  );
};

export default Judge;
