import { gql } from "@apollo/client";

export const CREATE_DOWNLOAD = gql`
  mutation CreateDownloads($input: CreateDownloadsInput) {
    createDownloads(input: $input) {
      id
      title
      file
    }
  }
`;

export const DELETE_DOWNLOAD = gql`
  mutation DeleteDownloads($id: ID!) {
    deleteDownloads(id: $id)
  }
`;

export const UPDATE_DOWNLOAD = gql`
  mutation UpdateDownloads(
    $id: ID!
    $input: UpdateDownloadsInput
  ) {
    updateDownloads(id: $id, input: $input) {
      id
    }
  }
`;
