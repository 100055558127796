import React from "react";
import CardHeader from "./CardHeader";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";

interface CardProps {
  name: string;
  status: string;
  statusType: "success" | "warning" | "error";
  concept: string;
  duration: number;
  category: {
    name: string;
    id: string;
  };
  skill: string;
  programCategory: string;
  programCode: string;
  programId: string;
  noOfCandidates: number;
  isRegistrable:boolean
}

const Card: React.FC<CardProps> = ({
  name,
  concept,
  duration,
  category,
  skill,
  programId,
  programCategory,
  programCode,
  noOfCandidates,
  isRegistrable
}) => {
  return (
    <div className="card">
      <CardHeader
        noOfCandidates={noOfCandidates}
        name={name}
        programCode={programCode}
        programId={programId}
        category={category}
        isRegistrable={isRegistrable}
      />
      <CardBody concept={concept} programId={programId} />
      <CardFooter
        duration={duration}
        category={category.name}
        skill={skill}
        programCategory={programCategory}
      />
    </div>
  );
};

export default Card;
