import { useState } from "react";
import Private from "../../context/Private";
import DetailsView from "../candidates/DetailsView";

interface Section {
  id: string;
  name: string;
}

interface Institution {
  id: string;
  name: string;
  shortName: string;
}

interface Class {
  id: string;
  name: string;
}

interface Category {
  id: string;
  name: string;
  colorCode: string;
}

interface Candidate {
  id: string;
  photo: string;
  chestNo: string;
  name: string;
  section: Section;
  institution: Institution;
  admissionNo: string;
  class: Class;
  category: Category;
  gender: string;
  email: string;
  dob: string;
}

const Candidate: React.FC<{
  photo: string;
  name: string;
  chestNo: string;
  institution: string;
  candidate: Candidate;
  participationId?: string;
  edit?: boolean;
  handleEdit?: (edit: boolean) => void;
  handleDelete?: (id: string) => void;
  setEditId?: (id: string) => void;
}> = ({
  photo,
  name,
  chestNo,
  institution,
  participationId,
  handleDelete,
  handleEdit,
  edit,
  setEditId,
  candidate,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="flex items-center p-3">
      {/* Candidate Image */}
      <img
        src={photo}
        alt={name}
        className="inline-block h-12 w-12 bg-rose-500 dark:bg-yellow-400 object-cover rounded-full border-2 border-white dark:border-gray-700"
      />

      {/* Candidate Info */}
      <div className="flex-grow pl-2">
        <div
          className="text-md font-semibold cursor-pointer"
          onClick={() => setIsModalOpen(!isModalOpen)}
        >
          {chestNo} - {name}
        </div>
        <div className="text-sm text-gray-500">{institution}</div>
      </div>

      {handleEdit && setEditId && participationId && (
        <Private
          permissions={["update-participation"]}
          element={
            <span
              className="text-xl cursor-pointer"
              onClick={() => {
                handleEdit(!edit);
                setEditId(participationId);
              }}
            >
              <i className="mgc_pencil_line"></i>
            </span>
          }
        />
      )}

      {handleDelete && participationId && (
        <Private
          permissions={["delete-participation"]}
          element={
            <div
              onClick={() => handleDelete(participationId)}
              className="text-xl text-red-600 cursor-pointer"
            >
              <i className="mgc_delete_2_line"></i>
            </div>
          }
        />
      )}

      <DetailsView
        isModalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        candidate={candidate}
      />
    </div>
  );
};

export default Candidate;
