import { gql } from "@apollo/client";

export const GET_PARTICIPATIONS = gql`
  query GetParticipations {
    participations {
      id
      candidate {
        id
        name
        photo
        chestNo
      }
      program {
        id
        name
        isItemRegistrable
        category {
          id
          name
        }
        programCategory {
          id
          name
        }
        programCode
      }
      institution {
        id
        name
      }

      codeLetter
      is_selected
      position {
        id
        name
      }
      grade {
        id
        name
      }
      deletedAt
    }
  }
`;

export const GET_REGISTRATION_PARTICIPATIONS = gql`
  query GetitemParticipations {
    ItemRegistrableParticipations {
      id
      candidate {
        id
        name
        photo
      }
      program {
        id
        name
        isItemRegistrable
        category {
          id
          name
        }
        programCode
      }
      institution {
        id
        name
      }

      codeLetter
      is_selected
      position {
        id
        name
      }
      grade {
        id
        name
      }
      deletedAt
    }
  }
`;

export const GET_INSTITUTION_PARTICIPANTS = gql`
  query GetInstitutionParticipants($programId: ID!, $institutionId: ID!) {
    institutionParticipants(
      programId: $programId
      institutionId: $institutionId
    ) {
      id
      candidate {
        id
        name
        photo
        admissionNo
        chestNo
        category {
          id
          name
        }
        class {
          id
          name
        }
        institution {
          id
          name
        }
      }
      program {
        id
        name
      }
      institution {
        id
        name
      }

      codeLetter
      is_selected
      deletedAt
    }
  }
`;

export const GET_PROGRAM_PARTICIPANTS = gql`
  query GetProgramParticipants($programId: ID!) {
    programParticipants(programId: $programId) {
      id
      candidate {
        id
        name
        photo
        admissionNo
        chestNo
        gender
        dob
        email
        category {
          id
          name
        }
        class {
          id
          name
        }
        institution {
          id
          name
        }
      }
      program {
        id
        name
      }
      institution {
        id
        name
        shortName
      }
      codeLetter
      is_selected
      deletedAt
    }
  }
`;

export const GET_PARTICIPANTS_BY_ID = gql`
  query Participation($id: ID!) {
    participation(id: $id) {
      id
      candidate {
        id
        name
        photo
        admissionNo
        chestNo
        category {
          id
          name
        }
        class {
          id
          name
        }
        institution {
          id
          name
        }
      }
      program {
        id
        name
      }
      institution {
        id
        name
      }
      isRegistered
      codeLetter
      is_selected
      deletedAt
    }
  }
`;

export const GET_CANDIDATE_PARTICIPANTIONS = gql`
  query CandidateParticipants($candidateId: ID!) {
    candidateParticipants(candidateId: $candidateId) {
      id
      candidate {
        id
        name
      }
      program {
        id
        name
        programCode
        programCategory {
          id
          name
        }
        skill {
          id
          name
        }
      }
      is_selected
    }
  }
`;
