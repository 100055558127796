import React from "react";
import { gql, useQuery } from "@apollo/client";

const GET_CATEGORY_SUMMARIES = gql`
  query GetCategorySummaries {
    getCategorySummaries {
      category {
        name
      }
      candidateCount
      programCount
    }
  }
`;

const DashboardSummary: React.FC = () => {
  const { loading, error, data } = useQuery(GET_CATEGORY_SUMMARIES);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="col-span-1">
      <div className="card mb-6">
        <div className="px-6 pt-5 flex justify-between items-center">
          <h4 className="header-title">Category Summary</h4>
        </div>

        <div className="p-6 grid gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-1">
          {data.getCategorySummaries.map((summary: any, index: number) => {
        

            return (
              <div
                key={index}
                className="flex items-center border border-gray-200 dark:border-gray-700 rounded-lg p-4 bg-white dark:bg-gray-800 shadow-sm"
              >
                {/* Icon for each category */}
                <div className="flex-shrink-0 mr-3">
                  <div className="w-12 h-12 flex justify-center items-center rounded-full text-gray-800 bg-primary/25">
                    <i className={`mgc_check_circle_line text-2xl`}></i>
                  </div>
                </div>

                <div className="flex-grow">
                  <h5 className="font-semibold text-gray-800 dark:text-gray-200 mb-1">
                    {summary.category.name}
                  </h5>
                  <p className="text-gray-500 dark:text-gray-400">
                    Candidates: {summary.candidateCount}
                  </p>
                  <p className="text-gray-500 dark:text-gray-400">
                    Programs: {summary.programCount}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DashboardSummary;
