import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { GET_PARTICIPATIONS } from '../../graphql/queries/participation';
import { CREATE_PROPOSAL_SUBMISSION, UPDATE_PROPOSAL_SUBMISSION } from "../../graphql/mutations/propSub";
import toast from "react-hot-toast";
import UploadImage from "./UploadImage";

interface SubmitProposalProps {
    selectedProposal: any; // For editing, the selected proposal will be passed
    onSave: () => void; // Callback when saving is complete
    onCancel: () => void; // Callback when canceling the edit
    refetch: () => void;
}

const SubmitProposal: React.FC<SubmitProposalProps> = ({ selectedProposal, onSave, onCancel, refetch }) => {
    const { loading, error, data } = useQuery(GET_PARTICIPATIONS);
    
    const [createProposalSubmit] = useMutation(CREATE_PROPOSAL_SUBMISSION);
    const [updateProposalSubmit] = useMutation(UPDATE_PROPOSAL_SUBMISSION);

    const [formData, setFormData] = useState({
        proposalText: '',
        institution: '',
        program: '',
        link: '',
        participation: '',
        file: null,
        remarks: ''
    });

    const [loadingFile, setLoadingFile] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [filteredCandidates, setFilteredCandidates] = useState<any[]>([]); // To store candidates based on program
    const [selectedCandidate, setSelectedCandidate] = useState<any>(null);
    const firstCandidate = filteredCandidates[0]
    // Load form data if editing an proposal

    const handleCandidateChange = (selectedProgramId: string) => {
        const participation = data?.participations
            .filter((participation: any) => participation.program.id === selectedProgramId)
        const candidatesForProgram = participation.map((participation: any) => participation.candidate);
        const participationId = participation.map((participation: any) => participation.id);

        setFormData({ ...formData, program: selectedProgramId, institution: participation[0]?.institution.id });
        setFilteredCandidates(candidatesForProgram || []);
        setSelectedCandidate(participationId[0]); // Set the first candidate by default

    };


    useEffect(() => {
        if (selectedProposal) {
            setFormData({
                proposalText: selectedProposal.proposalText || '',
                institution: selectedProposal.institution?.id || '',
                participation: selectedProposal.participation?.id || '',
                program: selectedProposal.program?.id || '',
                link: selectedProposal.link || '',
                file: selectedProposal.file,
                remarks: '',
            },);
            handleCandidateChange(selectedProposal.program.id)
        }


    }, [selectedProposal]);

    // Handle program change and filter candidates based on the selected program


    // Filter candidates based on the selected program

    const handleProgramChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedProgramId = e.target.value;
        handleCandidateChange(selectedProgramId)
    };

    // Handle candidate selection
    // const handleCandidateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    //     const candidateId = e.target.value;
    //     const selectedCandidate = filteredCandidates.find(cand => cand.id === candidateId);
    //     setSelectedCandidate(selectedCandidate);
    //     setFormData({ ...formData, participation: candidateId });
    // };

    const handleFileChange = (file: any | null) => {
        setFormData({ ...formData, file });
    };

    const uploadFile = async (file: File): Promise<string> => {
        const uploadFormData = new FormData();
        uploadFormData.append('file', file);
        uploadFormData.append('upload_preset', import.meta.env.VITE_UPLOAD_PRESET);

        try {
          const response = await fetch(`https://api.cloudinary.com/v1_1/${import.meta.env.VITE_CLOUD_NAME}/image/upload`, {
                method: 'POST',
                body: uploadFormData,
            });
            const data = await response.json();
            return data.secure_url;
        } catch (error) {
            console.error("Error uploading file:", error);
            throw new Error("File upload failed");
        }
    };

    const clearForm = () => {
        setFormData({
            proposalText: '',
            institution: '',
            program: '',
            link: '',
            file: null,
            participation: '',
            remarks: ''
        });
        setPreviewUrl(null);
        onCancel()
        handleCandidateChange('')
    };
    
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setLoadingFile(true);
            let fileUrl = '';

            if (formData.file) {
                fileUrl = await uploadFile(formData.file);
            }

            if (selectedProposal) {
                // Update existing proposal
                await updateProposalSubmit({
                    variables: {
                        id: selectedProposal.id,
                        input: {
                            program: formData.program,
                            proposalText: formData.proposalText,
                            file: fileUrl || selectedProposal.file,
                            link: formData.link,
                            participation: selectedCandidate,
                            institution: formData.institution,
                            status: 'PENDING',
                            remarks: '',
                        }
                    }
                });
                toast.success('Proposal updated successfully');
            } else {
                // Create new proposal
                await createProposalSubmit({
                    variables: {
                        input: {
                            program: formData.program,
                            proposalText: formData.proposalText,
                            file: fileUrl,
                            link: formData.link,
                            participation: selectedCandidate,
                            institution: formData.institution,
                            status: "PENDING"
                        }
                    }
                });
                toast.success('Proposal submission successful');
            }

            onSave();
            clearForm()
            refetch();
        } catch (err) {
            console.error('Error during submission:', err);
        } finally {
            setLoadingFile(false);
        }
    };

    

    if (loading || loadingFile) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div className="bg-white p-6 rounded-lg shadow-sm dark:bg-slate-800">
            <div className="flex justify-between flex-1 align-center">
                <h2 className="text-base font-medium mb-4">{selectedProposal ? 'Edit Proposal' : 'Submit an Proposal'}</h2>
                {selectedProposal ? <button className="bg-gray-400 text-white px-2 text-xs rounded-lg max-h-8" onClick={clearForm}>Cancel</button> : ''}
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                    <label className="block text-gray-700 text-sm mb-2">Select Program</label>
                    <select
                        id="program"
                        name="program"
                        value={formData.program}
                        onChange={handleProgramChange}
                        className="w-full p-2 border border-gray-300 rounded-md"
                    >
                        <option value="">Select Program</option>
                        {data?.participations?.map((participation: any) => (
                            <option key={participation.program.id} value={participation.program.id}>
                                {participation.program.name} | {participation.program.category.name}
                            </option>
                        ))}
                    </select>
                </div>

                {filteredCandidates.length > 0 && (
                    <div className="flex justify-start flex-1">
                        <img src={firstCandidate.photo} alt={firstCandidate.name} className="w-10 h-10 rounded-full mr-3" />
                        <div className="flex flex-col justify-between flex-1">
                            <h5 className="font-semibold">{firstCandidate.name}</h5>
                            <p>{firstCandidate.category}</p>
                        </div>

                        {/* <label className="block text-gray-700 text-sm mb-2">Select Candidate</label>
                        <select
                            id="candidate"
                            name="candidate"
                            value={selectedCandidate?.id || ''}
                            onChange={handleCandidateChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        >
                            {filteredCandidates.map(candidate => (
                                <option key={candidate.id} value={candidate.id}>
                                    {candidate.name}
                                </option>
                            ))}
                        </select> */}
                    </div>
                )}

                <div>
                    <label className="block text-gray-700 text-sm mb-2">Enter Lyrics</label>
                    <textarea
                        className="form-input w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Enter lyrics of the proposal..."
                        value={formData.proposalText}
                        onChange={(e) => setFormData({ ...formData, proposalText: e.target.value })}
                    ></textarea>
                </div>

                <div>
                    <label className="block text-gray-700 text-sm mb-2">Link</label>
                    <input
                        type="text"
                        className="form-input w-full p-2 border border-gray-300 rounded-md"
                        placeholder="Enter link of the proposal"
                        value={formData.link}
                        onChange={(e) => setFormData({ ...formData, link: e.target.value })}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium">File</label>
                    <UploadImage
                        defaultImageUrl={formData.file}
                        onFileChange={handleFileChange}
                        previewUrl={previewUrl}
                        setPreviewUrl={setPreviewUrl}
                    />
                </div>

                <div className="flex justify-between">
                    <button type="submit" className={`${selectedProposal ? "bg-blue-500" : "bg-green-500"} text-white py-2 px-4 rounded-md w-full`} >
                        {loadingFile ? "Submitting..." : selectedProposal ? "Update Proposal" : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SubmitProposal;
