import React from "react";

interface CardProps {
    participantId: string,
    chestNo: string;
    name: string,
    // photo: string,
    institution: string,
    // program: string,
    category: string,
    programCategory: string,
}

const Card: React.FC<CardProps> = ({
    participantId,
    chestNo,
    name,
    // photo,
    institution,
    // program,
    category,
    programCategory,
}) => {
    return (
        <tr key={participantId}>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {chestNo}
            </td>
            {/* <td className="whitespace-nowrap py-4 pe-3 text-sm">
                <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                        <img
                            className="inline-block h-10 w-10 bg-rose-500 dark:bg-yellow-400 rounded-full border-2 border-white dark:border-gray-700 object-cover"
                            src={photo}
                            alt={name}
                        />
                    </div>
                </div>
            </td> */}
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {name}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {institution}
            </td>
            {/* <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {program}
            </td> */}
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {category}
            </td>
            <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 dark:text-gray-200">
                {programCategory}
            </td>

        </tr>
    );
};

export default Card;
