import { gql } from "@apollo/client";

export const GET_CURB_DATA = gql`
  query {
    programCurbs {
      id
      name
      maximumProgramsCount
      programs {
        id
        name
        programCode
      }
    }
  }
`;
