import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import CandidateItem from "./CandidateItem";
import Modal from "../UIElements/Model";
import { ApolloError, useMutation } from "@apollo/client";
import { DELETE_CANDIDATE } from "../../graphql/mutations/candidates";
import toast from "react-hot-toast";
import Skeleton from "../UIElements/Skeleton";
import DetailsView from "./DetailsView";

interface Section {
  id: string;
  name: string;
}

interface Institution {
  id: string;
  name: string;
  shortName: string;
}

interface Class {
  id: string;
  name: string;
}

interface Category {
  id: string;
  name: string;
  colorCode: string;
}

interface Candidate {
  id: string;
  photo: string;
  chestNo: string;
  name: string;
  section: Section;
  institution: Institution;
  admissionNo: string;
  class: Class;
  category: Category;
  gender: string;
  email: string;
  dob: string;
}

interface CandidatesTableProps {
  candidates: Candidate[];
  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;
}

const CandidatesTable: React.FC<CandidatesTableProps> = ({
  candidates,
  refetch,
  loading,
  error,
}) => {
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const [candidateId, setCandidateId] = useState<string>("");
  const [viewCandidate, setViewCandidate] = useState<Candidate | null>(null);

  const navigate = useNavigate();

  const [deleteCandidate, { loading: deleteCandidateLoading }] =
    useMutation(DELETE_CANDIDATE);

  const handleEdit = async () => {
    navigate("/candidates/edit");
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setCandidateId(id);
  };

  const handleView = async (candidate: Candidate) => {
    setIsDetailModalOpen(true);
    setViewCandidate(candidate);
  };

  const handleDelete = async () => {
    try {
      await deleteCandidate({ variables: { id: candidateId } });
      refetch();
      toast.success("Candidate deleted successfully!");
      setDeleteModel(false);
    } catch (error) {
      console.error("Failed to delete the candidate", error);
      toast.error("Failed to delete the candidate");
    }
  };

  return (
    <>
      <div className="relative overflow-x-auto no-scrollbar">
        <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
          <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
            <tr>
              <th
                scope="col"
                className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Chest No.
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Photo
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Full Name
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Institution
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Ad. No.
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Class
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Category
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
            {loading ? (
              <td colSpan={100} className="pt-3">
                <Skeleton />
              </td>
            ) : error ? (
              <td colSpan={100} className="pt-3">
                Error fetching candidates: {error.message}
              </td>
            ) : candidates.length ? (
              candidates.map((candidate) => (
                <CandidateItem
                  key={candidate.id}
                  candidate={candidate}
                  handleEdit={handleEdit}
                  handleDeleteButton={handleDeleteConfirm}
                  handleView={handleView}
                />
              ))
            ) : (
              <tr>
                <td colSpan={100} className="text-center pt-5">
                  No Candidates
                </td>{" "}
              </tr>
            )}
          </tbody>
        </table>
        <Modal
          setIsOpen={setDeleteModel}
          isOpen={deleteModel}
          title="Delete Confirmation"
          handleSubmit={handleDelete}
          buttonText="Delete"
          danger={true}
          loading={deleteCandidateLoading}
        >
          <p className="text-center bg-red-100 text-red-800 rounded-md border border-red-600 p-3">
            Are you sure want to delete this candidate?
          </p>
        </Modal>
        <DetailsView
          isModalOpen={isDetailModalOpen}
          setModalOpen={setIsDetailModalOpen}
          candidate={viewCandidate}
        />
      </div>
    </>
  );
};

export default CandidatesTable;
