import React from "react";

interface ScheduleCardProps {
  title: string;
  category: string;
  date: string;
  venue: string;
  startTime: string;
  endTime: string;
}

const ScheduleCard: React.FC<ScheduleCardProps> = ({
  title,
  category,
  date,
  venue,
  startTime,
  endTime,
}) => {
  return (
    <div className="bg-white rounded-3xl shadow-md p-4 pb-8 w-72">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h4 className="text-lg font-bold text-gray-900">{title}</h4>
          <p className="text-xs text-gray-500">{category}</p>
        </div>
          <i className="mgc_more_2_fill text-gray-500"></i>
      </div>

      <div className="flex items-center text-gray-500 mb-2">
        <p className="text-sm">{date}</p>
        <span className="ml-2 bg-blue-100 text-blue-600 text-xs font-semibold px-2 py-0.5 rounded">
          {venue}
        </span>
      </div>

      <div className="w-full flex justify-between text-xs text-gray-500 mt-4">
        <div className="flex flex-col items-start">
          <span className="text-gray-400">Start Time</span>
          <div className="w-20 flex justify-between">
            <span className="font-bold text-gray-900">{startTime.split(" ")[0]}</span>
            <span className="text-[10px]">{startTime.split(" ")[1]}</span>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <span className="text-gray-400">End Time</span>
          <div className="w-20 flex justify-between">
            <span className="font-bold text-gray-900">
              {endTime.split(" ")[0]}{" "}
            </span>
            <span className="text-[10px]">{endTime.split(" ")[1]}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCard;
