import { gql } from "@apollo/client";

export const GET_MARKS = gql`
  query Marks {
    marks {
      id
      mark
      name
      type
      category
    }
  }
`;
