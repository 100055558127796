import { useMutation } from "@apollo/client";
import React from "react";
import { DELETE_INSTITUTION } from "../../graphql/mutations/institution";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

interface InstitutionCardProps {
  data: any;
  refetch: () => void;
  handleEdit: (institution: any) => void;
}

const InstitutionCard: React.FC<InstitutionCardProps> = ({
  data,
  refetch,
  handleEdit,
}) => {
  const [DeleteInstitution] = useMutation(DELETE_INSTITUTION);

  const handleDelete = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await DeleteInstitution({
        variables: {
          id: data.id,
        },
      });
      
      toast.success("Inistitution Deleted!");
      refetch();
    } catch (error: any) {
      const errorMessage =
        error.graphQLErrors && error.graphQLErrors[0]?.message
          ? error.graphQLErrors[0]?.message
          : "Failed to Delete Inistitution";
      console.log(error);

      toast.error(errorMessage);
    }
  };
  return (
    <div className="flex items-center">
      <img
        className="me-3 rounded-full"
        src={data.logo}
        width="40"
        alt={data.shortName}
      />
      <div className="grid grid-cols-4 items-center w-full overflow-hidden">
        <div className="col-span-3">
          <h5 className="font-semibold">
            <Link to={`${data.id}`}>
              <p className="text-gray-600 dark:text-gray-400">
                {data.shortName}
              </p>
            </Link>
          </h5>
          <div className="flex items-center gap-2 text-xs">
            <div>{data.name}</div>
          </div>
        </div>
        <div className="whitespace-nowrap py-4 text-center text-sm font-medium">
          <a onClick={() => handleEdit(data)} className="me-0.5 cursor-pointer">
            {" "}
            <i className="mgc_edit_line text-lg"></i>{" "}
          </a>
          <a onClick={handleDelete} className="ms-0.5 cursor-pointer">
            {" "}
            <i className="mgc_delete_line text-xl"></i>{" "}
          </a>
        </div>
      </div>
    </div>
  );
};

export default InstitutionCard;
