import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";


import Modal from "../UIElements/Model";
import Input from "../ui-elements/Input";
import { GET_HOSTS } from "../../graphql/queries/host";
import { CREATE_HOST, DELETE_HOST, UPDATE_HOST } from "../../graphql/mutations/host";
import toast from "react-hot-toast";
import Card from "./Card";
import CardStructure from "./CardStructure";
import Skeleton from "../UIElements/Skeleton";

const Host: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [host, setHost] = useState<string>("");
  const [place, setPlace] = useState<string>("");
  const [editHost, setEditHost] = useState<any>(null);
  const [deleteModel, setDeleteModel] = useState<boolean>(false);
  const [deleteHostId, setDeleteHostId] = useState<string>(""); 

  useEffect(() => {
    if (editHost) {
      setHost(editHost.name);
      setPlace(editHost.place);
    }
  }, [editHost]);

  // QUERY
  const {
    data: hostData,
    loading: hostLoading,
    error: hostError,
    refetch,
  } = useQuery(GET_HOSTS);

  // MUTATION
  const [createHost, { loading: createHostLoading }] =
    useMutation(CREATE_HOST);
  const [updateHost, { loading: updateHostLoading }] =
    useMutation(UPDATE_HOST);
  const [deleteHost, { loading: deleteHostLoading }] =
    useMutation(DELETE_HOST);

  if (hostLoading) return <Skeleton />;
  if (hostError) return <p>Error loading hosts: {hostError.message}</p>;

  const handleAddHost = async () => {
    setEditHost(null);
    clearInput();
    setIsOpen(true);
  };

  const handleEditHost = async (item: any) => {
    setEditHost(item);
    setHost(item.name);
    setPlace(item.place);
    setIsOpen(true);
  };

  const handleDeleteConfirm = async (id: string) => {
    setDeleteModel(true);
    setDeleteHostId(id);
  }

  const handleDelete = async() => {
    try {
      await deleteHost({ variables: {id: deleteHostId}});
      toast.success("Host deleted successfully!");
      setDeleteModel(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error("Failed to delete the host", error);
      toast.error("Failed to delete the host")
    }
  }

  const clearInput = () => {
    setHost("");
    setPlace("");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!host.trim() || !place.trim()) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      if (editHost) {
        await updateHost({
          variables: {
            id: editHost.id,
            name: host.trim(),
            place: place.trim(),
          },
        });
        toast.success("Host updated successfully!");
      } else {
        await createHost({
          variables: {
            name: host.trim(),
            place: place.trim(),
          },
        });
        toast.success("Host created successfully!");
      }

      setIsOpen(false);
      clearInput();
      refetch();
    } catch (error) {
      console.error(
        editHost ? `Failed to update the host:` : `Failed to create the host:`,
        error
      );
      toast.error(
        editHost ? `Failed to update the host:` : `Failed to create the host:`
      );
    }
  };

  return (
    <>
      <CardStructure
        Heading="Host"
        addButton="Add"
        addButtonOnCLick={handleAddHost}
        data={hostData.hosts}
        renderCard={(item: any) => (
          <Card
            item={item}
            title={item.name}
            subTitle={item.place}
            handleEditButton={handleEditHost}
            handleDeleteButton={handleDeleteConfirm}
            textClass="text-sm"
          />
        )}
      />
      <Modal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        title={editHost ? "Edit Host" : "Add Host"}
        handleSubmit={handleSubmit}
        loading={editHost ? updateHostLoading : createHostLoading}
      >
        <form className="flex flex-col gap-3" onSubmit={handleSubmit}>
          <Input
            placeholder="Enter Host Name"
            label="name"
            value={host}
            onChange={(e) => setHost(e.target.value)}
          />
          <Input
            placeholder="Enter Place"
            label="place"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
          />
        </form>
      </Modal>
      <Modal
        setIsOpen={setDeleteModel}
        isOpen={deleteModel}
        title="Delete Confirmation"
        handleSubmit={handleDelete}
        loading={deleteHostLoading}
        danger={true}
        buttonText="Delete"
      >
        <p className="text-center bg-red-100 text-red-800 rounded-md border border-red-600 p-3">Are you sure want to delete this host?</p>
      </Modal>
    </>
  );
};

export default Host;
