import React, { useState, useRef, useEffect } from "react";

const NotificationDropdown: React.FC = () => {
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Toggle notification dropdown
  const toggleNotification = () => setNotificationOpen(!isNotificationOpen);

  // Close dropdown when clicking outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setNotificationOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative md:flex hidden">
      <button
        type="button"
        data-fc-type="dropdown"
        data-fc-placement="bottom-end"
        className={`nav-link p-2 fc-dropdown ${
          isNotificationOpen ? "open" : ""
        }`}
        onClick={toggleNotification}
      >
        <span className="sr-only">View notifications</span>
        <span className="flex items-center justify-center h-6 w-6">
          <i className="mgc_notification_line text-2xl"></i>
        </span>
      </button>
      <div
        ref={dropdownRef}
        className={`fc-dropdown ${
          isNotificationOpen ? "block" : "hidden"
        } w-80 z-50 mt-2 transition-opacity duration-300 bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 rounded-lg absolute top-[44px] -left-[280px]`}
      >
        <div className="p-2 border-b border-dashed border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <h6 className="text-sm">Notification</h6>
            <a href="#" className="text-gray-500 underline">
              <small>Clear All</small>
            </a>
          </div>
        </div>
        {/* <div className="p-4 h-80 overflow-auto">
          <h5 className="text-xs text-gray-500 mb-2">Today</h5>

          <a href="#" className="block mb-4">
            <div className="card-body">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <div className="flex justify-center items-center h-9 w-9 rounded-full bg text-white bg-primary">
                    <i className="mgc_message_3_line text-lg"></i>
                  </div>
                </div>
                <div className="flex-grow truncate ms-2">
                  <h5 className="text-sm font-semibold mb-1">
                    Datacorp{" "}
                    <small className="font-normal text-gray-500 ms-1">
                      1 min ago
                    </small>
                  </h5>
                  <small className="noti-item-subtitle text-muted">
                    Caleb Flakelar commented on Admin
                  </small>
                </div>
              </div>
            </div>
          </a>


          <h5 className="text-xs text-gray-500 mb-2">Yesterday</h5>

          <a href="#" className="block mb-4">
            <div className="card-body">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <div className="flex justify-center items-center h-9 w-9 rounded-full bg-primary text-white">
                    <i className="mgc_message_1_line text-lg"></i>
                  </div>
                </div>
                <div className="flex-grow truncate ms-2">
                  <h5 className="text-sm font-semibold mb-1">Datacorp</h5>
                  <small className="noti-item-subtitle text-muted">
                    Caleb Flakelar commented on Admin
                  </small>
                </div>
              </div>
            </div>
          </a>

          <a href="#" className="block">
            <div className="card-body">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    src="assets/images/users/avatar-4.jpg"
                    className="rounded-full h-9 w-9"
                    alt=""
                  />
                </div>
                <div className="flex-grow truncate ms-2">
                  <h5 className="text-sm font-semibold mb-1">Karen Robinson</h5>
                  <small className="noti-item-subtitle text-muted">
                    Wow ! this admin looks good and awesome design
                  </small>
                </div>
              </div>
            </div>
          </a>
        </div> */}
        <a
          href="#"
          className="p-2 border-t border-dashed border-gray-200 dark:border-gray-700 block text-center text-primary underline font-semibold"
        >
          View All
        </a>
      </div>
    </div>
  );
};

export default NotificationDropdown;
