import { useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";

import PageTitle from "../../components/PageTitle";
import Card from "../../components/programs/Card";
import { GET_PROGRAMS } from "../../graphql/queries/program";
import { GET_CATEGORIES } from "../../graphql/queries/category";
import { GET_PROGRAM_CATEGORIES } from "../../graphql/queries/programCategory";
import { GET_SKILLS } from "../../graphql/queries/skill";
import Private from "../../context/Private";

const Programs: React.FC = () => {
  
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProgramCategory, setSelectedProgramCategory] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  const dropdownRef = useRef<HTMLDivElement>(null);
  
  const { data: programsData, error, loading } = useQuery(GET_PROGRAMS);
  const { data: categoryData } = useQuery(GET_CATEGORIES);
  const { data: programCategoryData } = useQuery(GET_PROGRAM_CATEGORIES);
  const { data: skillData } = useQuery(GET_SKILLS);


  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    }

    if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside);
    } else {
        document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [isOpen]);

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>{error.message}</p>;
  }

  // Filter programs based on search term and selected filters
  const filteredPrograms = programsData.programs.filter(
    (program: {
      name: string;
      category: {
        id: string;
        name: string;
      };
      programCategory: {
        id: string;
        name: string;
      };
      skill: {
        id: string;
        name: string;
      };
    }) => {
      const matchesSearch = program.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategory
        ? program?.category?.id === selectedCategory
        : true;
      const matchesProgramCategory = selectedProgramCategory
        ? program?.programCategory?.id === selectedProgramCategory
        : true;
      const matchesSkill = selectedSkill
        ? program?.skill?.id === selectedSkill
        : true;

      return (
        matchesSearch &&
        matchesCategory &&
        matchesProgramCategory &&
        matchesSkill
      );
    }
  );

  const handleDownload = () => {
    // flatten object like this {id: 1, title:'', category: ''};
    const rows = filteredPrograms.map((program: any) => ({
      programCode: program.programCode,
      name: program.name,
      concept: program.concept,
      duration: program.duration,
      skill: program.skill?.name,
      noOfCandidates: program.noOfCandidates,
      tools: program.tools,
      section: program.section?.name,
      category: program.category?.name,
      programCategory: program.programCategory?.name,
      mode: program.mode,
      type: program.type,
      curbGroup: program.curbGroup?.name,
      isRegistrable: program.isRegistrable ? "Yes" : "No",
      isItemRegistrable: program.isItemRegistrable ? "Yes" : "No",
      isProposalSubmission: program.isProposalSubmission ? "Yes" : "No",
      isStarred: program.isStarred ? "Yes" : "No",
      publishTimeElimination: program.publishTimeElimination,
      eliminationResultStatus: program.eliminationResultStatus,
      publishTimeFinal: program.publishTimeFinal,
      finalResultStatus: program.finalResultStatus,
      maxSelection: program.maxSelection,
      status: program.status,
      finalDate: program.finalDate,
      finalStartingTime: program.finalStartingTime,
      finalEndingTime: program.finalEndingTime,
      finalVenue: program.finalVenue?.name,
      eliminiationDate: program.eliminiationDate,
      eliminiationStartingTime: program.eliminiationStartingTime,
      eliminiationEndingTime: program.eliminiationEndingTime,
      eliminationVenue: program.eliminationVenue?.name,
    }));

    // create workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Programs");

    XLSX.writeFile(workbook, "Programs.xlsx", { compression: true });
  };

  return (
    <>
      <PageTitle pagetitle={"Programs"} subtitle={"Programs"} />

      {/* Responsive form controls */}
      <div className="flex flex-col sm:flex-row gap-2 mb-7">
        <div>
          <div className="flex flex-grow relative">
            <div className="pointer-events-none absolute top-3.5 left-4 text-gray-900 text-opacity-40 dark:text-gray-200">
              <i className="mgc_search_line text-xl"></i>
            </div>
            <input
              type="search"
              className="h-12 w-full border rounded-lg bg-transparent pl-11 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
              placeholder="Search Programs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="flex-grow hidden sm:block">
          <select
            required
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
          >
            <option value="">Select Category</option>
            {categoryData?.categories?.map((category: any) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex-grow hidden sm:block">
          <select
            required
            value={selectedProgramCategory}
            onChange={(e) => setSelectedProgramCategory(e.target.value)}
            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
          >
            <option value="">Select Program Category</option>
            {programCategoryData?.programCategories?.map((category: any) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div className="flex-grow hidden sm:block">
          <select
            required
            value={selectedSkill}
            onChange={(e) => setSelectedSkill(e.target.value)}
            className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
          >
            <option value="">Select Skill</option>
            {skillData?.skills?.map((skill: any) => (
              <option key={skill.id} value={skill.id}>
                {skill.name}
              </option>
            ))}
          </select>
        </div>

        <Private
          permissions={["create-program"]}
          element={
            <div>
              <Link
                to="/programs/add"
                className="btn h-full w-full bg-danger/20 inline-flex items-center justify-center text-sm font-medium text-danger hover:text-white hover:bg-danger"
              >
                <i className="mgc_add_circle_line me-3"></i> Add Program
              </Link>
            </div>
          }
        />

        <Private
          permissions={["show-programs"]}
          element={
            <div className="relative" ref={dropdownRef}>
              <div>
                <button
                  onClick={()=>setIsOpen(!isOpen)}
                  className="btn w-full h-full bg-green-300 inline-flex items-center justify-center text-sm font-medium text-green-700 hover:text-white hover:bg-green-500"
                >
                  <i className="mgc_arrow_down_circle_line me-2 text-xl"></i>{" "}
                  Download
                </button>
              </div>
              {isOpen && <div className="absolute top-full left-0 w-full mt-2 bg-white border rounded shadow-lg z-10">
                <div className="max-h-48 overflow-y-auto">
                <div onClick={handleDownload} className="px-4 py-2 cursor-pointer hover:bg-gray-500 hover:text-white">Programs</div>
                <div  className="px-4 py-2 cursor-pointer hover:bg-gray-500 hover:text-white">Participations</div>
                </div>
              </div>}
            </div>
          }
        />
      </div>

      {/* Responsive program cards */}
      <div className="flex flex-auto flex-col mt-6">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
          {filteredPrograms?.map((program: any) => (
            <Card
              programId={program.id}
              key={program.id}
              name={program.name}
              status={program.status}
              statusType={program.type}
              concept={program.concept}
              programCode={program.programCode}
              duration={program.duration}
              category={program?.category}
              noOfCandidates={program?.noOfCandidates}
              skill={program.skill?.name}
              programCategory={program.programCategory?.name}
              isRegistrable={program.isRegistrable}
            />
          ))}
        </div>

        {/* Load More button */}
        {/* <div className="text-center mt-6">
          <button
            type="button"
            className="btn bg-transparent border-gray-300 dark:border-gray-700"
          >
            <i className="mgc_loading_4_line me-2 animate-spin"></i>
            <span>Load More</span>
          </button>
        </div> */}
      </div>
    </>
  );
};

export default Programs;
