import React, { useState } from "react";
import PageTitle from "../../components/PageTitle";
import NotificationItem from "../../components/notifications/NotificationItem";
import { useMutation, useQuery } from "@apollo/client";
import { GET_NOTIFICATIONS } from "../../graphql/queries/notifications";
import {
    CREATE_NOTIFICATION,
    UPDATE_NOTIFICATION,
} from "../../graphql/mutations/notifications";

const Notifications: React.FC = () => {
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [type, setType] = useState("");
    const [role, setRole] = useState("");
    const [user, setUser] = useState("");
    const [message, setMessage] = useState("");
    const [editingId] = useState("");
    const [isPublic] = useState(false);
    const [activeTab, setActiveTab] = useState("All Notifications");

    const {
        data: getNotifications,
        refetch,
    } = useQuery(GET_NOTIFICATIONS);
    const [createNotification] = useMutation(CREATE_NOTIFICATION);
    const [updateNotification] = useMutation(UPDATE_NOTIFICATION);

    const notifications = getNotifications?.notifications || [];

    const filteredNotifications = notifications.filter(() => {
        if (activeTab === "All Notifications") return true;
        if (activeTab === "Public") {
            return true;
        }
        if (activeTab === "Private") {
            return false;
        }
        return false;
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const formData = {
            title,
            date,
            type,
            role,
            user,
            message,
            isPublic,
        };
        console.log("Form data:", formData);

        if (editingId) {
            await updateNotification({
                variables: {
                    updateNotificationId: editingId,
                    input: {
                        title,
                        description: message,
                    },
                },
            });
        } else {
            await createNotification({
                variables: {
                    input: {
                        title,
                        description: message,
                        // status,
                    },
                },
            });
        }

        refetch();
        setTitle("");
        setMessage("");
    };

    createNotification({
        variables: {
            input: {
                title,
                description: message,
            },
        },
    });

    return (
        <>
            <PageTitle pagetitle="Notifications" subtitle="Notifications" />
            <div className="grid place-items-center lg:grid-cols-5 gap-6 lg:gap-11 p-4 lg:p-6 w-full max-w-7xl mx-auto">
                <div className="bg-white p-6 lg:p-8 rounded-lg shadow-lg lg:col-span-3 w-full">
                    <h2 className="text-2xl font-bold text-center mb-6">
                        Add Notifications
                    </h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Title
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                            />
                        </div>

                        <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Date
                                </label>
                                <input
                                    type="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                                />
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Select Type
                                </label>
                                <div className="relative">
                                    <select
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 pr-10"
                                    >
                                        <option value="public">Public</option>
                                        <option value="private">Private</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Conditional rendering of role and user selection based on the type */}
                        <div
                            className={`mb-4 ${type === "private" ? "grid" : "hidden"
                                } grid-cols-1 sm:grid-cols-2 gap-4`}
                        >
                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Select Role
                                </label>
                                <div className="relative">
                                    <select
                                        value={role}
                                        onChange={(e) => setRole(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 pr-10"
                                    >
                                        <option value="">Media</option>
                                        <option value="">Institution</option>
                                    </select>
                                </div>
                            </div>

                            <div>
                                <label className="block text-gray-700 text-sm font-bold mb-2">
                                    Select User
                                </label>
                                <div className="relative">
                                    <select
                                        value={user}
                                        onChange={(e) => setUser(e.target.value)}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500 pr-10"
                                    >
                                        <option value="">User1</option>
                                        <option value="">User2</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Message
                            </label>
                            <textarea
                                placeholder="Type Message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-green-500"
                            ></textarea>
                        </div>

                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>

                <div className="w-full lg:col-span-2 h-full bg-white shadow-md rounded-lg p-4 lg:p-6">
                    <div className="flex items-center justify-between mb-4">
                        <h2 className="text-xl font-bold">Notifications</h2>
                        <input
                            type="text"
                            placeholder="Search Notifications"
                            className="border-none text-right rounded-lg py-2 px-3 text-sm outline-none focus:outline-none"
                        />
                    </div>

                    <div className="w-full flex justify-between items-center mb-4 border-b border-gray-200">
                        <div className="flex items-center border-b border-gray-200">
                            {["All Notifications", "Public", "Private"].map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => setActiveTab(tab)}
                                    className={`px-4 py-2 text-sm font-semibold focus:outline-none ${activeTab === tab
                                            ? "border-b-2 border-blue-500 text-black"
                                            : "text-gray-500"
                                        }`}
                                >
                                    {tab}
                                </button>
                            ))}
                        </div>
                    </div>

                    {filteredNotifications.map((notification: any) => (
                        <NotificationItem
                            key={notification.id}
                            title={notification.title}
                            message={notification.description}
                            timeAgo={notification.date}
                            imageUrl="via.placeholder.com/40"
                        // imageUrl={notification.imageUrl}
                        />
                    ))}

                    <div className="flex justify-center mt-4">
                        <button className="text-blue-500 font-semibold">Load more</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Notifications;
