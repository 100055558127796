import React from 'react';
import Nav from "./Nav";
import Table from "./Table";

interface DebateDetailsProps {
  program: {
    id: string;
    name: string;
    // Add any other properties you need from the program object
  };
}

const DebateDetails: React.FC<DebateDetailsProps> = ({ program }) => {
  return (
    <>
      <Nav programId={program.id}/>
      <h2 className="text-xl font-semibold my-4">Program: {program.name}</h2>
      <Table />
    </>
  );
};

export default DebateDetails;
