import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_PROGRAM_PARTICIPANTS } from "../../../graphql/queries/participation";
import TeamInput from "./TeamInput";
import Modal from "../../UIElements/Model";
import toast from "react-hot-toast";
import { UPDATE_PARTICIPATION } from "../../../graphql/mutations/participation";

// Type for the candidate data structure
interface Candidate {
  id: string;
  chestNo: string;
  name: string;
  photo: string;
  institution: {
    id: string;
    name: string;
    shortName: string;
  };
}

interface AddSelectedProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  programId: string;
  participationId?: string;
}

const AddSelected: React.FC<AddSelectedProps> = ({
  isOpen,
  setIsOpen,
  programId,
  // participationId,
}) => {
  const [teamOne, setTeamOne] = useState<(Candidate | null)[]>(
    Array(1).fill(null)
  );
  const [teamTwo, setTeamTwo] = useState<(Candidate | null)[]>(
    Array(1).fill(null)
  );
  const [teamThree, setTeamThree] = useState<(Candidate | null)[]>(
    Array(1).fill(null)
  );
  const [teamFour, setTeamFour] = useState<(Candidate | null)[]>(
    Array(1).fill(null)
  );
  const [teamOneSearch, setTeamOneSearch] = useState<string[]>([]);
  const [teamTwoSearch, setTeamTwoSearch] = useState<string[]>([]);
  const [teamThreeSearch, setTeamThreeSearch] = useState<string[]>([]);
  const [teamFourSearch, setTeamFourSearch] = useState<string[]>([]);
  const [filteredTeamOne, setFilteredTeamOne] = useState<Candidate[][]>([]);
  const [filteredTeamTwo, setFilteredTeamTwo] = useState<Candidate[][]>([]);
  const [filteredTeamThree, setFilteredTeamThree] = useState<Candidate[][]>([]);
  const [filteredTeamFour, setFilteredTeamFour] = useState<Candidate[][]>([]);
  const [loading] = useState(false);

  const clearInput = () => {
    setTeamOne(Array(1).fill(null));
    setTeamTwo(Array(1).fill(null));
    setTeamThree(Array(1).fill(null));
    setTeamFour(Array(1).fill(null));
    setTeamOneSearch([]);
    setTeamTwoSearch([]);
    setTeamThreeSearch([]);
    setTeamFourSearch([]);
  };

  const {
    data: participantsData,
    loading: participantsLoading,
    error: participantsError,
  } = useQuery(GET_PROGRAM_PARTICIPANTS, {
    variables: { programId },
    skip: !programId,
  });

  const [updateParticipation] = useMutation(UPDATE_PARTICIPATION);

  useEffect(() => {
    if (participantsData && participantsData.programParticipants) {
      const filterParticipants = (searchTerms: string[]) => {
        return searchTerms.map((term) =>
          participantsData.programParticipants.filter(
            (participant: Candidate) =>
              participant.institution.name
                .toLowerCase()
                .includes(term.toLowerCase()) ||
              participant.institution.shortName
                .toLowerCase()
                .includes(term.toLowerCase())
          )
        );
      };

      setFilteredTeamOne(filterParticipants(teamOneSearch));
      setFilteredTeamTwo(filterParticipants(teamTwoSearch));
      setFilteredTeamThree(filterParticipants(teamThreeSearch));
      setFilteredTeamFour(filterParticipants(teamFourSearch));
    }
  }, [
    participantsData,
    teamOneSearch,
    teamTwoSearch,
    teamThreeSearch,
    teamFourSearch,
  ]);

  const handleParticipantSelect = (
    participant: Candidate | null,
    index: number,
    setTeam: React.Dispatch<React.SetStateAction<(Candidate | null)[]>>
  ) => {
    setTeam((prev) =>
      prev.map((item, idx) => (idx === index ? participant : item))
    );
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Ensure both teams have at least one participant before submitting
    if (
      teamOne.filter((participant) => participant !== null).length === 0 ||
      teamTwo.filter((participant) => participant !== null).length === 0 ||
      teamThree.filter((participant) => participant !== null).length === 0 ||
      teamFour.filter((participant) => participant !== null).length === 0
    ) {
      toast.error("Four teams must have selected.");
      return;
    }

    // Proceed with the form submission logic
    try {
      const teams = [teamOne, teamTwo, teamThree, teamFour];
      for (let i = 0; i < teams.length; i++) {
        const team = teams[i];
        if (team[0]) {
          await updateParticipation({
            variables: {
              id: team[0]?.id,
              input: { is_selected: true, programId },
            },
          });
        }
      }

      toast.success("Selected Teams added successfully");
    } catch (error) {
      toast.error("Failed to add selected Teams");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title="Add Selected Participations"
      setIsOpen={setIsOpen}
      onClose={clearInput}
      handleSubmit={handleFormSubmit}
      loading={loading}
      buttonText={"Submit"}
    >
      <div className="">
        <TeamInput
          team={teamOne}
          teamName=""
          search={teamOneSearch}
          setSearch={setTeamOneSearch}
          filteredParticipants={filteredTeamOne}
          handleParticipantSelect={(participant, index) =>
            handleParticipantSelect(participant, index, setTeamOne)
          }
          loading={participantsLoading}
          error={participantsError}
        />
        <TeamInput
          team={teamTwo}
          teamName=""
          search={teamTwoSearch}
          setSearch={setTeamTwoSearch}
          filteredParticipants={filteredTeamTwo}
          handleParticipantSelect={(participant, index) =>
            handleParticipantSelect(participant, index, setTeamTwo)
          }
          loading={participantsLoading}
          error={participantsError}
        />
        <TeamInput
          team={teamThree}
          teamName=""
          search={teamThreeSearch}
          setSearch={setTeamThreeSearch}
          filteredParticipants={filteredTeamThree}
          handleParticipantSelect={(participant, index) =>
            handleParticipantSelect(participant, index, setTeamThree)
          }
          loading={participantsLoading}
          error={participantsError}
        />
        <TeamInput
          team={teamFour}
          teamName=""
          search={teamFourSearch}
          setSearch={setTeamFourSearch}
          filteredParticipants={filteredTeamFour}
          handleParticipantSelect={(participant, index) =>
            handleParticipantSelect(participant, index, setTeamFour)
          }
          loading={participantsLoading}
          error={participantsError}
        />
      </div>
    </Modal>
  );
};

export default AddSelected;
