import React from 'react';

interface NotificationItemProps {
  title: string;
  message: string;
  timeAgo: string;
  imageUrl: string;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ title, message, timeAgo, imageUrl }) => {
  return (
    <div className="flex items-start p-4 border-b border-gray-200 w-full">
      <div className="relative">  
        <img
          src={imageUrl}
          alt="Notification"
          className="w-10 h-10 rounded-full"
        />
        <span className="absolute top-0 left-0 bg-blue-500 w-3 h-3 rounded-full"></span>
      </div>
      <div className="w-full">
        <div className="ml-4 flex justify-between">
          <h4 className="font-bold text-sm">{title}</h4>
          <i className='mgc_more_2_fill'></i>
        </div>
        <div className="ml-4 flex justify-between">
          <p className="text-gray-500 text-[10px] truncate">{message}...</p>
          <div className="text-xs text-gray-400">{timeAgo}</div>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
