import { gql } from "@apollo/client";

export const GET_PROPOSAL_SUBMISSION = gql`
  query ProposalSubmissions {
    proposalSubmissions {
      id
      proposalText
      program {
        id
        name
        category{
          id
          name
        }
      }
      participation {
        id
        
      }  
      institution {
        id
        name
      }
      file
      link
      status
      remarks
    }
  }
`;
