import React, { useState, useEffect, useRef } from 'react';
import PageTitle from '../../components/PageTitle';
import ProgramCard from '../../components/item-reg/ProgramCard';
import RegisterItem from '../../components/item-reg/RegisterItem'; // Single form for both creating and editing items
import { GET_ITEM_REGISTRATIONS, SUBSCRIPTION } from '../../graphql/queries/itemReg';
import { DELETE_ITEM_REGISTRATION } from '../../graphql/mutations/itemReg';
import { useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { useAuth } from '../../context/AuthContext';
import { GET_INSTITUTIONS } from '../../graphql/queries/institution';
import { UPDATE_REGISTRATION_STATUS } from '../../graphql/mutations/participation';
import { GET_REGISTRABLE_PROGRAMS } from '../../graphql/queries/program';
import Private from '../../context/Private';
import { GET_GROUPS } from '../../graphql/queries/group';

// Define an interface for the ItemRegistration type
interface ItemRegistration {
    id: string;
    title: string;
    description: string;
    program: {
        id: string;
        name: string;
        category: {
            name: string;
            id: string;
        }
        programCode: string;
    };
    participation: {
        id: string;
        // candidate: {
        //     name:string;
        //     id:string;
        // }
    };
    institution: {
        id: string;
        name: string;
        groupId: {
            id: string;
            name: string;
        }
    };
    file: string;
    link: string;
    status: string;
    remarks: string;
    createdAt: string;
}

const ItemReg: React.FC = () => {
    const { user } = useAuth();

    const [itemRegistrations, setItemRegistrations] = useState<ItemRegistration[]>([]);
    const [searchQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState<ItemRegistration | null>(null); // State to hold the selected item for editing
    const [selectedStatus, setSelectedStatus] = useState<string>("");
    const [selectedInstitution, setSelectedInstitution] = useState<string>(`${user?.institution ? user?.institution?.id : ""}`);
    const [selectedCategory] = useState<string>("");
    const [selectedProgram, setSelectedProgram] = useState<string>("");
    const [selectedGroup, setSelectedGroup] = useState<string>("");
    const [isAll, setIsAll] = useState<boolean>(user?.institution ? false : true);
    const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

    const LIMIT = 12;
    const [hasMore, setHasMore] = useState(true);
    const [IsLoadingMore, setIsLoadingMore] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);


    const { subscribeToMore, data, loading, error, refetch, fetchMore } = useQuery(GET_ITEM_REGISTRATIONS, {
        variables: { search: searchQuery, institutionId: selectedInstitution, categoryId: selectedCategory, programId: selectedProgram, groupId: selectedGroup, status: selectedStatus, offset: 0, limit: LIMIT }
    });
    const { data: institutionData } = useQuery(GET_INSTITUTIONS);
    const { data: programsData } = useQuery(GET_REGISTRABLE_PROGRAMS);
    const { data: groupData } = useQuery(GET_GROUPS);
    const [deleteItemRegistration] = useMutation(DELETE_ITEM_REGISTRATION);
    const [updateRegistrationStatus] = useMutation(UPDATE_REGISTRATION_STATUS);

    const toggleDropdown = (id: string) => {
        setOpenDropdownId(prev => (prev === id ? null : id)); // If the same id is clicked, close it, otherwise open
    };

    const loadMore = async () => {
        setIsLoadingMore(true)
        try {
            await fetchMore({
                variables: {
                    search: searchQuery,
                    institutionId: selectedInstitution,
                    categoryId: selectedCategory,
                    status: selectedStatus,
                    offset: itemRegistrations.length,
                    limit: LIMIT,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult || fetchMoreResult.itemRegistrations.length === 0) {
                        setHasMore(false); // No more items
                        return prev;
                    }
                    setHasMore(true);
                    // Append new items to existing list
                    return {
                        ...prev,
                        itemRegistrations: [
                            ...prev.itemRegistrations,
                            ...fetchMoreResult.itemRegistrations,
                        ],
                    };
                },
            });
        } finally {
            setIsLoadingMore(false)
        }
    };

    const filteredOptions = programsData?.registrablePrograms?.filter((option: any) =>
        option?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );


    // Load data into state
    useEffect(() => {
        if (data && data.itemRegistrations) {
            setItemRegistrations(data.itemRegistrations.filter((item: ItemRegistration) => !isAll ? item.institution.id === user?.institution?.id : true));
            setIsLoading(false)
            if (data.itemRegistrations.length < LIMIT) {
                setHasMore(false); // No more items to fetch
            } else {
                setHasMore(true);
            }
        }
    }, [data]);

    useEffect(() => {
        const unsubscribe = subscribeToMore({
            document: SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newFeedItem = subscriptionData.data.registrationAdded;
                return {
                    itemRegistrations: [newFeedItem, ...prev.itemRegistrations]
                };
            }
        });
        return () => unsubscribe();
    }, [subscribeToMore]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        }

        if (isOpen) {
            searchInputRef.current?.focus();
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setIsLoading(true)
    //     setSearchQuery(e.target.value);
    // };

    // Filtered list of items based on search query
    // const filteredItemRegistrations = itemRegistrations.filter(item => {
    //     const matchesSearch = (
    //         item?.program?.name?.toLowerCase().includes(searchQuery.toLowerCase())
    //         || item?.title?.toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    //     const matchesInstitution = selectedInstitution
    //         ? item?.institution?.id === selectedInstitution
    //         : true;
    //     const matchesCategory = selectedCategory
    //         ? item?.program?.category?.id === selectedCategory
    //         : true;
    //     const matchesStatus = selectedStatus === '' ? true : item?.status === selectedStatus

    //     return (
    //         matchesSearch &&
    //         matchesCategory &&
    //         matchesInstitution &&
    //         matchesStatus
    //     );
    // });

    // Handle editing an item
    const handleEdit = (item: ItemRegistration) => {
        toggleDropdown(item.id)
        setSelectedItem(item); // Set the selected item to edit
    };

    // Handle deletion of an item
    const handleDelete = async (id: string) => {
        toggleDropdown(id)
        try {
            if (confirm('Are you sure you want to delete the Registration?')) {
                await deleteItemRegistration({ variables: { id } });
                setItemRegistrations(itemRegistrations.filter(item => item.id !== id));
                toast.success('Item deleted successfully');
                let participationId = itemRegistrations.filter(item => item.id === id)[0]?.participation?.id
                await updateRegistrationStatus({
                    variables: {
                        id: participationId,
                        input: {
                            isRegistered: false
                        }
                    }
                })
            }
        } catch (error) {
            console.error('Error deleting item registration:', error);
        }

    };

    // Close edit form and reset selected item
    const handleSave = () => {
        setSelectedItem(null); // Clear the selected item after saving
        refetch()
    };

    // Handle cancel action to reset selected item
    const handleCancel = () => {
        setSelectedItem(null);
    };

    if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            <PageTitle pagetitle="Item Registration" subtitle="Registration" />
            <div className="w-full">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* RegisterItem component for both registering and editing items */}
                    <Private
                        permissions={["create-item-registration"]}
                        element={<RegisterItem
                            selectedItem={selectedItem} // Passing selected item if editing
                            onSave={handleSave} // Handle save action
                            onCancel={handleCancel} // Handle cancel action
                            refetch={refetch}
                        />}
                    />
                    <div className="p-6 bg-white dark:bg-slate-800 rounded-lg col-span-2">
                        <div className="flex justify-between align-center">
                            <h2 className="text-base font-medium mb-4">Registered Items</h2>
                            {user?.institution &&
                                <h2 className="text-sm font-medium mb-4">
                                    <a className={`cursor-pointer mr-2 ${isAll && 'p-2 bg-gray-600 text-white rounded-md'}`} onClick={() => {
                                        setItemRegistrations(data.itemRegistrations.filter((item: ItemRegistration) => {
                                            setIsAll(true)
                                            let filter = user?.institution ? (item.status === "APPROVED" || item.status === "PENDING") : true
                                            return filter;
                                        }))
                                        setSelectedInstitution("");
                                    }
                                    }>All</a>
                                    <a className={`cursor-pointer ml-2  ${!isAll && 'p-2 bg-gray-600 text-white rounded-md'}`} onClick={() => {
                                        setIsAll(false);
                                        setSelectedInstitution(user?.institution?.id)
                                        // setItemRegistrations(itemRegistrations.filter(item => item.institution.id === user.institution.id))
                                    }}>Your Institution</a>
                                </h2>}
                        </div>
                        <div className="flex flex-col sm:flex-row gap-2 mb-7">
                            {/* <div className='flex flex-grow relative'>
                                <input
                                    type="text"
                                    placeholder="Search registered items..."
                                    className="h-12 w-full border rounded-lg bg-transparent pr-4 text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:ring-0 sm:text-sm"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </div> */}
                            {/* <div className="flex-grow hidden sm:block">
                                <select
                                    required
                                    value={selectedCategory}
                                    onChange={(e) => {
                                        setIsLoading(true)
                                        setSelectedCategory(e.target.value)
                                    }}
                                    className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                                >
                                    <option value="">Select Category</option>
                                    {categoryData?.categories?.map((category: any) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                            <div className="relative" ref={dropdownRef}>
                                {/* Selected Option Display */}
                                <div className="w-full">
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        ref={searchInputRef}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                        }}
                                        onClick={() => {
                                            setSearchTerm("")
                                            setIsOpen(true)
                                        }}
                                        placeholder="Select Program"
                                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                                    />
                                </div>
                                {/* Dropdown Options */}
                                {isOpen && !selectedItem && (
                                    <div className="absolute top-full left-0 w-full mt-2 bg-white border rounded shadow-lg z-10">

                                        {/* Options List */}
                                        <ul className="max-h-48 overflow-y-auto">
                                            {filteredOptions?.map((option: any, index: any) => (
                                                <li
                                                    key={index}
                                                    onClick={() => {
                                                        // let code = data?.ItemRegistrableParticipations?.filter((item: any) => item?.id === e.target.value);
                                                        setSelectedProgram(option.id)
                                                        setIsOpen(false)
                                                        setSearchTerm(`${option.name} | ${option.category?.name}`);

                                                    }}
                                                    className="px-4 py-2 cursor-pointer hover:bg-gray-500 hover:text-white"
                                                >
                                                    {`${option.name} | ${option.category?.name}`}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>

                            <Private
                                permissions={["create-topic-selection"]}
                                element={<div className="flex-grow hidden sm:block">
                                    <select
                                        required
                                        value={selectedInstitution}
                                        onChange={(e) => {
                                            setSelectedInstitution(e.target.value)
                                            setIsLoading(true)
                                        }}
                                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                                    >
                                        <option value="">Select Institution</option>
                                        {institutionData?.institutions?.map((institution: any) => (
                                            <option key={institution.id} value={institution.id}>
                                                {institution.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>}
                            />
                            <Private
                                permissions={["create-topic-selection"]}
                                element={<div className="flex-grow hidden sm:block">
                                    <select
                                        required
                                        value={selectedGroup}
                                        onChange={(e) => {
                                            setSelectedGroup(e.target.value)
                                            setIsLoading(true)
                                        }}
                                        className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                                    >
                                        <option value="">Select Group</option>
                                        {groupData?.groups?.map((group: any) => (
                                            <option key={group.id} value={group.id}>
                                                {group.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>}
                            />
                            {(!user?.institution || (user?.institution && !isAll)) && <div className="flex-grow hidden sm:block">
                                <select
                                    required
                                    value={selectedStatus}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setSelectedStatus(value); // Set selected status
                                        setIsLoading(true)
                                        refetch();
                                    }}
                                    className="h-12 p-2 w-full border-2 border-gray-300 rounded-lg bg-transparent text-gray-900 placeholder-gray-500 dark:placeholder-gray-300 dark:text-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 dark:focus:border-blue-300 dark:focus:ring-blue-500 transition duration-200 ease-in-out sm:text-sm"
                                >
                                    <option value="">Select Status</option>
                                    <option value="APPROVED">
                                        Approved
                                    </option>
                                    <option value="REJECTED">
                                        Rejected
                                    </option>
                                    <option value="PENDING">
                                        Pending
                                    </option>
                                </select>
                            </div>}
                        </div>

                        {loading || isLoading ? (
                            <div className='grid place-items-center'>
                                <button
                                    type="button"
                                    className="btn bg-transparent border-gray-300 dark:border-gray-700"
                                >
                                    <i className="mgc_loading_4_line me-2 animate-spin"></i>
                                    <span>Loading</span>
                                </button>
                            </div>
                        ) :
                            (<div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                    {itemRegistrations.map((item) => {
                                            return (
                                                    <ProgramCard
                                                        key={item.id} // Ensure a unique key prop
                                                        item={item} // Pass item to ProgramCard
                                                        onEdit={() => handleEdit(item)} // Handle edit action
                                                        onDelete={() => handleDelete(item.id)} // Handle delete action
                                                        refetch={refetch}
                                                        isDropdownOpen={openDropdownId === item.id} // Open dropdown only for the specific card
                                                        toggleDropdown={toggleDropdown}
                                                    />
                                                )
                                    })}
                                </div>
                                {/* {itemRegistrations.length === 0 && !loading && !isLoading && (
                                    <p className='text-center mt-5'>No Data Found</p>
                                )} */}
                                {hasMore && <div className="flex justify-center">
                                    <button
                                        type="button"
                                        className="mt-10 mb-10 btn bg-transparent border-gray-300 dark:border-gray-700"
                                        onClick={async () => {
                                            setIsLoadingMore(true);
                                            await hasMore && await loadMore();
                                            setIsLoadingMore(false)
                                        }}
                                    >
                                        {IsLoadingMore && <i className="mgc_loading_4_line me-2 animate-spin"></i>}
                                        <span>{IsLoadingMore ? "Loading..." : "Load More"}</span>
                                    </button>
                                </div>}
                            </div>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ItemReg;
