

function Table() {
  return (
    <div className="relative overflow-x-auto no-scrollbar">
      <table className="w-full divide-y divide-gray-300 dark:divide-gray-700">
        <thead className="bg-slate-300 bg-opacity-20 border-t dark:bg-slate-800 divide-gray-300 dark:border-gray-700">
          <tr>
            <th
              scope="col"
              className="py-3.5 ps-4 pe-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Sl. No.
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Teams
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Date
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Starting Time
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Ending Time
            </th>
            
            <th
              scope="col"
              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 dark:text-gray-200"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 dark:divide-gray-700"></tbody>
        {/* <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
          {loading ? (
            <td colSpan={100} className="pt-3">
              <Skeleton />
            </td>
          ) : error ? (
            <td colSpan={100} className="pt-3">
              Error fetching candidates: {error.message}
            </td>
          ) : candidates.length ? (
            candidates.map((candidate) => (
              <CandidateItem
                key={candidate.id}
                candidate={candidate}
                handleEdit={handleEdit}
                handleDeleteButton={handleDeleteConfirm}
                handleView={handleView}
              />
            ))
          ) : (
            <tr>
              <td colSpan={100} className="text-center pt-5">
                No Candidates
              </td>{" "}
            </tr>
          )}
        </tbody> */}
      </table>
    </div>
  );
}

export default Table;
