import ScheduleCard from "../../components/admin/schedule/ScheduleCard";
import PageTitle from "../../components/PageTitle";
import ScheduleNav from "../../components/schedule/SchedulNav";

const Schedule: React.FC = () => {
  const scheduleDetails= [
    {
      title: "Speech and Song MLM",
      category: "BIDAYA",
      date:"Dec 7 Wed",
      venue: "Venue 4",
      startTime:"03:50 AM",
      endTime: "06:30 PM",
    },
    {
      title: "Speech and Song MLM",
      category: "BIDAYA",
      date:"Dec 7 Wed",
      venue: "Venue 4",
      startTime:"03:50 AM",
      endTime: "06:30 PM",
    },
    {
      title: "Speech and Song MLM",
      category: "BIDAYA",
      date:"Dec 7 Wed",
      venue: "Venue 4",
      startTime:"03:50 AM",
      endTime: "06:30 PM",
    },
    {
      title: "Speech and Song MLM",
      category: "BIDAYA",
      date:"Dec 7 Wed",
      venue: "Venue 4",
      startTime:"03:50 AM",
      endTime: "06:30 PM",
    },
    {
      title: "Speech and Song MLM",
      category: "BIDAYA",
      date:"Dec 7 Wed",
      venue: "Venue 4",
      startTime:"03:50 AM",
      endTime: "06:30 PM",
    },
    {
      title: "Speech and Song MLM",
      category: "BIDAYA",
      date:"Dec 7 Wed",
      venue: "Venue 4",
      startTime:"03:50 AM",
      endTime: "06:30 PM",
    },
    {
      title: "Speech and Song MLM",
      category: "BIDAYA",
      date:"Dec 7 Wed",
      venue: "Venue 4",
      startTime:"03:50 AM",
      endTime: "06:30 PM",
    },
    {
      title: "Speech and Song MLM",
      category: "BIDAYA",
      date:"Dec 7 Wed",
      venue: "Venue 4",
      startTime:"03:50 AM",
      endTime: "06:30 PM",
    },
    {
      title: "Speech and Song MLM",
      category: "BIDAYA",
      date:"Dec 7 Wed",
      venue: "Venue 4",
      startTime:"03:50 AM",
      endTime: "06:30 PM",
    },
  ]
  return (
    <>
      <PageTitle pagetitle="Judgement" subtitle="Judgement" />
      <ScheduleNav />
      <div className="grid grid-cols-4 gap-6">
      {scheduleDetails.map((scheduleData)=>(  
      <ScheduleCard
        title={scheduleData.title}
        category={scheduleData.category}
        date={scheduleData.date}
        venue={scheduleData.venue}
        startTime={scheduleData.startTime}
        endTime={scheduleData.endTime}
      />
      ))}
      </div>
    </>
  );
};

export default Schedule;
