import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const ScheduleNav: React.FC = () => {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("BIDAYA");
  const [skill, setSkill] = useState("Technical");
  const [judge, setJudge] = useState("Muhamed Ashiq");

  return (
    <div className="justify-evenly flex items-center gap-4 p-4 ">
      {/* Search Input */}
      <div className="w-1/4 flex items-center">
        <label className="text-gray-600 mr-2">Search</label>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search.."
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      <div className="flex items-center">
        <label className="text-gray-600 mr-2">Category</label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          className="p-2 w-28 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <option value="BIDAYA">BIDAYA</option>
          <option value="ULA">ULA</option>
          <option value="THANIYA">THANIYAYA</option>
          <option value="THANAWIYYAH">THANAWIYYAH</option>
          <option value="ALIYA">ALIYA</option>
          <option value="KULLIYA">KULLIYYA</option>
        </select>
      </div>

      <div className="flex items-center">
        <label className="text-gray-600 mr-2">Venue</label>
        <select
          value={skill}
          onChange={(e) => setSkill(e.target.value)}
          className="w-28 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <option value="Venue 1">Venue 1</option>
          <option value="Venue 2">Venue 2</option>
          <option value="Venue 3">Venue 3</option>
          <option value="Venue 4">Venue 4</option>
          <option value="Venue 5">Venue 5</option>
          <option value="Venue 6">Venue 6</option>
        </select>
      </div>

      <div className="flex items-center">
        <label className="text-gray-600 mr-2">Date</label>
        <input
          type="date"
          value={judge}
          onChange={(e) => setJudge(e.target.value)}
          className="w-36 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      <button className="w-40 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
        + Add Schedule
      </button>

      <NavLink
      to="/compare-schedules" 
      className="w-40 grid place-items-center bg-red-600 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
      >
        Compare
      </NavLink>
    </div>
  );
};

export default ScheduleNav;
